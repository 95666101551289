import BasicSettings from "./BasicSettings";
import PushNotification from "./PushNotification";
import PromotionalImages from "./PromotionalImages";
import TournamentsAndEvents from "./TournamentsAndEvents";
import ClubChallenges from "./ClubChallenges";
import ClubChallenge from "./ClubChallenge";
import { Fragment, useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter, NavLink } from "react-router-dom";
import { compose } from "redux";
import AssociationService from "./../../../../services/api/association";
import { connect } from "react-redux";

function GolferProducts(props) {
	// State to hold the result of the challenges enabled status
	const [clubChallengesEnabled, setClubChallengesEnabled] = useState(false);

	// Fetch the challenges enabled status when the component mounts
	useEffect(() => {
		const fetchClubChallengesEnabled = async () => {
			try {
				const res = await AssociationService.getGolferProducts(props.associationId);
				setClubChallengesEnabled(res.association_settings.club_challenges_enabled);
			} catch (error) {
				console.error(error);
			}
		};

		fetchClubChallengesEnabled();
	}, [props.associationId]);

	return (
		<Fragment>
			<div className="vertical-tabs__container row">
				<ul className="vertical-tabs col">
					<li>
						<NavLink
							className="vertical-tab"
							activeClassName="is-active"
							to={`${props.match.url}/basic-settings`}
						>
							Basic Settings
						</NavLink>
					</li>
					{process.env.REACT_APP_TYPE !== "hv" && (
						<Fragment>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/promotional-images`}
								>
									Promotional Images
								</NavLink>
							</li>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/tournaments-and-events`}
								>
									Tournaments & Events
								</NavLink>
							</li>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/push-notifications`}
								>
									Push Notifications
								</NavLink>
							</li>
							{clubChallengesEnabled && (
								<li>
									<NavLink
										className="vertical-tab"
										activeClassName="is-active"
										to={`${props.match.url}/challenges`}
									>
										Challenges
									</NavLink>
								</li>
							)}
						</Fragment>
					)}
				</ul>
				<div className="vertical-tabs__panel col">
					<Switch>
						<Redirect
							exact
							from={`${props.match.url}`}
							to={`${props.match.url}/basic-settings`}
						/>
						<Route
							path={`${props.match.path}/basic-settings`}
							component={() => (
								<BasicSettings
									canEditAssociation={props.canEditAssociation}
									associationId={props.associationId}
								/>
							)}
						/>
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/promotional-images`}
								component={() => (
									<PromotionalImages
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/tournaments-and-events`}
								component={() => (
									<TournamentsAndEvents
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/push-notifications`}
								component={() => (
									<PushNotification
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								exact
								path={`${props.match.path}/challenges`}
								component={() => (
									<ClubChallenges
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/challenges/:id`}
								component={() => (
									<ClubChallenge
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
										updateChallenge={props.updateChallenge}
									/>
								)}
							/>
						)}
					</Switch>
				</div>
			</div>
		</Fragment>
	);
}

function mapStateToProps(state) {
	return {
		userType: state.user.access.user_type,
		user: state.user
	};
}

export default compose(withRouter, connect(mapStateToProps))(GolferProducts);
