import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {
	Field,
	formValueSelector,
	getFormMeta,
	getFormSyncErrors,
	getFormValues,
	reduxForm,
	SubmissionError,
} from "redux-form";
import {connect} from "react-redux";
import moment from 'moment';
import {SelectInput} from "../../../inputs/Select";
import {InputField} from "../../../inputs/InputField";
import {
	isNumber,
	required,
	scoreMaximumValue,
	scoreMinimumValue,
	totalScoreValue
} from "../../../inputs/validations";
import ScoreService from "../../../services/api/score";
import {DatePickerInput} from "../../../inputs/DatePicker";
import CourseRatingSystemService from "../../../services/api/courseRatingSystem";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import debounce from "debounce-promise";
import GolferService from "../../../services/api/golfer";
import { store } from '../../../store';
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import createCancelableRequest
	from "../../../services/shared/asyncSearchIntrerupt";
import {orderTees} from "../../../services/shared/teesFilter";

class PlayerEntry extends Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),
			dateSetup: [
				{value: 'specific', label: 'Specific Date'},
				{value: 'ascending', label: 'Ascending Date'},
				{value: 'descending', label: 'Descending Date'},
			],
			scoreType: [
				{ value: 'H', label: 'Home'},
				{ value: 'A', label: 'Away'},
				{ value: 'T', label: "Competition"}
			],
			holesOptions: [
				{ value: "18", label: '18 (9 + 9)' },
				{ value: "18t", label: '18 Total' },
				{ value: "9", label: '9 Total' },
			],
			golfers: [],
			courses: [],
			teeSetsOptions: [],
			selectedCourse: {},
			confirmationModalOpen: false,
			savedEntry: null,
			errors: [],
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.confirmScore = this.confirmScore.bind(this);
		this.onGhinChange = this.onGhinChange.bind(this);
		this.resetDefaults = this.resetDefaults.bind(this);
		this.searchName = this.searchName.bind(this);
		this.searchLocal = this.searchLocal.bind(this);
		this.searchDebounce = debounce(this.searchLocal, 1000);
		this.debounceOnChange = debounce(this.onGhinChange, 1000);
	}

	onGhinChange(e) {
		this.props.touch('player_entry_value');
		if (!e.target.classList.contains('is-error') && this.props.player_entry_value) {
			GolferService.getGolferByGHIN(this.props.player_entry_value)
				.then(response => {
					var super_admin = store.getState().user.access.super_user;
					if (response && response.golfer && (super_admin || !response.golfer.is_trial)) {
						let golfer = response.golfer;
						let self = this;
						this.props.change('firstName', golfer.first_name);
						this.props.change('lastName', golfer.last_name);
						this.props.change('PlayerName', golfer.first_name + ' ' + golfer.last_name);
						this.props.change('Display', golfer.handicap_index);
						if (golfer.clubs.filter(club => club.status === "Active").length > 0) {
							this.props.change('Error', null);
							this.props.change('gender', golfer.gender);
							this.props.change('golfer_id', this.props.player_entry_value);

							this.getCourseDetails(() => {
								self.props.change('tee_set_id', null);
								if (golfer.gender === "M") {
									if (self.props.default_male_tee) {
										let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
											if (self.props.number_of_holes.value === "9") {
												return o.value === self.props.default_male_tee.value && o.tee_set_side === self.props.default_male_tee.tee_set_side;
											} else {
												return o.value === self.props.default_male_tee.value;
											}
										});
										self.props.change('tee_set_id', selectedDefaultTee[0])
									}
								} else {
									if (self.props.default_female_tee) {
										let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
											if (self.props.number_of_holes.value === "9") {
												return o.value === self.props.default_female_tee.value && o.tee_set_side === self.props.default_female_tee.tee_set_side;
											} else {
												return o.value === self.props.default_female_tee.value;
											}

										});
										self.props.change('tee_set_id', selectedDefaultTee[0])
									}
								}
							});
						} else {
							if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
								this.props.change('Error', `This golfer is inactive.`);
							else
								this.props.change('Error', `This is an Archived golfer.`);
							this.props.change('golfer_id', null);
							this.props.change('gender', null);

						}
					} else {
						this.props.change('PlayerName', null);
						this.props.change('golfer_id', null);
						this.props.change('gender', null);
						this.props.change('firstName', null);
						this.props.change('lastName', null);
						this.props.change('PlayerName', null);
						this.props.change('Display', null);
						this.props.change('Error', 'Golfer Not Found');
					}
				})
				.catch(err => {
					this.props.change('PlayerName', null);
					this.props.change('golfer_id', null);
					this.props.change('gender', null);
					this.props.change('firstName', null);
					this.props.change('lastName', null);
					this.props.change('PlayerName', null);
					this.props.change('Display', null);
					this.props.change('Error', 'Golfer Not Found');
				})
		} else {
			this.props.change('PlayerName', null);
			this.props.change('golfer_id', null);
			this.props.change('gender', null);
			this.props.change('firstName', null);
			this.props.change('lastName', null);
			this.props.change('PlayerName', null);
			this.props.change('Display', null);
			this.props.change('Error', null);
		}
	}

	resetDefaults() {
		this.props.change('number_of_holes', this.props.hidden_number_of_holes);
		this.props.change('score_type', this.props.hidden_score_type);
		this.props.change('course_name', this.props.hidden_course_name);
	}

	onSubmit(values) {

		let data = {
			golfer_id: values.golfer_id,
			course_id: values.course_name.value,
			tee_set_id: values.tee_set_id.value,
			tee_set_side: parseInt(values.number_of_holes.value) === 9 ? values.tee_set_id.tee_set_side : 'All18',
			played_at: moment(values.played_at).format("YYYY-MM-DD"),
			score_type: values.score_type.value,
			number_of_holes: parseInt(values.number_of_holes.value),
			gender: values.gender
		};

		if (values.number_of_holes.value === "18") {
			data.front9_adjusted= values.front9;
			data.back9_adjusted= values.back9;
			return ScoreService.post18h9and9(data)
				.then(res => {
					if (!res.errors) {
						let data = res.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.course_name = values.course_name.unparsedLabel;
						data.tee_name = values.tee_set_id.label;
						data.number_of_holes_played = values.number_of_holes.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						data.firstName = values.firstName;
						data.lastName = values.lastName;
						if (res.score.status === 'UnderReview') {
							this.setState({
								savedEntry: data,
								errors: [],
								confirmationModalOpen: true
							});
						} else {
							this.setState({
								errors: [],
								teeSetsOptions: [],
							});
							this.props.addEntry(data);
							this.props.untouch('front9');
							this.props.untouch('back9');
							this.props.untouch('total');
							this.props.untouch('player_entry_value');
							this.props.change('player_entry_value', null);
							this.props.change('golfer_id', null);
							this.props.change('tee_set_id', null);
							this.props.untouch('tee_set_id');
							this.props.change('front9', null);
							this.props.change('back9', null);
							this.props.change('total', null);
							this.props.change('PlayerName', null);
							this.props.change('Display', null);
							this.resetDefaults();
							if (values.date_setup && values.date_setup.value === 'ascending') {
								let d = moment(values.played_at).add(1, 'd');
								this.props.change('played_at', new Date(d));
							}

							if (values.date_setup && values.date_setup.value === 'descending') {
								let d = moment(values.played_at).subtract(1, 'd')
								this.props.change('played_at', new Date(d));
							}
              document.getElementsByClassName('player-score')[0].getElementsByClassName('player_entry_value')[0].focus()
						}

					} else {
						throw new SubmissionError(res.errors)
					}

				})
				.catch(err => {
					let errors =  [];
					if (err.data && err.data.errors) {
						Object.keys(err.data.errors).forEach(error => {
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								if (error === 'front9_adjusted' || error === 'back9_adjusted') {
									const errorMrg = `${error.split('_')[0]} score ${err.data.errors[error][0]}`
									errors.push(errorMrg) ;
								} else {
									errors.push(err.data.errors[error][0]) ;
								}
							}
						});

						this.setState({errors});
					}
				})
		} else {
			data.adjusted_gross_score= parseInt(values.total || 0);
			return ScoreService.postAdjusted(data)
				.then(res => {
					if (!res.errors) {
						let data = res.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.course_name = values.course_name.unparsedLabel;
						data.tee_name = values.tee_set_id.label;
						data.number_of_holes_played = values.number_of_holes.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						data.firstName = values.firstName;
						data.lastName = values.lastName;
						if (res.score.status === 'UnderReview') {
							this.setState({
								savedEntry: data,
								errors: [],
								confirmationModalOpen: true
							});
						} else {
							this.setState({
								errors: [],
								teeSetsOptions: [],
							});
							this.props.addEntry(data);
							this.props.untouch('front9');
							this.props.untouch('back9');
							this.props.untouch('total');
							this.props.untouch('player_entry_value');
							this.props.change('player_entry_value', null);
							this.props.change('golfer_id', null);
							this.props.change('tee_set_id', null);
							this.props.untouch('tee_set_id');
							this.props.change('front9', null);
							this.props.change('back9', null);
							this.props.change('total', null);
							this.props.change('PlayerName', null);
							this.props.change('Display', null);
							this.resetDefaults();
							if (values.date_setup && values.date_setup.value === 'ascending') {
								let d = moment(values.played_at).add(1, 'd');
								this.props.change('played_at', new Date(d));
							}

							if (values.date_setup && values.date_setup.value === 'descending') {
								let d = moment(values.played_at).subtract(1, 'd')
								this.props.change('played_at', new Date(d));
							}
              document.getElementsByClassName('player-score')[0].getElementsByClassName('player_entry_value')[0].focus()
						}

					} else {
						throw new SubmissionError(res.errors)
					}

				})
				.catch(err => {
					let errors =  [];
					if (err.data && err.data.errors) {
						Object.keys(err.data.errors).forEach(error => {
							console.log(err.data.errors[error]);
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								if (error === 'front9_adjusted' || error === 'back9_adjusted') {
									const errorMrg = `${error.split('_')[0]} score ${err.data.errors[error][0]}`
									errors.push(errorMrg) ;
								} else {
									errors.push(err.data.errors[error][0]) ;
								}
							}
						});

						this.setState({errors});
					}
				})
		}


	}

	confirmScore() {
		ScoreService.confirmScore(this.state.savedEntry.id, this.state.savedEntry.golfer_id)
			.then(response => {
				this.props.addEntry(this.state.savedEntry);
				this.setState({confirmationModalOpen: false,teeSetsOptions: [], savedEntry: null});
				this.props.untouch('front9');
				this.props.untouch('back9');
				this.props.untouch('total');
				this.props.untouch('player_entry_value');
				this.props.change('player_entry_value', null);
				this.props.change('golfer_id', null);
				this.props.change('front9', null);
				this.props.change('back9', null);
				this.props.change('tee_set_id', null);
				this.props.untouch('tee_set_id');
				this.props.change('total', null);
				this.props.change('PlayerName', null);
				this.props.change('Display', null);
				if (this.props.date_setup.value === 'ascending') {
					let d = moment(this.props.played_at).add(1, 'd');
					this.props.change('played_at', new Date(d));
				}

				if (this.props.date_setup.value === 'descending') {
					let d = moment(this.props.played_at).subtract(1, 'd')
					this.props.change('played_at', new Date(d));
				}
        document.getElementsByClassName('player-score')[0].getElementsByClassName('player_entry_value')[0].focus();
				this.resetDefaults();
			})
			.catch(err => {
				this.setState({confirmationModalOpen: false, savedEntry: null});
        document.getElementsByClassName('player-score')[0].getElementsByClassName('player_entry_value')[0].focus();
			})
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		} return new Promise(resolve => {
			resolve([])
		})

	}

	getCourseDetails(callback) {
		CourseRatingSystemService.getCourseDetails(this.props.hidden_course_name.value)
			.then(res => {
				let filteredTS = res.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
					let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
					let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
					let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
					return {
						label: ts.TeeSetRatingName,
						holes: ts.Holes,
						value: ts.TeeSetRatingId,
						ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
						ratingFront: front && `${front.CourseRating} / ${front.SlopeRating}`,
						ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
						ratingBack: back && `${back.CourseRating} / ${back.SlopeRating}`}
				});
				orderTees(filteredTS);
				if (this.props.number_of_holes.value === "9") {
					filteredTS = filteredTS.reduce((res, current) => {
						let frontPar = current.holes.reduce((acc, h, index) => {
							if (index < 9) return acc + h.Par;
							return acc + 0;
						}, 0);
						let backPar = current.holes.reduce((acc, h, index) => {
							if (index >= 9) return acc + h.Par;
							return acc + 0;
						}, 0);
						let front = current.ratingFront ? {
							label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
							unparsedLabel: current.label + ' F9',
							value: current.value,
							tee_set_side: 'F9'
						} : null;
						let back = current.ratingBack ? {
							label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
							unparsedLabel: current.label + ' B9',
							value: current.value,
							tee_set_side: 'B9'
						} : null;
						if(front && back) {
							return res.concat([front, back])
						}
						if (front) {
							return res.concat([front]);
						}
						if (back) {
							return res.concat([back]);
						}
						return null;
					}, []);
					if (this.props.tee_set_id) {
						let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
						this.props.change('tee_set_id', selectedTee[0])
					}
				} else {
					filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).map(ts => {
						let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
						let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
						return {label: label, value: ts.value}
					});
					if (this.props.tee_set_id) {
						let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
						this.props.change('tee_set_id', selectedTee[0])
					}
				}
				this.setState({
					teeSetsOptions: filteredTS,
					errors: []
				}, () => {
					if (typeof callback === 'function') {
						callback();
					}
				});
			})
	}

	searchName (searchTerm, callback){
		if (searchTerm) {
			this.props.change('searchable_player_entry', '');
			this.props.change('golfer_id', null);
			this.props.change('gender', null);
			this.props.change('firstName', null);
			this.props.change('lastName', null);
			this.props.change('PlayerName', null);
			this.props.change('Display', null);
			let cancelableRequest = createCancelableRequest();
			return cancelableRequest(`/search_golfer.json?name=${searchTerm}&club_id=${this.props.userAccess.default_club_id}`)
				.then(response => {
					if (response && response.data) {
						let options = response.data.golfers.map(g => {return {...g, ...{value: g.id,label:g.first_name + ' ' + g.last_name}}}).sort((a, b) => (a.label > b.label) ? 1 : -1);
						this.setState({golfers: options});
						callback(options);
					} else {
						callback([]);
					}
				})
				.catch(err => {
					this.props.change('Error', 'Golfer Not Found');
					console.error(err)
				})
		} return new Promise(resolve => {
			resolve([])
		})
	}

	searchLocal (searchTerm, callback){
		if (searchTerm.target.value) {
			this.props.change('searchable_player_entry', '');
			this.props.change('golfer_id', null);
			this.props.change('gender', null);
			this.props.change('firstName', null);
			this.props.change('lastName', null);
			this.props.change('PlayerName', null);
			this.props.change('Display', null);
			let cancelableRequest = createCancelableRequest();
			return cancelableRequest(`/search_golfer.json?local_number=${searchTerm.target.value}&club_id=${this.props.userAccess.default_club_id}`)
				.then(response => {
					let golfer = response && response.data && response.data.golfers && (response.data.golfers.find(g => g.clubs.some(club => club.status === "Active")) || response.data.golfers[0])
					if (golfer) {

						this.props.change('firstName', golfer.first_name);
						this.props.change('lastName', golfer.last_name);
						this.props.change('PlayerName', golfer.first_name + ' ' + golfer.last_name);
						this.props.change('Display', golfer.handicap_index);
						if(golfer.clubs.filter(club => club.status = "Active").length > 0) {
							this.props.change('golfer_id', golfer.id);
							this.props.change('gender', golfer.gender);
							this.props.change('Error', null);
							this.getCourseDetails(() => {
								this.props.change('tee_set_id', null);
								if (golfer.gender === "M") {
									if (this.props.default_male_tee) {
										let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
											if (this.props.number_of_holes.value === "9") {
												return o.value === this.props.default_male_tee.value && o.tee_set_side === this.props.default_male_tee.tee_set_side;
											} else {
												return o.value === this.props.default_male_tee.value;
											}
										});
										this.props.change('tee_set_id', selectedDefaultTee[0])
									}
								} else {
									if (this.props.default_female_tee) {
										let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
											if (this.props.number_of_holes.value === "9") {
												return o.value === this.props.default_female_tee.value && o.tee_set_side === this.props.default_female_tee.tee_set_side;
											} else {
												return o.value === this.props.default_female_tee.value;
											}
										});
										this.props.change('tee_set_id', selectedDefaultTee[0])
									}
								}
							});
						} else {
							if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
								this.props.change('Error', `This golfer is inactive.`);
							else
								this.props.change('Error', `This is an Archived golfer.`);
							this.props.change('golfer_id', null);
							this.props.change('gender', null);
						}

					} else {
						this.props.change('golfer_id', null);
						this.props.change('gender', null);
						this.props.change('firstName', null);
						this.props.change('lastName', null);
						this.props.change('PlayerName', null);
						this.props.change('Display', null);
						this.props.change('Error', 'Golfer Not Found');
					}
				})
				.catch(err => {
					this.props.change('Error', 'Golfer Not Found');
					console.error(err)
				})
		}
	}

	selectGolfer (golfer) {
		let self = this;
		if (golfer) {
			this.props.change('firstName', golfer.first_name);
			this.props.change('lastName', golfer.last_name);
			this.props.change('PlayerName', golfer.first_name + ' ' + golfer.last_name);
			this.props.change('Display', golfer.handicap_index);
			if(golfer.clubs.filter(club => club.status === "Active").length > 0){
				this.props.change('Error', null);
				this.props.change('golfer_id', golfer.id);
				this.props.change('gender', golfer.gender);
				this.getCourseDetails(() => {
					self.props.change('tee_set_id', null);
					if (golfer.gender === "M") {
						if (self.props.default_male_tee) {
							let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
								if (self.props.number_of_holes.value === "9") {
									return o.value === self.props.default_male_tee.value && o.tee_set_side === self.props.default_male_tee.tee_set_side;
								} else {
									return o.value === self.props.default_male_tee.value;
								}
							});
							self.props.change('tee_set_id', selectedDefaultTee[0])
						}
					} else {
						if (self.props.default_female_tee) {
							let selectedDefaultTee = this.state.teeSetsOptions.filter(o => {
								if (self.props.number_of_holes.value === "9") {
									return o.value === self.props.default_female_tee.value && o.tee_set_side === self.props.default_female_tee.tee_set_side;
								} else {
									return o.value === self.props.default_female_tee.value;
								}

							});
							self.props.change('tee_set_id', selectedDefaultTee[0])
						}
					}
				});
			} else {
				if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
					this.props.change('Error', `This golfer is inactive.`);
				else
					this.props.change('Error', `This is an Archived golfer.`);
				this.props.change('golfer_id', null);
				this.props.change('gender', null);

			}

		} else {
			this.props.change('golfer_id', null);
			this.props.change('gender', null);
			this.props.change('firstName', null);
			this.props.change('lastName', null);
			this.props.change('PlayerName', null);
			this.props.change('Display', null);
			this.props.change('Error', null);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.hidden_course_name !== this.props.hidden_course_name) {
			this.getCourseDetails();
		}
		if (this.props.player_entry !== prevProps.player_entry) {
			this.setState({golfers: []});
		}
	}


	render() {
		let {handleSubmit} = this.props;
		let self = this;
		let fieldsDisabled = !(this.props.default_male_tee || this.props.default_female_tee);
		let validateFrontBack = !(!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9"))));
		return (
			<Fragment>
				<form className={'player-score'} onSubmit={handleSubmit(this.onSubmit)} disabled="true">
					<Field component={InputField} type="hidden" autoComplete="off" id="golfer_id" name="golfer_id" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="gender" name="gender" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="player_entry" name="player_entry" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_number_of_holes" name="hidden_number_of_holes" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_score_type" name="hidden_score_type" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_course_name" name="hidden_course_name" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_courses" name="hidden_courses" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_male_tees" name="hidden_male_tees" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="hidden_female_tees" name="hidden_female_tees" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="default_male_tee" name="default_male_tee" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="default_female_tee" name="default_female_tee" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="date_setup" name="date_setup" placeholder="Player Entry" />
					<Field component={InputField} type="hidden" autoComplete="off" id="firstName" name="firstName" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="lastName" name="lastName" placeholder="Course Name" />
					<div className="row">
						<div className="col is-1-of-8">
							<label htmlFor="played_at">Date played</label>
							<Field hideErrorMessages maxDate={new Date()} showMonthDropdown showYearDropdown onChange={() => {this.setState({errors: []})}} className={'date'} id={"played_at"}  name={"played_at"} component={DatePickerInput} disabled={!this.props.initialized || fieldsDisabled} placeholder="Select ... " />
						</div>
						<div className="col is-1-of-10">
							<label>Enter {this.props.player_entry === 'name' && 'Name'}{this.props.player_entry === "golfer_id" && "GHIN #"}{this.props.player_entry === "local_number" && "Local #"}</label>
							<Field onChange={this.debounceOnChange}
								   validate={[isNumber]}
								   parse={(value) => {if(isNaN(parseInt(value))){return ''} else {return parseInt(value)}}}
								   hideErrorMessages={true}
								   component={InputField}
								   type={this.props.player_entry !== 'golfer_id' ? "hidden": "text"}
								   autoComplete="off"
								   id="player_entry_value"
								   name="player_entry_value"
								   className={'player_entry_value'}
								   placeholder="Player Entry"
								   disabled={fieldsDisabled}/>
							<Field onChange={this.searchDebounce}
								   hideErrorMessages={true}
								   component={InputField}
								   type={this.props.player_entry !== 'local_number' ? "hidden": "text"}
								   autoComplete="off"
								   id="player_entry_value"
								   name="player_entry_value"
								   className={'player_entry_value'}
								   placeholder="Player Entry"
								   disabled={fieldsDisabled}/>
							<Field component={AsyncSelectInput}
								   type={this.props.player_entry !== 'name' ? "hidden": "text"}
								   onChange={(value)=>{this.selectGolfer(value)}}
								   searchable={true}
								   hideErrorMessages={true}
								   defaultOptions={true}
								   dontCacheOptions={true}
								   loadOptions={debounce(this.searchName, 1000)}
								   autoComplete="off"
								   className="react-select-container"
								   classNamePrefix="react-select"
								   id="searchable_player_entry"
								   name="searchable_player_entry"
								   placeholder={" "}
								   disabled={fieldsDisabled}
									/>
						</div>
						<div className="col is-1-of-8">
							<label>Posting Method</label>
							<Field onChange={(event, newValue, prevValue) => {
								if (prevValue) {
									if (prevValue.value === "18" && newValue.value === "9") {
										this.props.change('front9', null);
										this.props.change('back9', null);
									}
									if (prevValue.value === "18" && newValue.value === "18t") {
										this.props.change('front9', null);
										this.props.change('back9', null);
									}
									if ((prevValue.value === '18t' || prevValue.value === '9') && newValue.value === '18') {
										this.props.untouch('front9');
										this.props.untouch('back9');
									}
								}
								if (newValue) {
									if (newValue.value === "9") {
										if (this.props.score_type) {
											if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A')) {
												this.props.change('score_type', null);
											}
										}
										this.setState({
											scoreType: [
												{value: 'H', label: 'Home'},
												{value: 'A', label: 'Away'},
												{value: 'T', label: "Competition"},
											]
										})
									} else {
										if (newValue.value === "18t") {
											this.setState({
												scoreType: [
													{value: 'H', label: 'Home'},
													{value: 'A', label: 'Away'},
													{value: 'T', label: "Competition"},
												]
											});
										} else {
											if (this.props.score_type) {
												if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A' || this.props.score_type.value === 'T')) {
													this.props.change('score_type', null);
												}
											}
											this.setState({
												scoreType: [
													{value: 'H', label: 'Home'},
													{value: 'A', label: 'Away'},
													{value: 'T', label: "Competition"},
												]
											});
										}

									}
									if (this.props.course_name) {
										CourseRatingSystemService.getCourseDetails(this.props.course_name.value)
											.then(response => {
												let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
													let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
													let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
													let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
													return {
														label: ts.TeeSetRatingName,
														holes: ts.Holes,
														value: ts.TeeSetRatingId,
														ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
														ratingFront: front && `${front.CourseRating} / ${front.SlopeRating}`,
														ratingBack: back && `${back.CourseRating} / ${back.SlopeRating}`,
														ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1)
													}
												});
												orderTees(filteredTS);
												if (this.props.number_of_holes.value === "9") {
													filteredTS = filteredTS.reduce((res, current) => {
														let frontPar = current.holes.reduce((acc, h, index) => {
															if (index < 9) return acc + h.Par;
															return acc + 0;
														}, 0);
														let backPar = current.holes.reduce((acc, h, index) => {
															if (index >= 9) return acc + h.Par;
															return acc + 0;
														}, 0);
														let front = current.ratingFront ? {
															label: current.label + " F9 \n" + `(${parseFloat(current.ratingFront).toFixed(1)} / ${frontPar})`,
															unparsedLabel: current.label + ' F9',
															value: current.value,
															tee_set_side: 'F9'
														} : null;
														let back = current.ratingBack ? {
															label: current.label + " B9 \n" + `(${parseFloat(current.ratingBack).toFixed(1)} / ${backPar})`,
															unparsedLabel: current.label + ' B9',
															value: current.value,
															tee_set_side: 'B9'
														}: null;
														if(front && back) {
															return res.concat([front, back])
														}
														if (front) {
															return res.concat([front]);
														}
														if (back) {
															return res.concat([back]);
														}
														return null;
													}, []);
													if (this.props.tee_set_id) {
														let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
														this.props.change('tee_set_id', selectedTee[0])
													}
												} else {
													filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).map(ts => {
														let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
														let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
														return {label: label, value: ts.value}
													});
													if (this.props.tee_set_id) {
														let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
														this.props.change('tee_set_id', selectedTee[0])
													}
												}
												this.setState({
													teeSetsOptions: filteredTS,
													errors: []
												});
											})
									}
								}
							}} name={"number_of_holes"} component={SelectInput} disabled={!this.props.initialized || fieldsDisabled} className="react-select-container" classNamePrefix="react-select" options={this.state.holesOptions} isSearchable={false} />
						</div>
						<div className="col is-1-of-8">
							<label>Score Type</label>
							<Field name={"score_type"} component={SelectInput} disabled={!this.props.initialized || !this.props.number_of_holes || fieldsDisabled} className="react-select-container" classNamePrefix="react-select" options={this.state.scoreType} isSearchable={false} />
						</div>

						<div className="col">
							<label htmlFor="course_name">Course</label>
							<Field onChange={(event, newValue, prevValue) => {
								if (newValue) {
									CourseRatingSystemService.getCourseDetails(newValue.value)
										.then(response => {

											let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
												let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
												let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
												let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
												return {
													label: ts.TeeSetRatingName,
													holes: ts.Holes,
													value: ts.TeeSetRatingId,
													ratingTotal: total &&  `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
													ratingFront: front &&  `${front.CourseRating} / ${front.SlopeRating}`,
													ratingBack: back &&  `${back.CourseRating} / ${back.SlopeRating}`,
													ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
												}
											});
											orderTees(filteredTS);
											if (this.props.number_of_holes.value === "9") {
												filteredTS = filteredTS.reduce((res, current) => {
													let frontPar = current.holes.reduce((acc, h, index) => {
														if (index < 9) return acc + h.Par;
														return acc + 0;
													}, 0);
													let backPar = current.holes.reduce((acc, h, index) => {
														if (index >= 9) return acc + h.Par;
														return acc + 0;
													}, 0);
													let front = current.ratingFront ? {
														label: current.label + " F9 \n" + `(${parseFloat(current.ratingFront).toFixed(1)} / ${frontPar})`,
														unparsedLabel: current.label + ' F9',
														value: current.value,
														tee_set_side: 'F9'
													} : null;
													let back = current.ratingBack ? {
														label: current.label + " B9 \n" + `(${parseFloat(current.ratingBack).toFixed(1)} / ${backPar})`,
														unparsedLabel: current.label + ' B9',
														value: current.value,
														tee_set_side: 'B9'
													} : null;
													if(front && back) {
														return res.concat([front, back])
													}
													if (front) {
														return res.concat([front]);
													}
													if (back) {
														return res.concat([back]);
													}
													return null;
												}, []);
											} else {
												filteredTS = filteredTS.map(ts => {
													let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
													let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
													return {label: label, value: ts.value}
												})
											}
											this.props.change('tee_set_id', null);
											this.setState({
												teeSetsOptions: filteredTS,
												errors: []
											})
										})
								} else {
									this.props.change('tee_set_id', null);
									this.setState({
										teeSetsOptions: [],
										errors: []
									})
								}
							}}
								   defaultOptions={this.state.courses}
								   loadOptions={debounce(self.getCoursesOptions, 1000)}
								   disabled={!this.props.initialized || !this.props.score_type || fieldsDisabled}
								   component={AsyncSelectInput}
								   searchable={true}
								   type="text"
								   autoComplete="off"
								   validate={[required]}
								   hideErrorMessages={true}
								   id="course_name"
								   name="course_name"
								   placeholder="Enter Course Name"
									 grayPlaceholder={true}
									  />
						</div>
						<div className="col is-1-of-10">
							<label>Tee</label>
							<Field name={'tee_set_id'}
								   component={SelectInput}
								   disabled={!this.props.initialized || !this.props.score_type || fieldsDisabled || !this.props.golfer_id}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   placeholder="Men's Tees"
								   options={this.state.teeSetsOptions}
								   isSearchable={false}
								   validate={[required]}
								   hideErrorMessages={true}
							/>
						</div>

						<div className="col is-1-of-16">
							<label htmlFor="front9">Front 9</label>
							<Field hideErrorMessages validate={validateFrontBack ? [isNumber, required] : undefined} onChange={(event, newValue, prevValue) => {
								if (newValue.length === 1) {
									if (parseInt(newValue) === 0) {
										event.preventDefault();
										return;
									}

									let backValue = parseInt(this.props.back) || 0;
									this.props.change('total', backValue + parseInt(newValue));
								}
								if (newValue.length > 2) {
									event.preventDefault();
									return;
								}
								if(newValue.length === 2) {
									let backValue = parseInt(this.props.back) || 0;
									this.props.change('total', backValue + parseInt(newValue));
									document.getElementsByClassName('back9')[0].focus();
								}
							}} component={InputField} disabled={!this.props.golfer_id || !this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9")))} type="text" autoComplete="off" id="front9" name="front9" placeholder="Front 9" />
						</div>
						<div className="col is-1-of-16">
							<label htmlFor="back9">Back 9</label>
							<Field hideErrorMessages validate={validateFrontBack ? [isNumber, required] : undefined} onChange={(event, newValue, prevValue) => {
								if (newValue.length === 1) {
									if (parseInt(newValue) === 0) {
										event.preventDefault();
										return;
									}
									let frontValue = parseInt(this.props.front) || 0;
									this.props.change('total', frontValue + parseInt(newValue));
								}
								if (newValue.length > 2) {
									event.preventDefault();
								}
								if(newValue.length === 2) {
									let frontValue = parseInt(this.props.front) || 0;
									this.props.change('total', frontValue + parseInt(newValue));
								}

							}} className={'back9'} component={InputField} disabled={!this.props.golfer_id || !this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9")))} type="text" autoComplete="off" id="back9" name="back9" placeholder="Back 9" />
						</div>
						<div className="col is-1-of-16">
							<label htmlFor="total">Total</label>
							<Field hideErrorMessages onChange={(event, newValue, prevValue) => {
								if (this.props.number_of_holes === "9") {
									if (newValue.length > 2) event.preventDefault();
								} else {
									if (newValue.length > 3) event.preventDefault();
								}
							}}
								   validate={[isNumber]}
								   component={InputField}
								   disabled={!this.props.golfer_id || !this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18")))}
								   type="text"
								   autoComplete="off"
								   id="total"
								   name="total"
								   placeholder="Total" />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field name={"PlayerName"} component={(input) => {
								return <div className="pull-data__golfer-name">{input.input.value}</div>
							}}/>
							<Field name={"Display"} component={(input) => {
								return input.input.value && <div className="pull-data__golfer-handicap-index">Handicap Index: <span className="cardinal">{input.input.value}</span></div>
							}}/>
							<Field name={"Error"} component={(input) => {
								return input.input.value && <div className="pull-data__golfer-handicap-index"><span className="cardinal">{input.input.value}</span></div>
							}}/>
							{this.state.errors.length > 0 && this.state.errors.map(error=> {
									return (<p className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</p>);
								})
							}
							{this.props.errors && Object.keys(this.props.errors).map(err => {
							switch(err){
								case 'total':
									if (this.props.metas && this.props.metas.total && this.props.metas.total.touched) {
										if ((this.props.number_of_holes.value === "18t" || this.props.number_of_holes.value === "9" ) && this.props.tee_set_id) {
											return ( <p className="validation__message is-error">{this.props.errors[err]}</p>);
										} else {
											return null;
										}
									} else {
										return null;
									}
								case 'front9':
									if (this.props.metas && this.props.metas.front9 && this.props.metas.front9.touched) {
										if (!this.props.front) {
											return ( <p className="validation__message is-error">Please enter your score for the Front 9.</p>);
										} else {
											return ( <p className="validation__message is-error">{'The front ' + this.props.errors.front9}</p>);
										}
									} else {
										return null;
									}
								case 'back9':
									if (this.props.metas && this.props.metas.back9 && this.props.metas.back9.touched) {
										if (!this.props.back) {
											return ( <p className="validation__message is-error">Please enter your score for the Back 9.</p>);
										} else {
											return (
												<p className="validation__message is-error">{'The back ' + this.props.errors.back9}</p>);
										}
									} else {
										return null;
									}
								case 'course_name':
									if (this.props.metas && this.props.metas.course_name && this.props.metas.course_name.touched) {
										return (<p className="validation__message is-error">Please select a course.</p>);
									} else {
										return null;
									}
								case 'tee_set_id':
									if (this.props.metas && this.props.metas.tee_set_id && this.props.metas.tee_set_id.touched) {
										return (<p className="validation__message is-error">Please select Tees.</p>);
									} else {
										return null;
									}

								default:
									if (this.props[err]) {
										return ( <p className="validation__message is-error">{this.props.errors[err]}</p>);
									} else {
										return null;
									}
							}
						})}</div>
						<div className="col is-1-of-8 push-right">
							<button disabled={!this.props.initialized || !this.props.golfer_id || (!this.state.teeSetsOptions.length) || (!this.props.tee_set_id)} className="btn fill blue">Post Score</button>
						</div>

					</div>

				</form>

				<ConfirmationModalTypeB
					openModal={this.state.confirmationModalOpen}
					onConfirmAction={() => this.confirmScore()}
					modalIcon={'help_outline'}
					onCancelAction={() => {
						this.setState({
							confirmationModalOpen: false,
							savedEntry: null
						})
					}}
					closeModal={() => {
						this.setState({
							confirmationModalOpen: false,
							savedEntry: null
						})
					}}
					question={"Duplicate Score Found"}
					bodyQuestion={"This score may already exist in the golfers score history."}
					infoText={['Press "Continue" to post the score anyway', 'Press "Cancel" to review the score prior to posting']}
					cancelLabel={"Cancel"}
					confirmLabel={"Continue"}
				/>
			</Fragment>
		);
	}

}

PlayerEntry = reduxForm({
	form: 'multiplePlayerEntryForm',
	destroyOnUnmount: true,
	initialValues: {player_entry: 'golfer_id', played_at: new Date(), number_of_holes: { value: "18", label: '18 (9 + 9)' }, score_type: { value: 'H', label: 'Home'}}
})(PlayerEntry);

function mapStateToProps(state) {

	const selector = formValueSelector('multiplePlayerEntryForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		userAccess: state.user.access,
		course_name: selector(state, 'course_name'),
		golfer_gender: selector(state, 'gender'),
		golfer_id: selector(state, 'golfer_id'),
		number_of_holes: selector(state, 'number_of_holes'),
		score_type: selector(state, 'score_type'),
		tee_set_id: selector(state, 'tee_set_id'),
		total: selector(state, 'total'),
		front: selector(state, 'front9'),
		back: selector(state, 'back9'),
		played_at: selector(state, 'played_at'),
		date_setup: selector(state, 'date_setup'),
		hidden_courses: selector(state, 'hidden_courses'),
		hidden_course_name: selector(state, 'hidden_course_name'),
		player_entry: selector(state, 'player_entry'),
		player_entry_value: selector(state, 'player_entry_value'),
		hidden_male_tees: selector(state, 'hidden_male_tees'),
		hidden_female_tees: selector(state, 'hidden_female_tees'),
		default_male_tee: selector(state, 'default_male_tee'),
		default_female_tee: selector(state, 'default_female_tee'),
		hidden_number_of_holes: selector(state, 'hidden_number_of_holes'),
		hidden_score_type: selector(state, 'hidden_score_type'),
		errors: getFormSyncErrors('multiplePlayerEntryForm')(state),
		metas: getFormMeta('multiplePlayerEntryForm')(state),
		formValues: getFormValues('multiplePlayerEntryForm')(state)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(PlayerEntry);
