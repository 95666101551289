import React, { Component, Fragment } from "react";
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import "react-picky/dist/picky.css"; // Include CSS
import DatePicker from "react-datepicker";
import debounce from "debounce-promise";
import moment from "moment";

export default class GolferTableFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {
				course: '',
				status: null,
			},
		};
	}

	componentDidMount() {
		this.updateFilter();
	}

	updateFilter = (name, value) => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					[name]: value,
				},
			},
			() => {
				this.updateTable();
			}
		);
	};

	updateTable = debounce(() => {
		let filters = JSON.parse(JSON.stringify(this.state.filters));

		Object.keys(filters).forEach((f) => {
			if (filters[f] !== null) {
				if (Array.isArray(filters[f])) {
					let fv = filters[f];
					fv = fv
						.map((v) => v.value.toLowerCase().replace(" ", "_"))
						.join(",");
					filters[f] = fv;
				}
				if (typeof filters[f] === "object") {
					filters[f] = filters[f].value;
				}
			}
		});
		this.props.updateFiltered(filters);
	}, 500);

	reset() {
		this.setState(
			{
				filters: {
					course: "",
					status: null,
				},
			},
			() => {
				this.updateTable();
			}
		);
	}
	render() {
		if (this.props.hideFilters) return null;

		return (
			<Fragment>
				<div className="filters">
					<div className="row">
						<div className="filters__inputs col">
							<div className="row">
								<div className={"col"}>
									<div className="row">
										<div className="col is-1-of-5">
											<label htmlFor="name">
												Name
											</label>
											<input
												type="text"
												autoComplete="off"
												name="name"
												id="name"
												value={
													this.state.filters
														.name
												}
												onChange={(e) => {
													this.updateFilter(
														"name",
														e.target.value
													);
												}}
											/>
										</div>

										<div className={"col is-1-of-5"}>
											<GhinFilterSelect
												options={[
													"In progress",
													"Qualified",
												]}
												value={
													this.state.filters.status
												}
												onChange={(values) => {
													this.updateFilter(
														"status",
														values
													);
												}}
												label={"Scheduled Status"}
											/>
										</div>
										<div className={"col is-1-of-5"}>
											<GhinFilterSelect
												options={[
													"Male",
													"Female"
												]}
												value={
													this.state.filters.gender
												}
												onChange={(values) => {
													this.updateFilter(
														"gender",
														values
													);
												}}
												label={"Gender"}
											/>
										</div>
										<div
											className={
												"col is-1-of-7 push-right"
											}
										>
											<button
												className="btn fill gray top"
												onClick={this.reset.bind(this)}
											>
												Reset
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
