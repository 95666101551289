import request from '../shared/request';

export default class ChallengesService {
	static postChallenge(data) {
		return request({
			url: `/associations/${data.id}/challenge.json`,
			method: 'POST',
			data
		})
	}

	static getChallenges(id, params) {
		return request({
			url: `/associations/${id}/challenges.json`,
			method: 'GET',
      params: params
		})
	}

	static getChallenge(id) {
		return request({
			url: `/challenges/${id}.json`,
			method: 'GET',
		})
	}

	static getAssociationSettings(id) {
		return request({
			url: `/associations/${id}/club_challenges_enabled.json`,
			method: 'GET',
		})
	}

	static getGolferChallenges(golferId) {
		return request({
			url: `/golfer/${golferId}/challenges.json`,
			method: 'GET',
		})
	}

  static getGolfers(id, params) {
		return request({
			url: `/challenges/${id}/golfers.json`,
			method: 'GET',
      params: params
		})
  }

  static deleteChallenge(id) {
		return request({
			url: `/challenge/${id}.json`,
			method: 'DELETE',
		})
  }

  static removeGolfer(data) {
		return request({
			url: `/challenge/${data.challenge_id}/golfers/${data.golfer_id}.json`,
			method: 'DELETE',
		})
  }

  static updateChallenge(data) {
		return request({
			url: `/challenge/${data.id}.json`,
			method: 'PATCH',
      data
		})
  }

	static enableChallenges(data) {
		return request({
			url: `/associations/${data.association_id}/enable_club_challenges.json`,
			method: 'PATCH',
      data
		})
  }

}
