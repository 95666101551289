import React, {Component, Fragment} from 'react';
import TableFilter from "./TableFilter";
import {NavLink} from 'react-router-dom';
import GhinTable from "../../shared/GhinTable";
import TableService from "../../../services/tableService";
import GuardianService from "../../../services/api/guardian";

const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};

export default class GuardianSearch extends  Component {


	constructor(props) {
		super(props);
    const queryString = new URLSearchParams(this.props.location.search);
    const searchText = queryString.get("q");
    const isEmail = searchText.includes('@');
    const query = isEmail ? searchText.replace(/ /g, '+') : searchText;
		this.state = {
			tableFilterIsHidden: false,
			associations: [],
			filtered: [],
			sorted: [{id: "full_name", desc:false}],
			searchText: query || '',
			isAllSelected: false,
			guardians: [],
			page: 0,
			per_page: 25,
			isExportDataOpened: false,
			selectedGolfers: [],
			tableInternalData: [] //used to get filtered array from the table
		};
	}

	componentDidMount() {
		this.loadGuardianList();
	}

	getRequestParams() {

		let params = {
			page: (this.state.page) + 1,
			per_page: this.state.per_page,
			global_search: true
		};

		if (this.state.sorted.length > 0) {
			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		}

		params = {
			...params,
			...TableService.filtersArrayToParams(this.state.filtered)
		}

		if (!params['search']) {
			params['search'] = this.state.searchText;
		}

		return params;
	}

	updateFilter(filtered) {
		this.setState({
			filtered,
			page: 0
		}, () => {
			this.loadGuardianList();
		})

	}

	loadGuardianList() {
		this.setState({
			loading: true,
			golfers: []
		});
		const params = this.getRequestParams();
		GuardianService.getGuardians(params).then((data) => {
			this.setState({
				loading: false,
				isAllSelected: false,
				global_search: true,
				guardians: data.guardians.map((i) => {
					return {
						...i,
						...{full_name: generateGolferName(i)}
						//date: moment(i.date).format("MM/DD/YYYY")
					}
				}),
				total: parseInt(data['total-count'], 10),
				per_page: this.state.per_page,
				pages: parseInt(data['total-pages'], 10)

			})
		});
	}


	render() {
		const columns = [
			{
				accessor: 'id',
				show: false
			},
			{
				Header: 'Guardian Name',
				accessor: 'full_name',
				filterMethod: (filter, row) =>
					row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
				Cell: props =>
				{
					if (props.original.golfer_id) {
						return (
							<Fragment>
								<NavLink to={`/manage/association/${props.original.golf_association_id}/club/${props.original.club_id}/golfer/${props.original.golfer_id}`}>{props.row.full_name}</NavLink>
							</Fragment>
						)
					} else {
						return (
							<Fragment>
								<NavLink to={`/manage/guardian/${props.original.id}`}>{props.row.full_name}</NavLink>
							</Fragment>
						)
					}
				}
				,
				// colClassName: 'col_golfer-name-short'
			},
			{
				Header: 'Guardian GHIN #',
				Cell: props => {
					return (<>{props.original.golfer_id || "N/A"}</>)
				},
				colClassName: 'col_ghin'
			},
			{
				Header: 'Guardianship Status',
				accessor: 'status',
				colClassName: 'col_guardianship_status'
			},
			{
				Header: 'Relationship',
				Cell: (props) => <>{props.original.relationship === 'Other'  ?  `Other - ${props.original.other_relationship}` : props.original.relationship}  </>,
				colClassName: 'col_relationship'

			},
			{
				Header: 'Guardian Email Address',
				accessor: 'email',
				sortable: false,
				colClassName: 'col_club-name',
				Cell: props =>
					props.row._original.entitlement ?
						<Fragment>
							<a href={`mailto:${props.value}`}>{props.value}</a>
						</Fragment>
						:
						<span>{props.value}</span>

			},
			{
				Header: 'Golfer Name',
				accessor: 'minor_name',
				colClassName: 'col_golfer-name'
			},


		];

		return (
			<section className="default">

				<TableFilter updateFiltered={this.updateFilter.bind(this)} searchText={this.state.searchText}/>

				<h2 className="section__title large">Guardian Results ({this.state.total ? this.state.total : 0})</h2>

				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					manual
					pageSize={this.state.per_page}
					data={this.state.guardians}
					ref={(r) => this.table = r}
					onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadGuardianList())}
					onPageSizeChange={(pageSize, pageIndex) => this.setState({
						per_page: pageSize,
						page: pageIndex
					}, () => this.loadGuardianList())}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState({
							sorted: newSorted,
							page: 0
						}, () => {
							this.loadGuardianList()
						})
					}}
					hideSelect={true}
				/>

			</section>
		)
	}
}
