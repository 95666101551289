import { Fragment } from "react"
import EditClubChallengesEnabledForm from "../Forms/EditClubChallengesEnabledForm"

export default function EditClubChallengesEnabledModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">
        <div className="modal__head">
          {
            props.enable_club_challenges ?
              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              :
              <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
          }
          <h2 className="modal__title">Enable Challenges</h2>
          <button className="modal__close" onClick={() => { props.closeModal() }}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          <EditClubChallengesEnabledForm
            {...props}
          />
        </div>
      </div>
    </Fragment>
  )
}