import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import GhinTable from "../shared/GhinTable";
import {NavLink} from 'react-router-dom';

class ViewScoreModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
		this.onConfirm = this.onConfirm.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.openModal !== this.props.openModal)
		this.setState({loading:false})
	}

	onConfirm() {
		this.setState({loading: true});
		this.props.onConfirmAction();
	}

	render() {
		var scores, ranking, golfers;
		if (this.props.challenge) {
			scores = this.props.challenge[0]?.scores;
			ranking = this.props.challenge[0]?.ranking;
			golfers = this.props.challenge[0]?.golfers;
		}
		else {
			scores = [];
			ranking = "";
			golfers = "";
		}
		const columns = [
			{
				Header: '#',
				accessor: 'id',
				colClassName: 'col_id',
				show: false
			},
			{
				Header: "Adj. Gross Score",
				accessor: "adjusted_gross_score",
				thClassName: "is-3-of-9 wrap-header",
				sortable: true,
			},
			{
				Header: "Course Handicap",
				accessor: "course_handicap",
				thClassName: "is-2-of-9 wrap-header",
				sortable: true,
			},
			{
				Header: "Net Score",
				accessor: "net_score",
				thClassName: "is-2-of-10 wrap-header",
				sortable: true,
			},
			{
				Header: "C.R. / Slope",
				accessor: "cr_slope",
				thClassName: "is-4-of-10",
				sortable: false,
			},
			{
				Header: "Tees",
				accessor: "tee_name",
				thClassName: "is-3-of-9",
				sortable: false,
			},
			{
				Header: "Date Played",
				accessor: "played_at",
				thClassName: "is-3-of-9",
				sortable: false,
			},
			{
				Header: "Date Posted",
				accessor: "created_at",
				thClassName: "is-3-of-9",
				sortable: false,
			},
		];
		return (
			<Modal
				isOpen={this.props.openModal}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={false}
			>

				<div className="modal__container confirmation narrow">
					<div className={`modal__head ${this.props.longQuestion ? 'auto__height' : ''}`}>
						<h2 className={`modal__title ${this.props.longQuestion ? 'long' : ''}`}>{this.props.question}</h2>
						<button className="modal__close" onClick={() => this.props.closeModal()}><i
							className="material-icons-outlined">clear</i></button>
					</div>
					<div className="modal__body">
						{this.state.loading ?
							<table style={{width: '100%'}}>
								<tbody>
								<tr>
									<td style={{textAlign: 'center'}}>
										<span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>
							:

							<Fragment>
								{this.props.bodyQuestion && <div style={{textAlign: 'center'}}><h2>{this.props.bodyQuestion}</h2></div>}
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<div style={{ textAlign: 'left' }}>
										<h2>{this.props.viewedGolfer}</h2>
									</div>
									<div style={{ textAlign: 'right', marginBottom: '20px', fontFamily: 'National' }}>
										<div>
											<span style={{color: 'rgba(51, 51, 51, 1)', fontWeight: '600', fontSize: '18px'}}>{`${ranking}/${golfers}`}</span>
										</div>
										<div>
											<span style={{color: 'rgba(51, 51, 51, 1)', fontWeight: '500', fontSize: '16px'}}>Current Position</span>
										</div>
									</div>
								</div>
								<div style={{textAlign: 'center'}}><h3>{"Scores Posted to Challenge"}</h3></div>
                  <div className="pre__table">
                    <div className="row">

                      <div className="col auto push-right">
                        <div className="row">
                        </div>
                      </div>
                    </div>
                  </div>
                  <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    data={scores}
                    hidePagination={true}
                    manual
                    hideSelect
                  />
									<div className="row">
										<div className="col">
											<button type="submit" disabled={this.props.loading} onClick={this.onConfirm}
													className="btn fill blue">
														{this.props.fromClub
															? <NavLink style={{ all: 'unset' }} to={`../../../../../${this.props.viewedGolferAssociationId || 2}/club/${this.props.viewedGolferPrimaryClub}/golfer/${this.props.viewedGolferId}`}>{this.props.confirmLabel}</NavLink>
															: <NavLink style={{ all: 'unset' }} to={`../../../${this.props.viewedGolferAssociationId || 2}/club/${this.props.viewedGolferPrimaryClub}/golfer/${this.props.viewedGolferId}`}>{this.props.confirmLabel}</NavLink>
														}
													</button>
										</div>
									</div>
							</Fragment>

						}
					</div>
				</div>
			</Modal>
		)
	}
}

export default ViewScoreModal;
