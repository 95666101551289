import React, { Component, Fragment } from "react";

import { bindActionCreators } from "redux";
import { addAlert } from "../shared/actions";
import { connect } from "react-redux";
import EditChallengeForm from "../Forms/EditChallengeForm";



class EditChallengeModal extends Component {
	render() {
    
		return (
			<Fragment>
				<div className={`modal__container narrow`}>
					<div className="modal__head">
						<div className="modal__icon">
							<i className="material-icons-outlined">
								add_circle_outline
							</i>
						</div>
						<h2 className="modal__title">Edit Challenge</h2>
						<button
							className="modal__close"
							onClick={() => {
								this.props.closeModal();
							}}
						>
							<i className="material-icons-outlined">clear</i>
						</button>
					</div>

					<div className="modal__body">
						<EditChallengeForm
              challenge={this.props.challenge}
							challengeId={this.props.challengeId}
							user={this.props.user}
							close={() => this.props.closeModal()}
							showSuccess={() => {
								this.props.closeModal();
							}}
							setTableRefresh={(value) =>
								this.props.setTableRefresh(value)
							}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(null, mapDispatchToProps)(EditChallengeModal);
