import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import GolferService from "../../../services/api/golfer";
import {addAlert} from "../../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import GhinTable from "../../shared/GhinTable";
import moment from 'moment';
import EditEmailSubscriptionModal from '../../Modals/EditEmailSubscriptionModal';
import AssociationService from "../../../services/api/association";
import {getAllPermissions} from "../../../permissionManager";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import EditNotificationsModal from "../../Modals/EditNotificationsModal";
import ContactSettings from './ContactSettings';

class SubscriptionManagement extends Component {

	constructor(props) {

		super(props);

		this.state = {
			editEmailSubscriptionModal: false,
			resetFreeTrialModal: false,
			subscriptions: [],
			loading: true,
			gpsSubscriptions: null,
			freeTrials: null,
			gpsLoading: true,
			editNotificationsModalIsOpen: false,
      showDoNotContact: false,
			golferAge: this.props.golfer.date_of_birth ? moment().diff(moment(this.props.golfer.date_of_birth), 'years') : this.props.date_of_birth ? moment().diff(moment(this.props.date_of_birth), 'years') : 30
		};

		this.openEditEmailSubscriptionModal = this.openEditEmailSubscriptionModal.bind(this);
		this.editEmailSubscription = this.editEmailSubscription.bind(this);
		this.openEditNotificationsModal = this.openEditNotificationsModal.bind(this);
    this.setShowDoNotContact = this.setShowDoNotContact.bind(this);
  }

  setShowDoNotContact(value) {
    this.setState({
      showDoNotContact: value
    })
  }

	openEditEmailSubscriptionModal(subscription) {
		this.setState({editEmailSubscriptionModal: subscription});
	}

	openEditNotificationsModal() {
		this.setState({editNotificationsModalIsOpen: true});
	}

	closeModal(data) {
		if(data){
			this.props.updateGolfer(data.golfers);
		}
		this.setState({
			editEmailSubscriptionModal: false,
			editNotificationsModalIsOpen: false,
		});
	}

	componentDidMount() {
		this.getSubscriptions()
		this.getGpsSubscriptions();
    this.getDoNotContact();
		
		if (this.state.golferAge >= 19) {
			this.getNotificationsPreferences();
		}
		
		Modal.setAppElement('body');
  }

  getDoNotContact() {
    GolferService.getContactSettings(this.props.golfer.id)
      .then(res => {
        this.setState({
          declineContactSettings: res.decline_contact_settings,
          showDoNotContact: true
        })
      })
  }

	getSubscriptions() {
		GolferService.getGolferSubscriptions(this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id)
			.then(res => {
				this.setState({
					subscriptions: res.subscriptions.map((subscription,key) => {
            let isFed = subscription.sub_type === "fed";
            let email_campaign = isFed ? "USGA Newsletter" : "Newsletter";
            let sent_by = isFed ? "USGA" : subscription.golf_association_name;
						return {
							...subscription,
							id: key,
							email_campaign: email_campaign,
							sent_by: sent_by,
							subscribed: subscription.unsubscribed ? 'No' : 'Yes',
							date_of_change: subscription.updated_at ? moment(new Date(subscription.updated_at + '.000Z')).format('MM/DD/YYYY') : null,
							note: subscription.note ? subscription.note  : '-'
						}
					}),
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	getGpsSubscriptions() {
		GolferService.getGolferGpsSubscriptions(this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id)
			.then(res => {
				this.setState({
					gpsSubscriptions: res.gps_subscriptions.filter(sub => sub.active)[0] || res.gps_subscriptions[0],
					freeTrials: res.free_trials,
					gpsLoading: false
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	editEmailSubscription(params, subscription){
		if(params.subscribed === "true" || params.subscribed === true) {
			return AssociationService.subscribeUser(subscription._original.golf_association_id, this.props.golfer.id, params.note)
				.then(res => {
					this.setState({loading:true,editEmailSubscriptionModal: false, subscriptions: []}, () => this.getSubscriptions())
				})
		} else {
			return AssociationService.unsubscribeUser(subscription._original.golf_association_id, this.props.golfer.id, params.note)
				.then(res => {
					this.setState({loading:true,editEmailSubscriptionModal: false, subscriptions: []}, () => this.getSubscriptions())
				})
		}
	}

	resetFreeTrial(){
		GolferService.resetFreeTrial(this.props.golfer.id)
			.then(res => {
				this.setState({gpsLoading:true, resetFreeTrialModal: false, gpsSubscriptions: [], freeTrials: null}, () => this.getGpsSubscriptions())
			})
	}

	getNotificationsPreferences() {
		GolferService.getGolferNotificationsPreferences(this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id)
			.then(res => {
				this.setState({
					handicap_index: res.notification_preference.handicap_index,
					score_posted: res.notification_preference.score_posted,
					score_updated: res.notification_preference.score_updated,
					update_profile: res.notification_preference.update_profile,
          draft_scores: res.notification_preference.draft_scores,
					tournaments_and_events: res.notification_preference.tournaments_and_events,
					member_benefits_and_promotions: res.notification_preference.member_benefits_and_promotions,
					general_updates: res.notification_preference.general_updates,
					challenges: res.notification_preference.challenges,
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {

		let allPermisions = getAllPermissions(this.props.rawAccess);

		const golferAge = this.props.golfer.date_of_birth ? moment().diff(moment(this.props.golfer.date_of_birth), 'years') : this.props.date_of_birth ? moment().diff(moment(this.props.date_of_birth), 'years') : 30;
		const golferIsJunior =  golferAge < 19 && golferAge > 13;
		const golferIsMinor = golferAge <= 13;
		const golferIsDeceased = this.props.golfer.deceased

		const columns = [
			{
				Header: 'Email Campaign',
				accessor: 'email_campaign',
			},
			{
				Header: 'Sent By',
				accessor: 'sent_by',
			},
			{
				Header: 'Changed By',
				accessor: 'changed_by',
			},
			{
				Header: 'Method',
				accessor: 'method',
			},
			{
				Header: 'Subscribed?',
				accessor: 'subscribed',
			},
			{
				Header: 'Note',
				accessor: 'note',
			},
			{
				Header: 'Date of Change',
				accessor: 'date_of_change',
			},
			{
				Header: '',
				accessor: 'edit',
				colClassName: 'col_edit',
				show: !this.props.archived && !this.props.publicView && (allPermisions.associations.filter(assoc => assoc === this.props.golfer.association_id).length > 0 || allPermisions.clubs.filter(assoc => assoc === parseInt(this.props.golfer.club_id)).length > 0 || this.props.user_type === "super-admin"),
				Cell: props => {
          if (!golferIsDeceased && (allPermisions.associations.filter(assoc => assoc === this.props.golfer.association_id).length > 0 || allPermisions.clubs.filter(assoc => assoc === parseInt(this.props.golfer.club_id)).length > 0 || this.props.user_type === "super-admin")) {
						return (
							<button className="btn lnk" onClick={()=>{ this.openEditEmailSubscriptionModal(props.row)}}>Edit</button>
						)
					} else {
						return null;
					}
				}
			},
		];
		return (
            <Fragment>

				{this.props.golfer && <div className="columns">

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Email Subscription Management</div>
								<div className="panel__body">

									<div className='row'>
										<div className="col jc-fs">

											<GhinTable
												loading={this.state.loading}
												columns={columns}
												ref={(r) => this.table = r}
												hideSelect
												hidePagination={true}
												data={this.state.subscriptions}
												sortable={true}
											/>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					{!this.state.gpsLoading && (!(this.props.golfer.is_minor) || (golferIsJunior && this.props.golfer.email)) && <div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Enhanced GPS</div>
								<div className="panel__body">
									<div className="row">
										<div className="col jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Active Subscription?</span>
													<span>{this.state.gpsSubscriptions.active ? "Yes" : "No"}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Subscription App Type:</span>
													<span>{this.state.gpsSubscriptions.subscription_app_type}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Subscription Type:</span>
													<span>{this.state.gpsSubscriptions.subscription_type}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Initial Subscription Date:</span>
													<span>{this.state.gpsSubscriptions.initial_subscription_date !== "-" ? moment(new Date(this.state.gpsSubscriptions.initial_subscription_date)).format("MM/DD/YYYY") : "-"}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Current Subscription Renewal Type:</span>
													<span>{this.state.gpsSubscriptions.current_subscription_renewal_type}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Current Subscription Start Date:</span>
													<span>{this.state.gpsSubscriptions.current_subscription_start_date !== "-" ? moment(new Date(this.state.gpsSubscriptions.current_subscription_start_date)).format("MM/DD/YYYY") : "-"}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Current Subscription End Date:</span>
													<span>{this.state.gpsSubscriptions.current_subscription_end_date !== "-" ? moment(new Date(this.state.gpsSubscriptions.current_subscription_end_date)).format("MM/DD/YYYY") : "-"}</span>
												</div>
											</div>
										</div>
										<div className="col  push-right">
											<div className="row">
												{this.state.freeTrials && <table className="data__table">
													<colgroup>
														<col className=""/>
														<col className="col_state"/>
														<col className=""/>
													</colgroup>
													<thead>
													<tr>
														<th>Free Trial</th>
														<th>Complete?</th>
														<th>Date Completed</th>
													</tr>
													</thead>
													<tbody>
													<tr>
														<td>Trial 1 (18 or 9 Hole Round)</td>
														<td>{this.state.freeTrials["18hole_complete"] ? "Yes" : "No"}</td>
														<td>{this.state.freeTrials["18hole_date_completed"] !== "-" ? moment(new Date(this.state.freeTrials["18hole_date_completed"])).format("MM/DD/YYYY HH:mm:ss") : "-"}</td>
													</tr>
													<tr>
														<td>Trial 2 (9 Holes)</td>
														<td>{this.state.freeTrials["9hole_complete"] ? "Yes" : "No"}</td>
														<td>{this.state.freeTrials["9hole_date_completed"] !== "-" ? moment(new Date(this.state.freeTrials["9hole_date_completed"])).format("MM/DD/YYYY HH:mm:ss") : "-" }</td>
													</tr>
													</tbody>
												</table>}
											</div>
											{this.state.freeTrials && !golferIsDeceased && (this.state.freeTrials["18hole_complete"] || this.state.freeTrials["9hole_complete"]) && !this.state.gpsSubscriptions.active && <div className="row">
												<button className={'btn fill green'} onClick={()=>{this.setState({resetFreeTrialModal: true})}}>Reset Free Trial</button>
											</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}

					{!golferIsMinor && !golferIsJunior && <div class="row">
						<div class="col">
							<div class="panel">
								<div class="panel__head">Notification Settings</div>
								<div class="panel__body">
									<div class="row">
										<div className="col">
											<span><strong>Note:</strong> Push Notifications must be enabled by the golfer within the GHIN Mobile App. As an Admin, you only have the ability to disable Push Notifications for the golfer.</span>
										</div>

                    {!golferIsDeceased && <div class="col auto push-right">
											<button onClick={this.openEditNotificationsModal} class="btn lnk">Edit</button>
                    </div>}
									</div>

									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Handicap Index Update:</span>
											<span>{this.state.handicap_index ? "Yes" : "No"}</span>
										</div>
									</div>
									{/* <div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Score Posted</span>
											<span>{this.state.score_posted ? "Yes" : "No"}</span>
										</div>
									</div> */}
									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Score Edited or Deleted:</span>
											<span>{this.state.score_updated ? "Yes" : "No"}</span>
										</div>
									</div>

									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Profile Update:</span>
											<span>{this.state.update_profile ? "Yes" : "No"}</span>
										</div>
									</div>
									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Score Sent By Another Golfer:</span>
											<span>{this.state.draft_scores ? "Yes" : "No"}</span>
										</div>
									</div>
									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Tournaments & Events:</span>
											<span>{this.state.tournaments_and_events ? "Yes" : "No"}</span>
										</div>
									</div>

									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">Member Benefits & Promotions:</span>
											<span>{this.state.member_benefits_and_promotions ? "Yes" : "No"}</span>
										</div>
									</div>

									<div class="data-set__container">
										<div class="data-set">
											<span class="data-label">General Updates:</span>
											<span>{this.state.general_updates ? "Yes" : "No"}</span>
										</div>
									</div>

									<div className="data-set__container">
										<div className="data-set">
											<span className="data-label">Challenges:</span>
											<span>{this.state.challenges ? "Yes" : "No"}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}
					
          {this.state.showDoNotContact && <div className="row">
            <div className="col">
              <div className="panel">
                <div className="panel__head">
                  <div>Do Not Contact Settings</div>
                  {golferIsDeceased && <div style={{ "marginLeft": "auto", "marginRight": 0, "color": "red" }}>Do Not Contact settings cannot be changed because the golfer is marked as "Deceased" in the Primary tab.</div>}
                </div>
                <div className="panel__body">

                  <div className='row'>
                    <div className="col jc-fs">

                      <ContactSettings
                        golfer={this.props.golfer}
                        user_type={this.props.user_type}
                        associationId={this.props.associationId}
                        decline_contact_settings={this.state.declineContactSettings}
                      />

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>}

				</div>}

				<Modal
					isOpen={this.state.editEmailSubscriptionModal ? true : false}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditEmailSubscriptionModal  subscription={this.state.editEmailSubscriptionModal} onSubmit={(params)=> this.editEmailSubscription(params, this.state.editEmailSubscriptionModal)}closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.resetFreeTrialModal}
					onConfirmAction={()=>{this.setState({resetFreeTrialModal: false});this.resetFreeTrial()}}
					onCancelAction={()=>{this.setState({resetFreeTrialModal: false})}}
					closeModal={()=>{this.setState({resetFreeTrialModal: false})}}
					question={`Are you sure you want to reset the free trial for ${this.props.golfer.player_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				<Modal
					isOpen={this.state.editNotificationsModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditNotificationsModal closeModal={(data)=>{this.closeModal(data)}} notifications_preferences={{
						'handicap_index': this.state.handicap_index,
						'score_posted': this.state.score_posted,
						'score_updated': this.state.score_updated,
						'update_profile': this.state.update_profile,
            'draft_scores': this.state.draft_scores,
						'tournaments_and_events': this.state.tournaments_and_events,
						'member_benefits_and_promotions': this.state.member_benefits_and_promotions,
						'general_updates': this.state.general_updates,
						'challenges': this.state.challenges
					}} golfer_id={this.props.golfer.id}  club_id={this.props.golfer.club_id}/>
				</Modal>

            </Fragment>
		);
	}
}
function mapStateToProps(state){
	return {
		user_type: state.user.access.user_type,
		rawAccess: state.user.rawAccess,
	}
}
function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManagement);
