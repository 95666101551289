import ClubChallenges from "./ClubChallenges";
import ClubChallenge from "./ClubChallenge";
import KioskSetupClub from "./KioskSetupClub";
import { Fragment, useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter, NavLink } from "react-router-dom";
import { compose } from "redux";
import AssociationService from "./../../../../services/api/association";
import { connect } from "react-redux";

function GolferProducts(props) {
	const [clubChallengesEnabled, setClubChallengesEnabled] = useState(false);

	useEffect(() => {
		const fetchClubChallengesEnabled = async () => {
			try {
				const res = await AssociationService.getGolferProducts(props.associationId);
				setClubChallengesEnabled(res.association_settings.club_challenges_enabled);
			} catch (error) {
				console.error(error);
			}
		};

		fetchClubChallengesEnabled();
	}, [props.associationId]);

	return (
		<Fragment>
			<div className="vertical-tabs__container row">
				<ul className="vertical-tabs col">
					{process.env.REACT_APP_TYPE !== "hv" && (
						<Fragment>
							<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/kiosk-setup`}>Kiosk Setup</NavLink></li>
							{clubChallengesEnabled && (
								<li>
									<NavLink
										className="vertical-tab"
										activeClassName="is-active"
										to={`${props.match.url}/challenges`}
									>
										Challenges
									</NavLink>
								</li>
							)}
						</Fragment>
					)}
				</ul>
				<div className="vertical-tabs__panel col">
					<Switch>
						<Redirect
							exact
							from={`${props.match.url}`}
							to={`${props.match.url}/kiosk-setup`}
						/>
						{process.env.REACT_APP_TYPE !== "hv" && 
							<Route path={`${props.match.path}/kiosk-setup`} component={() =>
								<KioskSetupClub
									clubId={props.clubId}
									associationId={props.associationId}
									canEditClub={props.canEditClub}
									club={props.club}
									clubName={props.clubName}
								/>}
							/>
						}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								exact
								path={`${props.match.path}/challenges`}
								component={() => (
									<ClubChallenges
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
                    clubId={props.clubId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/challenges/:id`}
								component={() => (
									<ClubChallenge
										canEditAssociation={props.canEditAssociation}
										associationId={props.associationId}
                    clubId={props.clubId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
										updateChallenge={props.updateChallenge}
									/>
								)}
							/>
						)}
					</Switch>
				</div>
			</div>
		</Fragment>
	);
}

function mapStateToProps(state) {
	return {
		userType: state.user.access.user_type,
		user: state.user
	};
}

export default compose(withRouter, connect(mapStateToProps))(GolferProducts);
