import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
	required,
	maxLength,
	handicapIndex,
	golfer_age,
	number_of_scores,
	checkSignIsSelected,
	isNumeric,
	isUrl,
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { addAlert } from "../shared/actions";
import { SelectInput } from "../../inputs/Select";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { DatePickerInput } from "../../inputs/DatePicker";
import {requiredSelect, biggerThanPuts} from "../../inputs/validations";
import { Textarea } from "../../inputs/Textarea";
import GhinFilterSelect from "../shared/GhinFilterSelect";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import debounce from "debounce-promise";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import AssociationService from "../../services/api/association";
import UploadPushNotificationImage from "../../inputs/UploadPushNotificationImage";
import moment from "moment";
import ChallengesService from "../../services/api/challenges";
import { store } from '../../store';
import FacilityService from "../../services/api/facilities";

const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

class EditChallengeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
      errors: [],
      course: null,
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
	}

	componentDidMount() {
	}

  changeCourse(newValue) {
		if (newValue) {
			this.props.change('course_name.courseId', newValue);
    }
	}


	getCoursesOptions(searchTerm, callback) {
		var id = (store.getState().user.access.super_user? "" : store.getState().association.profile.crs_id)

			
		if (searchTerm && searchTerm.length > 2) {
			return FacilityService.getAssociationFacilities(searchTerm, id )
				.then(facilities => {
					const allCourses = facilities.reduce((acc, facility) => {
						if (facility.Courses && facility.Courses.length > 0) {
							return acc.concat(facility.Courses);
						}
						return acc;
					}, []);
					
					const options = allCourses.map(course => ({
						value: course.CourseId,
						label: course.CourseName,
						unparsedLabel: course.CourseName
					}));
					
					this.setState({ courses: options });
					callback(options);
				})
				.catch(err => {
					console.error(err);
				});
		}
		
		return new Promise(resolve => {
			resolve([]);
		});
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
    console.log(values);
    data.course_id = data.course_name.value;
    data.id = this.props.challengeId;
    data.status = "upcoming"

    return ChallengesService.updateChallenge(data).then(() => {
      if(this.props.setTableRefresh) this.props.setTableRefresh(true);
      this.props.addAlert({type: 'success', message: 'Challenge successfully updated'});
      this.props.close();
    }).catch(err => {
      let errors = [];
			if (err.data.errors)
				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});
			if (err.data.error)
				errors.push(err.data.error);
      this.setState({errors})
    });
	}

	render() {
		const { handleSubmit, pristine, submitting, invalid } = this.props;
		const noteMaxLength = 100 / 0;
		return (
			<Fragment>
				<form
					autoComplete="off"
					onSubmit={handleSubmit(this.onSubmit)}
					ref={"form"}
				>
					<div className="row">
						<div className="col">
							<p className="pn_bold">
								Challenge Overview
							</p>
						</div>
					</div>
					{/* First row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-1">
							<label htmlFor="notification_category">
								Course <span>*</span>
							</label>
							<Field name={'course_name'}
												   id={'course_name'}
												   validate={[requiredSelect]}
												   defaultOptions={this.state.courses}
												   loadOptions={debounce(this.getCoursesOptions, 1000)}
												   component={AsyncSelectInput}
												   onChange={(event, newValue, prevValue) => {
													   this.changeCourse(newValue);
												   }}
												   className="react-select-container"
												   classNamePrefix="react-select"
											/>
						</div>
					</div>
					{/* First row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-1">
							<label htmlFor="notification_category">
								Challenge Name <span>*</span>
							</label>
							<Field component={InputField}
								   validate={[required]}
								   type="text"
								   name="name"
								   id="name" />
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="date_of_birth">Start Date <span>*</span></label>
							<Field className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   dateFormatCalendar={"MM/dd"}
								   dateFormat={'MM/dd'}
								   propperPlacement={"top"}
								   name={'start_date'}
								   id={'start_date'}
								   component={DatePickerInput}
								   validate={[required]}
								   placeholderText="MM/DD"
								   placeholder="Select ..." />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="date_of_birth">End Date <span>*</span></label>
							<Field className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   propperPlacement={"top"}
								   dateFormatCalendar={"MM/dd"}
								   dateFormat={'MM/dd'}
								   name={'end_date'}
								   id={'end_date'}
								   component={DatePickerInput}
								   validate={[required]}
								   placeholderText="MM/DD"
								   placeholder="Select ..." />
						</div>
					</div>
          <div className="challenge-form-settings">
            <h3>Challenge Settings</h3>
            <p>Currently, challenges are limited to the following settings:</p>
            <ul>
              <li>18-hole scores</li>
              <li>Net stroke play</li>
              <li>3+ rounds to qualify</li>
            </ul>
          </div>
          {this.state.errors.length > 0 &&<div className={"row"}>
            <div className={"col is-full"}>
              <p>
                {this.state.errors.map(error => {
                  return (<span
                    className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
                })}
              </p>
            </div>
          </div>}

          <div className="row">
              <div className="col is-1-of-2">
                  <button type={'button'} className="btn fill gray" onClick={()=>{this.props.close()}}>Cancel</button>
              </div>
              <div className="col is-1-of-2">
                  <button className="btn fill blue" disabled={submitting} type={'submit'} ref={'submit'}>Save</button>
              </div>
          </div>
				</form>
			</Fragment>
		);
	}
}

EditChallengeForm = reduxForm({
	form: "editChallengeForm",
	destroyOnUnmount: true,
})(EditChallengeForm);

function mapStateToProps(state, ownProps) {
  const startDate = ownProps?.challenge?.start_date
    ? new Date(ownProps.challenge.start_date)
    : null;
  const endDate = ownProps?.challenge?.end_date
    ? new Date(ownProps.challenge.end_date)
    : null;
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		initialValues: {
      course_name: {label: ownProps?.challenge?.course_name, value: ownProps?.challenge?.course_id},
			name: ownProps?.challenge?.name,
      start_date: startDate,
      end_date: endDate
		},
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(EditChallengeForm));
