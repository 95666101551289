import React, { Component, Fragment } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import GolferService from "../../services/api/golfer";
import { addAlert } from "../shared/actions";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditNotificationsForm extends Component {
  constructor(props){
		super(props);
    this.state = this.props.notifications_preferences
    this.onSubmit = this.onSubmit.bind(this);
	}

  onSubmit() {
    let data = {
      "golfer_notification_preference": this.state,
      "source": "Admin Portal"
    }

    return GolferService.updateGolfer(this.props.club_id, this.props.golfer_id, data)
      .then(res => {
        this.props.addAlert({
					type:'success',
					message:'Golfer Notifications Preferences has been successfully edited'
				});
				this.props.close(res);
      })
      .catch(error => {
        console.error(error)
      })
  }
  
  render() {
    const {handleSubmit, pristine, submitting, invalid} = this.props;

    return (
      <Fragment>

        <form autocomplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

          <div class="row">
            <div class="col is-1-of-2">
              <span class="label">Handicap Index Update</span>
              <div class="radio__container">
                <Field
                  checked={this.state.handicap_index}
                  id="handicap-yes"
                  value={true}
                  name={'handicap'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="handicap-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {handicap_index: false};
                      });
                    }
                  }
                  checked={!this.state.handicap_index}
                  id="handicap-no"
                  value={false}
                  name={'handicap'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="handicap-no">No</label>
              </div>
            </div>

            <div class="col is-2-of-2">
              <span class="label">Tournaments & Events</span>
              <div class="radio__container">
                <Field
                  checked={this.state.tournaments_and_events}
                  id="events-yes"
                  value={true}
                  name={'events'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="events-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {tournaments_and_events: false};
                      });
                    }
                  }
                  checked={!this.state.tournaments_and_events}
                  id="events-no"
                  value={false}
                  name={'events'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="events-no">No</label>
              </div>
            </div>
          </div>

          {/* <div class="row">
            <div class="col is-1-of-2">
              <span class="label">Score Posted</span>
              <div class="radio__container">
                <Field
                  onClick={
                    () => {
                      this.setState((prevState) => {
                        return {score_posted: !prevState.score_posted};
                      });
                    }
                  }
                  checked={this.state.score_posted}
                  id="score_posted-yes"
                  value={true}
                  name={'score_posted'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="score_posted-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState((prevState) => {
                        return {score_posted: !prevState.score_posted};
                      });
                    }
                  }
                  checked={!this.state.score_posted}
                  id="score_posted-no"
                  value={false}
                  name={'score_posted'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="score_posted-no">No</label>
              </div>
            </div>
          </div> */}

          <div class="row">
            <div class="col is-1-of-2">
              <span class="label">Score Edited or Deleted</span>
              <div class="radio__container">
                <Field
                  checked={this.state.score_updated}
                  id="score_updated-yes"
                  value={true}
                  name={'score_updated'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="score_updated-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {score_updated: false};
                      });
                    }
                  }
                  checked={!this.state.score_updated}
                  id="score_updated-no"
                  value={false}
                  name={'score_updated'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="score_updated-no">No</label>
              </div>
            </div>

            <div class="col is-2-of-2">
              <span class="label">Member Benefits & Promotions</span>
              <div class="radio__container">
                <Field
                  checked={this.state.member_benefits_and_promotions}
                  id="member-yes"
                  value={true}
                  name={'member'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="member-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {member_benefits_and_promotions: false};
                      });
                    }
                  }
                  checked={!this.state.member_benefits_and_promotions}
                  id="member-no"
                  value={false}
                  name={'member'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="member-no">No</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col is-1-of-2">
              <span class="label">Profile Update</span>
              <div class="radio__container">
                <Field
                  checked={this.state.update_profile}
                  id="profile-yes"
                  value={true}
                  name={'profile'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="profile-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {update_profile: false};
                      });
                    }
                  }
                  checked={!this.state.update_profile}
                  id="profile-no"
                  value={false}
                  name={'profile'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="profile-no">No</label>
              </div>
            </div>

            <div class="col is-2-of-2">
              <span class="label">General Updates</span>
              <div class="radio__container">
                <Field
                  checked={this.state.general_updates}
                  id="general-yes"
                  value={true}
                  name={'general'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="general-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {general_updates: false};
                      });
                    }
                  }
                  checked={!this.state.general_updates}
                  id="general-no"
                  value={false}
                  name={'general'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="general-no">No</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col is-1-of-2">
              <span class="label">Score Sent By Another Golfer</span>
              <div class="radio__container">
                <Field
                  checked={this.state.draft_scores}
                  id="draft-yes"
                  value={true}
                  name={'draft'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="draft-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {draft_scores: false};
                      });
                    }
                  }
                  checked={!this.state.draft_scores}
                  id="draft-no"
                  value={false}
                  name={'draft'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
							  />
							  <label htmlFor="draft-no">No</label>
              </div>
            </div>

            <div className="col is-2-of-2">
              <span className="label">Challenges</span>
              <div className="radio__container">
                <Field
                  checked={this.state.challenges}
                  id="challenges-yes"
                  value={true}
                  name={'challenges'}
                  component={'input'}
                  className="simple__radio full-opacity"
                  type="radio"
                  disabled
                />
                <label htmlFor="challenges-yes">Yes</label>

                <Field
                  onClick={
                    () => {
                      this.setState(() => {
                        return {challenges: false};
                      });
                    }
                  }
                  checked={!this.state.challenges}
                  id="challenges-no"
                  value={false}
                  name={'challenges'}
                  component={'input'}
                  className="simple__radio"
                  type="radio"
                />
                <label htmlFor="challenges-no">No</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col is-1-of-2">
              <button type="button" class="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
            </div>
            <div class="col is-1-of-2">
              <button disabled={pristine || submitting || invalid} type={'submit'} ref={'submit'} class="btn fill blue">Save</button>
            </div>
          </div>
        </form>
        
      </Fragment>
    )
  }
}

EditNotificationsForm = reduxForm({
	form: 'editNotificationsForm',
	destroyOnUnmount: true
})(EditNotificationsForm);

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(null, mapDispatchToProps)(withKeyPress(EditNotificationsForm));