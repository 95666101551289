import React, { Component, Fragment } from "react";
import ChallengeTableFilter from "./ChallengeTableFilter";
import GhinTable from "../../../shared/GhinTable";
import { connect } from "react-redux";
import {NavLink} from 'react-router-dom';
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import ChallengesService from "../../../../services/api/challenges";

class ChallengeTable extends Component {

constructor() {
  super();
  
		this.state = {
			challenges: [],
			tableFilterIsHidden: false,
			loading: false,
			per_page: 25,
			page: 0,
			filtered: [],
			sorted: [{ id: "created_at", desc: true }],
		};
	}

	toggleTableFilter() {
		this.setState({
			tableFilterIsHidden: !this.state.tableFilterIsHidden,
		});
	}

	updateFilter(filtered) {
		this.setState(
			{
				filtered,
				page: 0,
			},
			() => {
				this.loadChallenges();
			}
		);
	}

	localDateTime(datetime) {
		datetime = new Date(datetime);
		let hours = datetime.getHours();
		datetime.setUTCHours(hours);
		return datetime;
	}

	loadChallenges() {
		var params = this.state.filtered;
		params['sorting_criteria'] = this.state.sorted[0].id;
		params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		params['per_page'] = this.state.per_page;
		params['page'] = this.state.page + 1;
		params['course_ids'] = this.props.homeCourses;
		this.setState({ loading: true });
		ChallengesService.getChallenges(this.props.associationId, params)
		.then((res) => {
			this.setState({
				challenges: res.challenges,
				total: res.total,
				pages: res.pages,
				loading: false,
			});
		})
		.catch((error) => {
			console.error(error);
			this.setState({ loading: false });
		});
	}

	componentDidMount() {
		this.refreshTable();
	}

	componentDidUpdate() {
		this.refreshTable();
	}

	refreshTable() {
		if (this.props.tableNeedsRefresh === true) {
			this.loadChallenges();
			this.props.setTableRefresh(false);
		}
	}

	render() {
		const canShow = this.props.canEditAssociation;
		let su = this.props.user_type === "super-admin"

		const columns = [
			{
				Header: '#',
				accessor: 'id',
				colClassName: 'col_id',
				show: false
			},
			{
				Header: "Name",
				accessor: "name",
				thClassName: "is-9-of-9",
        Cell: props =>
          <Fragment>
            { !su ? (
              <NavLink to={`./challenges/${props.row.id}`}>{props.row.name}</NavLink>
            ) : props.row.name }
          </Fragment>,
				sortable: true,
			},
			{
				Header: "Course",
				accessor: "course_name",
				thClassName: "is-7-of-9",
				sortable: true,
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				thClassName: "is-4-of-10",
				sortable: true,
			},
			{
				Header: "End Date",
				accessor: "end_date",
				thClassName: "is-4-of-10",
				sortable: false,
			},
			{
				Header: "Golfers",
				accessor: "golfers",
				thClassName: "is-2-of-7",
				sortable: true,
			},
			{
				Header: "Status",
				accessor: "status",
				thClassName: "is-3-of-9",
				sortable: false,
			},
			{
				Header: "Shareable Link",
				accessor: "link",
				thClassName: "is-4-of-9",
				sortable: false,
				Cell: props => (
					props.row.status === 'deleted' ||
						<span className="challenge-invite-link"
							onClick={() => {
								navigator.clipboard.writeText(props.value);
								this.props.addAlert({
									type: 'success',
									message: `Join Link copied to clipboard`
								});
							}}>
							Copy Invite Link
						</span>
				)
			},
		];

		return (
			<Fragment>
				<ChallengeTableFilter
					updateFiltered={this.updateFilter.bind(this)}
					hideFilters={this.state.tableFilterIsHidden}
				/>
				<div className="pre__table">
					<div className="row">

						<div className="col auto push-right">
							<div className="row">
							</div>
						</div>
					</div>
				</div>
				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					pageSize={this.state.per_page}
					onPageChange={(pageIndex) =>
						this.setState({ page: pageIndex }, () =>
							this.loadChallenges()
						)
					}
					onPageSizeChange={(pageSize, pageIndex) =>
						this.setState(
							{
								per_page: pageSize,
								page: pageIndex,
							},
							() => this.loadChallenges()
						)
					}
					data={this.state.challenges}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState(
							{
								sorted: newSorted,
								page: 0,
							},
							() => {
								this.loadChallenges();
							}
						);
					}}
					manual
					hideSelect
				/>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		access: state.user.access,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeTable);
