import { Field } from 'redux-form';
import React, { useState } from 'react';

export const PenaltyType = ({state, handlerFunction}) => {
  const [penaltyType, setPenaltyType] = useState(state.penaltyType);

  return(
    <div className='col  is-1-of-6'>
      <label htmlFor='date'>Penalty Type</label>
      <div className='custom-radio__container'>
        <Field
          onClick={
            () => {
              handlerFunction('high')
              setPenaltyType('high')
            }
          }
          checked={penaltyType === 'high'}
          id='high'
          name={'high'}
          component={'input'}
          type='radio'
        />
        <label htmlFor='high'>High</label>

        <Field
          onClick={
            () => {
              handlerFunction('low')
              setPenaltyType('low')
            }
          }
          checked={penaltyType === 'low'}
          id='low'
          name={'low'}
          component={'input'}
          type='radio'
        />
        <label htmlFor='low'>Low</label>
      </div>
    </div>
  )
}

export const Holes = ({state, handlerFunction}) => {
  const [nrHoles, setNrHoles] = useState(state.nrHoles);

  return(
    <div className='col  is-1-of-6'>
      <label htmlFor='date'>Holes</label>
      <div className='custom-radio__container'>
        <Field
          onClick={
            () => {
              handlerFunction('18')
              setNrHoles('18')
            }
          }
          checked={nrHoles === '18'}
          id='18'
          name={'18'}
          component={'input'}
          type='radio'
        />
        <label htmlFor='18'>18</label>
        <Field
          onClick={
            () => {
              handlerFunction('9')
              setNrHoles('9')
            }
          }
          checked={nrHoles === '9'}
          id='9'
          name={'9'}
          component={'input'}
          type='radio'
        />
        <label htmlFor='9'>9</label>
      </div>
    </div>
  )
}
