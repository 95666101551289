import {Fragment, useState, useEffect} from 'react';
import GhinFilterSelect from "../../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css';

const initial_filters = {
  page_name: '',
  setup_status: '',
  is_enabled: '',
};

export default function TableFilter(props) {
  const [filters, setFilters] = useState(initial_filters);

  useEffect(() => {
    let timer = setTimeout(() => {
      props.updateFilters(
        {
          ...filters,
          setup_status: filters.setup_status.value,
          is_enabled: filters.is_enabled.value
        }
      );
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Fragment>
      <div className="filters">
        <div className="row">
          <div className="filters__inputs col">
            <div className="row">
              <div className="col is-1-of-5">
                <label htmlFor="page_name">Page Name</label>
                <input type="text" autoComplete="off" value={filters.page_name} name="name" id="name" onChange={(e) => {
                  setFilters({...filters, page_name: e.target.value});
                }}/>
              </div>
              <div className="col is-1-of-5">
                <GhinFilterSelect
                  options={['Complete', 'Incomplete']}
                  value={filters.setup_status}
                  singleChoice={true}
                  onChange={value => setFilters({...filters, setup_status: value})}
                  label={'Setup Status'}
                />
              </div>
              <div className="col is-1-of-5">
                <GhinFilterSelect
                  options={[{"label": "Yes", "value": "true"}, {"label": "No", "value": "false"}]}
                  value={filters.is_enabled}
                  singleChoice={true}
                  onChange={value => setFilters({...filters, is_enabled: value})}
                  label={'Active?'}
                />
              </div>
              <div className="col is-1-of-7 jc-fe push-right">
                <button className="btn fill gray" onClick={() => setFilters(initial_filters)}>Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
