import { Fragment } from 'react';

export default function PaymentDetailModal(props) {
  
  const transaction = props.transaction;
  if (transaction === null)
    return null;

  const getFeeValue = (paymentDetails) => {
    let value = 0;
    
    const transaction_fee = paymentDetails.transaction_fee;
    
    if (transaction_fee)
      value = Number(transaction_fee.amount) || 0
    else 
      return '-';

    return `$${value.toFixed(2)}`;
  };

  const getRefundFeeValue = (refundDetails) => {
    let value = 0;

    const transaction_fee = paymentDetails.transaction_fee;

    if (refundDetails.include_fee)
      value = transaction_fee ? Number(paymentDetails.transaction_fee.amount) : 0
    else
      return '-';

    return `$${value.toFixed(2)}`;
  };

  const getMembershipValue = (paymentDetails) => {
    let value = 0;
    let discountedValue = 0;
    let proratedValue = 0;

    const prorating_data = paymentDetails.prorating_data;
    const club_membership_fee = paymentDetails.membership_data;
    const association_membership_fee = paymentDetails.golf_association_rate;

    if (club_membership_fee) {
      if (club_membership_fee.discounted_amount !== undefined) {
        value += Number(club_membership_fee.amount);
        discountedValue += Number(club_membership_fee.discounted_amount);
        proratedValue += Number(club_membership_fee.amount);
      }
      else if (prorating_data && prorating_data.hasOwnProperty('club_fee')) {
        value += Number(club_membership_fee.amount);
        discountedValue += Number(club_membership_fee.amount);
        proratedValue += Number(prorating_data.club_fee.prorated_amount);
      }
      else {
        value += Number(club_membership_fee.amount);
        discountedValue += Number(club_membership_fee.amount);
        proratedValue += Number(club_membership_fee.amount);
      }
    }
    
    if (association_membership_fee) {
      if (association_membership_fee.discounted_amount !== undefined) {
        value += Number(association_membership_fee.amount);
        discountedValue += Number(association_membership_fee.discounted_amount);
        proratedValue += Number(association_membership_fee.amount);
      }
      else if (prorating_data && prorating_data.hasOwnProperty('association_fee')) {
        value += Number(association_membership_fee.amount);
        discountedValue += Number(association_membership_fee.amount);
        proratedValue += Number(prorating_data.association_fee.prorated_amount);
      }
      else {
        value += Number(association_membership_fee.amount);
        discountedValue += Number(association_membership_fee.amount);
        proratedValue += Number(association_membership_fee.amount);
      }
    }

    return [value === 0 ? '-' : `$${value.toFixed(2)}`, discountedValue === 0 ? '-' : `$${discountedValue.toFixed(2)}`, proratedValue === 0 ? '-' : `$${proratedValue.toFixed(2)}`]
  };

  const getRefundMembershipValue = (refundDetails) => {
    let value = 0;

    const club_membership_fee = refundDetails.club_fee;
    const association_membership_fee = refundDetails.association_fee;

    if (club_membership_fee)
      value += Number(club_membership_fee);

    if (association_membership_fee) {
      value += Number(association_membership_fee);
    }

    return `$${value.toFixed(2)}`;
  };

  const totalAmount = (paymentDetails) => {  
    let total = 0;
    
    const club_membership_fee = paymentDetails.membership_data;
    const association_membership_fee = paymentDetails.golf_association_rate;
    const transaction_fee = paymentDetails.transaction_fee;
    const addons = paymentDetails.addon_charges;
    
    if (club_membership_fee)
      total += Number(club_membership_fee.amount);
    
    if (association_membership_fee)
      total += Number(association_membership_fee.amount);
    
    if (transaction_fee)
      total += Number(transaction_fee.amount) || 0
      
    if (addons)
      total += addons.map((a) => Number(a.amount)).reduce((a, b) => a + b, 0);
    
    return total;
  }
  
  const paymentDetails = transaction.payment_details;
  const refundDetails = transaction.refund_details;
  const failedStatus = transaction.status === 'failed';

  let membershipFee = 0;
  let discountedMembershipFee = 0;
  let proratedMembershipFee = 0;
  let transactionFee = 0;
  let isDiscountedFee = false;
  let isProratedFee = false;
  let promotionalCodes = [];

  if (transaction.transaction_type === 'refund') {
    membershipFee = getRefundMembershipValue(refundDetails);
    transactionFee = getRefundFeeValue(refundDetails);
  }
  else {
    const data = getMembershipValue(paymentDetails);
    membershipFee = data[0];
    discountedMembershipFee = data[1];
    proratedMembershipFee = data[2];
    if (membershipFee !== discountedMembershipFee) {
      isDiscountedFee = true;
      promotionalCodes = paymentDetails.promotional_codes;
    }
    if (membershipFee !== proratedMembershipFee) {
      isProratedFee = true;
    }
    transactionFee = getFeeValue(paymentDetails);
  }

  const displayTransactionFee = transaction.transaction_type !== 'refund' ||
    (paymentDetails.transaction_fee && (totalAmount(paymentDetails).toFixed(2) === Number(transaction.amount).toFixed(2)))
    || (transaction.transaction_type === 'refund' && refundDetails.include_fee === true)

  return (
    <Fragment>

      <div className="modal__container semi-narrow">
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">attach_money</i></div>
          <h2 className="modal__title">Payment Detail</h2>
          <button className="modal__close" onClick={() => {props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
        </div>

        <div className="modal__body">
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Golfer Name:</strong></div>
            <div className="col is-1-of-2">{`${transaction.full_name}`}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>GHIN Number:</strong></div>
            <div className="col is-1-of-2">{transaction.golfer_id}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Transaction Number:</strong></div>
            <div className="col is-1-of-2">{transaction.transaction_id}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Golf Club Name:</strong></div>
            <div className="col is-1-of-2">{transaction.club_name}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Membership Fee Name:</strong></div>
            <div className="col is-1-of-2">{paymentDetails.membership_data.name}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Membership Fee:</strong></div>
            <div className="col is-1-of-2">
              {failedStatus ? '-' :
                (
                  isDiscountedFee ?
                    <div className="codes-display">
                      <span style={{color: 'red'}}>{discountedMembershipFee === '-' ? '$0.00' : discountedMembershipFee}</span>
                      <span className="original-fee">Reg <span style={{textDecoration: 'line-through'}}>{membershipFee}</span></span>
                      {
                        promotionalCodes.filter(pc => pc.is_discount).map((pc, index) => {
                          return (
                            <Fragment key={index}>
                              <span key={pc.code} className="discount-code-text">{pc.code} Discount Applied</span>
                            </Fragment>
                          )
                        })
                      }
                    </div>
                  :
                  (
                    isProratedFee ?
                      <div className="codes-display">
                        <span style={{color: 'red'}}>{proratedMembershipFee === '-' ? '$0.00' : proratedMembershipFee}</span>
                        <span className="original-fee">Reg <span style={{textDecoration: 'line-through'}}>{membershipFee}</span></span>
                      </div>
                    :
                      membershipFee
                  )
                )
              }
            </div>
          </div>
          {
            paymentDetails.addon_charges.map(ac => {
              let showAddon = transaction.transaction_type !== 'refund' || refundDetails.addon_charge_ids.includes(ac.id);
              return <div className="row" key={ac.id}>
                <div className="col is-1-of-2 ai-fe"><strong>{ac.name}</strong></div>
                <div className="col is-1-of-2">
                  {showAddon ? `$${Number(ac.amount).toFixed(2)}` : '-'}
                </div>
              </div>
            })
          }  
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Transaction Fee:</strong></div>
            <div className="col is-1-of-2">{failedStatus || !displayTransactionFee ? '-' : transactionFee}</div>
          </div>
          <div className="row">
            <div className="col is-1-of-2 ai-fe"><strong>Total:</strong></div>
            <div className="col is-1-of-2">{failedStatus ? '-' : `$${transaction.amount}`}</div>
          </div>

          <div className="row no-margin">
							<div className="col">
								<button type='submit'  onClick={() => props.closeModal()} className="btn fill gray">Close</button>
							</div>
						</div>
        </div>
      </div>

    </Fragment>
  );
}
