import React, { Component, Fragment } from "react";
import ChallengeTable from "./ChallengeTable";
import Modal from "react-modal";
import "react-picky/dist/picky.css";
import AddChallengeModal from "../../../Modals/AddChallengeModal";
import { withRouter } from 'react-router-dom'; // Import withRouter

class ClubChallenges extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addNewChallengeModalIsOpen: false,
			tableNeedsRefresh: false,
		};

		this.openAddNewChallengeModal = this.openAddNewChallengeModal.bind(
			this
		);
		this.setTableRefresh = this.setTableRefresh.bind(this);
	}

	closeModal() {
		this.setState({
			addNewChallengeModalIsOpen: false,
		});
	}

	openAddNewChallengeModal() {
		this.setState({ addNewChallengeModalIsOpen: true });
	}

	setTableRefresh(value) {
		this.setState({ tableNeedsRefresh: value });
		console.log("REFRESH D . " + value);
	}

	render() {
		let panelTitle = "Challenges";
		return (
			<Fragment>
				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">{panelTitle}</div>
							<div className="panel__body">
								<div className="row">
									<div className="col">
										<ChallengeTable
											canEditAssociation={
												this.props.canEditAssociation
											}
											associationId={
												this.props.associationId
											}
											user={this.props.user}
                      openAddNewChallengeModal={this.openAddNewChallengeModal}
											setTableRefresh={(value) => this.setTableRefresh(value)}
											tableNeedsRefresh={this.state.tableNeedsRefresh}
											userType={this.props.userType}
										></ChallengeTable>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={this.state.addNewChallengeModalIsOpen}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddChallengeModal
						associationId={this.props.associationId}
						user={this.props.user}
						closeModal={() => this.closeModal()}
						setTableRefresh={(value) => this.setTableRefresh(value)}
					/>
				</Modal>
			</Fragment>
		);
	}
}
export default withRouter(ClubChallenges);
