import React, {Component} from 'react';
import {
	Field,
	reduxForm,
	submit,
	initialize,
	destroy as destroyForm,
	formValueSelector,
	change,
	getFormValues
} from "redux-form";
import {SelectInput} from "../../../inputs/Select";
import {InputField} from "../../../inputs/InputField";
import {isNumber} from "../../../inputs/validations";
import {connect} from "react-redux";
import debounce from 'lodash.debounce'
import GolferService from "../../../services/api/golfer";
import { store } from '../../../store';
import {bindActionCreators} from "redux";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import createCancelableRequest from "../../../services/shared/asyncSearchIntrerupt";

class SearchPlayer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			playerEntryMethod: this.props.userType === "club-admin" || this.props.userAccess.club_role === "score_only" || this.props.userAccess.club_role === "score_maintenance" ? [
				{ value: 'golfer_id', label: 'GHIN #' },
				{value: 'local_number', label: 'Local # (Members)'},
				{value: 'name', label: 'Name (Members)'}
			]:[
				{ value: 'golfer_id', label: 'GHIN #' }
			],
			dateSetup: [
				{value: 'specific', label: 'Specific Date'},
				{value: 'ascending', label: 'Ascending Date'},
				{value: 'descending', label: 'Descending Date'},
			],
			golfers: [],
			selectedEntryMethod: null
		};

		this.onChange = this.onChange.bind(this);
		this.debounceOnChange = debounce(this.onChange, 1000);
		this.searchName = this.searchName.bind(this);
		this.searchLocal = this.searchLocal.bind(this);
		this.searchDebounceLocal = debounce(this.searchLocal, 1000);
	}

	onChange() {

		if (this.props.valid && this.props.playerEntry) {
			this.props.dispatch(submit('searchPlayerForm'));
		} else {
			this.props.touch('player_entry');
			this.props.change('PlayerName', null);
			this.props.change('Display', null);
			this.props.destroyForm('playerEntryForm');
		}

	}

	searchLocal (searchTerm){
		let values = this.props.formValues;
		delete  values.PlayerName;
		delete values.searchable_player_entry;
		this.setState({golfers: []});
		delete  values.Display;
		this.props.dispatch(initialize('searchPlayerForm', values));
		this.props.dispatch(destroyForm('playerEntryForm'));

		if (searchTerm.target.value) {
			let values = this.props.formValues;
			let cancelableRequest = createCancelableRequest();
			return cancelableRequest(`/search_golfer.json?${this.state.selectedEntryMethod}=${searchTerm.target.value}&club_id=${this.props.userAccess.default_club_id}`)
				.then(response => {
					if (response && response.data && response.data.golfers) {
						let golfer = response.data.golfers.find(g =>
							g.clubs.some(club => club.status === "Active")
						) || response.data.golfers[0];
						values.PlayerName = golfer.first_name + ' ' + golfer.last_name;
						values.Display = golfer.handicap_index;
						if(golfer.clubs.filter(club => club.status === "Active").length > 0) {
							delete values.Error;
							this.props.dispatch(initialize('searchPlayerForm', values));
							this.props.dispatch(initialize('playerEntryForm', {score_type: {value: 'H', label: 'Home'}, date_setup: values.date_setup, number_of_holes: {value: "18", label: '18 (9 + 9)'}, golfer_id: golfer.id, gender: golfer.gender, firstName: golfer.first_name, lastName: golfer.last_name, played_at: new Date()}))
						} else {
							if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
								values.Error = `This golfer is inactive.`;
							else
								values.Error = `This is an Archived golfer.`;
							this.props.dispatch(initialize('searchPlayerForm', values));
							this.props.dispatch(destroyForm('playerEntryForm'))
						}
					} else {
						values.Error ='Golfer Not Found';
						delete  values.PlayerName;
						delete  values.Display;
						this.props.dispatch(initialize('searchPlayerForm', values));
						this.props.dispatch(destroyForm('playerEntryForm'))
					}
				})
				.catch(err => {
					values.Error = 'Golfer Not Found';
					this.props.dispatch(initialize('searchPlayerForm', values));
					this.props.dispatch(destroyForm('playerEntryForm'));
					console.error(err)
				})
		} else {
			values.Error ='';
			this.props.dispatch(initialize('searchPlayerForm', values));
			this.props.dispatch(destroyForm('playerEntryForm'))
		}
	}

	searchName (searchTerm, callback){
		let values = this.props.formValues;
		delete  values.PlayerName;
		delete values.searchable_player_entry;
		this.setState({golfers: []});
		delete  values.Display;
		this.props.dispatch(initialize('searchPlayerForm', values));
		this.props.dispatch(destroyForm('playerEntryForm'));

		if (searchTerm) {
			let cancelableRequest = createCancelableRequest();
			return cancelableRequest(`/search_golfer.json?${this.state.selectedEntryMethod}=${searchTerm}&club_id=${this.props.userAccess.default_club_id}`)
				.then(response => {
					if (response && response.data) {
						let options = response.data.golfers.map(g => {return {...g, ...{value: g.id,label:g.first_name + ' ' + g.last_name}}}).sort((a, b) => (a.label > b.label) ? 1 : -1);
						this.setState({golfers: options});
						callback(options)
					} else {
						callback([])
					}
				})
				.catch(err => {
					values.Error = 'Golfer Not Found';
					this.props.dispatch(initialize('searchPlayerForm', values));
					this.props.dispatch(destroyForm('playerEntryForm'));
					console.error(err)
				})
		} return new Promise(resolve => {
			resolve([])
		})
	}

	selectGolfer (golfer) {
		let values = this.props.formValues;
		if (golfer) {
			values.PlayerName = golfer.first_name + ' ' + golfer.last_name;
			values.Display = golfer.handicap_index;
			if (golfer.clubs.filter(club => club.status === "Active").length > 0) {
				delete values.Error;
				this.props.dispatch(initialize('searchPlayerForm', values));
				this.props.dispatch(initialize('playerEntryForm', {score_type: {value: 'H', label: 'Home'}, date_setup: values.date_setup, number_of_holes: {value: "18", label: '18 (9 + 9)'}, golfer_id: golfer.id, gender: golfer.gender, firstName: golfer.first_name, lastName: golfer.last_name, played_at: new Date()}))
			} else {
				if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
					values.Error = `This golfer is inactive.`;
				else
					values.Error = `This is an Archived golfer.`;
				this.props.dispatch(initialize('searchPlayerForm', values));
				this.props.dispatch(destroyForm('playerEntryForm'))
			}
		} else {
			values.Error = '';
			delete  values.PlayerName;
			delete  values.Display;
			this.props.dispatch(initialize('searchPlayerForm', values));
			this.props.dispatch(destroyForm('playerEntryForm'))
		}
	}

	componentDidMount() {
		this.props.change("selectedEntryMethod", { value: 'golfer_id', label: 'GHIN #' });
		this.setState({
			selectedEntryMethod: 'golfer_id'
		});
		if (this.props.super_user) {
			this.setState({
				playerEntryMethod: [
					{ value: 'golfer_id', label: 'GHIN #' }
				]
			})
		}
	}

	render() {
		let {handleSubmit} = this.props;
		return (
			<form ref={'form'} onSubmit={handleSubmit}>
				<div className="filters columns">

					<div className="row">
						<div className="col is-1-of-7">
							<label>Date Setup</label>
							<Field onChange={(event, newValue, prevValue) => {
								this.props.dispatch(change('playerEntryForm', 'date_setup', newValue))
							}} name={'date_setup'} component={SelectInput} className="react-select-container" classNamePrefix="react-select" placeholder="Select ... " options={this.state.dateSetup} isSearchable={false} />
						</div>
						<div className="col is-1-of-6">
							<label>Player Entry Method</label>
							<Field component={SelectInput}
								   onChange={(entry) => {
								   	this.setState({
										selectedEntryMethod: entry.value, golfers:[]});
								   		let values = this.props.formValues;
									   values.Error = '';
									   delete  values.PlayerName;
									   delete  values.Display;
									   delete values.searchable_player_entry;
									   delete values.player_entry;
									   this.props.dispatch(initialize('searchPlayerForm', values));
									   this.props.dispatch(destroyForm('playerEntryForm'))
								   }}
								   name={'selectedEntryMethod'}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={this.state.playerEntryMethod}
								   isSearchable={false} />
						</div>
						{this.state.selectedEntryMethod &&
						<div className="col is-1-of-4">
							<label htmlFor="player_entry">Enter {this.state.playerEntryMethod.filter(pe => pe.value === this.state.selectedEntryMethod)[0].label}</label>
							{this.state.selectedEntryMethod === "golfer_id" && <Field onChange={this.debounceOnChange}
								   component={InputField}
								   validate={this.state.selectedEntryMethod === 'golfer_id' && [isNumber]}
								   parse={(value) => {if(this.state.selectedEntryMethod === 'golfer_id'){if(isNaN(parseInt(value))){return ''} else {return parseInt(value)}} else {return value }}}
								   type="text"
								   autoComplete="off"
								   id="player_entry"
								   name="player_entry"
								   placeholder={`Enter ${this.state.playerEntryMethod.filter(pe => pe.value === this.state.selectedEntryMethod)[0].label}`} />}
							{this.state.selectedEntryMethod === "local_number" &&
								<Field onChange={this.searchDebounceLocal}
									   component={InputField}
									   type="text"
									   autoComplete="off"
									   id="player_entry"
									   name="player_entry"
									   placeholder={`Enter ${this.state.playerEntryMethod.filter(pe => pe.value === this.state.selectedEntryMethod)[0].label}`} />
							}
							{this.state.selectedEntryMethod === "name" &&
								<Field component={AsyncSelectInput}
									   type="text"
									   dontCacheOptions={true}
									   hideErrorMessages={true}
									   onChange={(value)=>{this.selectGolfer(value)}}
									   searchable={true}
									   defaultOptions={this.state.golfers}
									   loadOptions={debounce(this.searchName, 1000)}
									   autoComplete="off"
									   className="react-select-container"
									   classNamePrefix="react-select"
									   id="searchable_player_entry"
									   name="searchable_player_entry"
									   placeholder={` `} />
							}

						</div>
						}
						<div className="col is-1-of-6 jc-fe">
							<Field name={"PlayerName"} component={(input) => {
								return <div className="pull-data__golfer-name">{input.input.value}</div>
							}}/>
							<Field name={"Display"} component={(input) => {
								return input.input.value && <div className="pull-data__golfer-handicap-index">Handicap Index: <span className="cardinal">{input.input.value}</span></div>
							}}/>
							<Field name={"Error"} component={(input) => {
								return <div className="pull-data__golfer-name"><span className="cardinal">{input.input.value}</span></div>
							}}/>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

SearchPlayer = reduxForm({
	form: 'searchPlayerForm',
	destroyOnUnmount: true,
	initialValues: {selectedEntryMethod: { value: 'golfer_id', label: 'GHIN #' }, date_setup: {value: 'specific', label: 'Specific Date'}},
	onSubmit: (values, dispatch) => {
		var super_admin = store.getState().user.access.super_user;
		GolferService.getGolfer(values.selectedEntryMethod.value, values.player_entry)
			.then(response => {
				if (response && response.golfer && (super_admin || !response.golfer.is_trial)) {
					let golfer = response.golfer;
					values.PlayerName = golfer.first_name + ' ' + golfer.last_name;
					values.Display = golfer.handicap_index;
					delete values.Error;
					if (golfer.clubs.filter(club => club.status === "Active").length > 0) {
						dispatch(initialize('searchPlayerForm', values));
						dispatch(initialize('playerEntryForm', {
							score_type: {
								value: 'H',
								label: 'Home'
							},
							date_setup: values.date_setup,
							number_of_holes: {value: "18", label: '18 (9 + 9)'},
							golfer_id: golfer.id,
							gender: golfer.gender,
							firstName: golfer.first_name,
							lastName: golfer.last_name,
							played_at: new Date()
						}))
					} else {
						if (golfer.clubs.filter(club => club.status === "Inactive").length > 0)
							values.Error = `This golfer is inactive.`;
						else
							values.Error = `This is an Archived golfer.`;
						dispatch(initialize('searchPlayerForm', values));
						dispatch(destroyForm('playerEntryForm'))
					}
				} else {
					values.Error ='Golfer Not Found';
					delete  values.PlayerName;
					delete  values.Display;
					dispatch(initialize('searchPlayerForm', values));
					dispatch(destroyForm('playerEntryForm'))
				}
			})
			.catch(err => {
				values.Error = 'Golfer Not Found';
				delete  values.PlayerName;
				delete  values.Display;
				dispatch(initialize('searchPlayerForm', values));
				dispatch(destroyForm('playerEntryForm'))
				console.error(err)
			})


	}
})(SearchPlayer);

function mapStateToProps(state) {

	const selector = formValueSelector('searchPlayerForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		userAccess: state.user.access,
		super_admin: state.user.access.super_user,
		PlayerName: selector(state, 'PlayerName'),
		playerEntry: selector(state, 'player_entry'),
		formValues: getFormValues('searchPlayerForm')(state),
		userType: state.user.access.user_type
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({destroyForm}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlayer);
