import React, {Component, Fragment} from 'react';
import Breadcrumbs  from 'react-router-dynamic-breadcrumbs';

/**
*
*   DOCUMENTATION
*   https://github.com/roya3000/react-router-dynamic-breadcrumbs
*
**/



export default class BreadCrumbsNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

	render() {
        const routes = {
            '/': null,
            '/manage/association-list' : 'Association List',
            '/users': 'Users',
            '/rapid-score-entry': 'Rapid Score Entry',
            '/users/:id/info': 'User Info',
            '/users/:id/posts/:p_id': 'Post :p_id by :id',
            '/manage/association': () => null,
            '/manage/associations': () => null,
            '/manage/associations/list': () => null,
            '/manage/association/:id': () => this.props.canSeeAssociationName ? this.props.association.name : null,
            '/manage/guardian/:id': () => this.props.guardian ? this.props.guardian : null,
            '/manage/guardian/:id/account/minor-management': 'Guardianship',
            '/manage/association/:id/list': () => null,
            '/manage/association/:id/account/newsletter_setup': 'Newsletter-Setup',
            '/manage/association/:association_id/ghin-connect': 'GHIN Connect',
            '/manage/association/:association_id/club/:id': () => this.props.canSeeAssociationName || this.props.canSeeClubName ? this.props.club.club_name : null,
            '/manage/association/:association_id/club': null,
            '/manage/association/:association_id/club/:id/list': null,
            '/manage/association/:association_id/club/:id/golfer': null,
            ["/manage/association/:association_id/club/:id/golfer/" +  this.props.golfer_encoded_id]: ()=> this.props.golfer,
            '/manage/association/:association_id/club/:club_id/golfer/:golfer_id/account/minor-management': 'Guardianship',
            '/manage/association/:association_id/golfer-products/challenges': 'Challenges',
            '/manage/association/:association_id/golfer-products/challenges/:id': () => this.props.challenge_name,
            '/manage/association/:association_id/club/:id/golfer-products/challenges': 'Challenges',
            '/manage/association/:association_id/club/:id/golfer-products/challenges/:id': () => this.props.challenge_name,
            '/reports/my-reports': 'My Reports',
            '/reports/scheduled-reports': 'Scheduled Reports',
            '/reports/email-deliveries': 'Email Deliveries',
        };

        return (
            <Fragment>
                <div className="breadcrumbs__nav">
                    <div className="container">
						<Breadcrumbs mappedRoutes={routes}
									 WrapperComponent={(props) => <ol className="breadcrumb" >{props.children}</ol>}
									 ActiveLinkComponent={(props) => <li className={`breadcrumb-item ${props.children === "Newsletter-Setup" ? 'revision' : ''}`} >{props.children}</li>}
									 LinkComponent={(props) => <li className={`breadcrumb-item ${props.children === "Newsletter-Setup" ? 'revision' : ''}`}>{props.children}</li>}
						/>
                    </div>
                </div>
            </Fragment>
		);
	}
}
