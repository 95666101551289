import React, {Component, Fragment} from 'react';
import {Field, formValueSelector, reduxForm, destroy as externalDestroy, change as changeWithForm} from "redux-form";
import moment from "moment";
import {DatePickerInput} from "../../../inputs/DatePicker";
import {addAlert} from "../../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {SelectInput} from "../../../inputs/Select";
import {InputField} from "../../../inputs/InputField";
import ManualCourseModal from "../../Modals/ManualCourseModal";
import Modal from "react-modal";
import ClubService from "../../../services/api/club";
import {withRouter} from "react-router-dom";
import flatten from 'lodash.flatten'
import CourseRatingSystemService from "../../../services/api/courseRatingSystem";
import ScoreService from "../../../services/api/score";
import debounce from "debounce-promise";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import {requiredSelect} from "../../../inputs/validations";
import {orderTees} from "../../../services/shared/teesFilter";
import {Holes, PenaltyType} from "./TotalComponents";

class TotalForm extends Component{

	constructor(props){
		super(props);
		this.state = {
			scoreType: 'home',
			nrHoles: '18',
			method: 'home-course',
			penaltyMethod: 'auto',
			penaltyType: 'low',
			manualCourseModal: false,
			manualRatingModal: false,
			courses: [],
      	home_courses: [],
      	auto_score_type: false,
			teeSetsOptions: [],
			golfer: null,
			savedEntry: null,
			course: {courseName:'test course'},
			isManual: false,
			errors: [],
			clearManualForm: false,
      	outdatedScoreModalOpen: false,
			modalReviewBodyQuestion: '',
		};

		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.getHomeCourses = this.getHomeCourses.bind(this);
		this.setTees = this.setTees.bind(this);
		this.confirmScore = this.confirmScore.bind(this);
		this.setManualValues = this.setManualValues.bind(this);

    this.penaltyTypeSetState = this.penaltyTypeSetState.bind(this);
    this.holesSetState = this.holesSetState.bind(this);
	}

	onSubmit(values) {
		let formData = JSON.parse(JSON.stringify(values));
		let data = {};
		let scoreType = this.state.scoreType === 'home' ? 'H' : (this.state.scoreType === 'away' ? 'A' : 'T');

    if(this.props?.golfer?.use_scaling && this.state.scoreType === 'penalty' && this.state.penaltyMethod === "auto")
    {
      this.setState({nrHoles: '18'});
      this.props.change('nrHoles', '1S8');
    }

		if (this.state.method === "home-course" && !(this.state.scoreType === 'penalty' && this.state.penaltyMethod === "auto")) {
			data = {
				golfer_id: this.props.golfer.id,
				course_id: formData.course_name.courseId,
				tee_set_id: formData.course_name.teeSetID,
				tee_set_side: parseInt(this.state.nrHoles) === 9 ? formData.course_name.tee_set_side : 'All18',
				played_at: moment(formData.date).format("YYYY-MM-DD"),
				score_type: scoreType,
				number_of_holes: parseInt(this.state.nrHoles),
				adjusted_gross_score: parseInt(formData.total || 0),
				gender: this.props.golfer.gender
			};
			if(this.state.scoreType === 'penalty') {
				data.penalty = true;
				delete data.score_type;
				data.penalty_method = 'manual';
			}
		} else if (this.state.scoreType === 'penalty' && this.state.penaltyMethod === "auto") {
			data = {
				golfer_id: this.props.golfer.id,
				played_at: moment(formData.date).format("YYYY-MM-DD"),
				number_of_holes: parseInt(this.state.nrHoles),
				gender: this.props.golfer.gender,
				penalty: true,
				penalty_method: this.state.penaltyMethod,
				penalty_type: this.state.penaltyType
			};
		} else {
			if (this.state.isManual) {
				data = {
					is_manual: true,
					golfer_id: this.props.golfer.id,
					course_name: formData.course_name.label,
					course_id: formData.course_id? formData.course_id.value : null,
					tee_name: `Manual / ${formData.tee_set_id.label}`,
					course_rating: formData.tee_set_id.label.split(" /")[0],
					slope_rating: formData.tee_set_id.label.split("/ ")[1],
					tee_set_side: parseInt(this.state.nrHoles) === 9 ? formData.tee_set_id.tee_set_side : 'All18',
					played_at: moment(formData.date).format("YYYY-MM-DD"),
					score_type: scoreType,
					number_of_holes: parseInt(this.state.nrHoles),
					adjusted_gross_score: parseInt(formData.total || 0),
					gender: this.props.golfer.gender
				};
			} else {
				data = {
					golfer_id: this.props.golfer.id,
					course_id: formData.course_name.value,
					tee_set_id: formData.tee_set_id.value,
					tee_set_side: parseInt(this.state.nrHoles) === 9 ? formData.tee_set_id.tee_set_side : 'All18',
					played_at: moment(formData.date).format("YYYY-MM-DD"),
					score_type: scoreType,
					number_of_holes: parseInt(this.state.nrHoles),
					adjusted_gross_score: parseInt(formData.total || 0),
					gender: this.props.golfer.gender
				};
			}

			if(this.state.scoreType === 'penalty') {
				data.penalty = true;
				delete data.score_type;
				data.penalty_method = 'manual';
			}

		}

		if(parseInt(values.front9) && parseInt(values.back9)) {
			data.front9_adjusted = values.front9;
			data.back9_adjusted = values.back9;
			delete data.adjusted_gross_score;
			if(this.state.isManual) {
				data.front9_course_rating = values.manual_values['front-cr'];
				data.front9_slope_rating = values.manual_values['front-slope'];
				data.back9_course_rating = values.manual_values['back-cr'];
				data.back9_slope_rating = values.manual_values['back-slope'];
				delete data.slope_rating;
				delete data.course_rating;
			}
			return ScoreService.post18h9and9(data)
				.then(response => {
					if (response.score.status === 'UnderReview') {
						this.setState({
							savedEntry: response.score,
							errors: [],
							confirmationModalOpen: true,
							modalReviewBodyQuestion: response.score.validation_message_display,
						});
					} else {
						this.props.change('front9', null);
						this.props.change('back9', null);
						this.props.change('total', null);
						this.props.change('course_id', null);
						this.props.change('course_name', null);
						this.props.change('tee_set_id', null);
						this.props.untouch('tee_set_id');
						this.props.untouch('front9');
						this.props.untouch('back9');
						this.props.untouch('total');

						this.props.externalDestroy('manualCourseForm');

						this.setState({
							isManual: false,
							errors: []
						});
						this.props.addAlert({type:'success', message:'Score has been posted successfully'});
					}
            this.setState({
              outdatedScoreModalOpen: !response.score.is_recent && response.score.status !== 'UnderReview',
            })
				})
				.catch(err => {
					console.error(err);

					let errors =  [];

					if (err.data.errors) {

						Object.keys(err.data.errors).forEach(error => {
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								if (error === 'front9_adjusted' || error ==='back9_adjusted') {
									const errorMrg = `${error.split('_')[0]} score ${err.data.errors[error][0]}`
									errors.push(errorMrg) ;
								} else {
									errors.push(err.data.errors[error][0]);
								}

							}
						});

						this.setState({errors});

					} else {
						this.props.addAlert({type:'error', message:'Score can\'t be posted at this moment. Please try again.'});
					}

				})
		} else {
			return ScoreService.postAdjusted(data)
				.then(response => {
					if (response.score.status === 'UnderReview') {
						this.setState({
							savedEntry: response.score,
							errors: [],
							confirmationModalOpen: true,
							modalReviewBodyQuestion: response.score.validation_message_display,
						});
					} else {
						this.props.change('front9', null);
						this.props.change('back9', null);
						this.props.change('total', null);
						this.props.change('course_id', null);
						this.props.change('course_name', null);
						this.props.change('tee_set_id', null);
						this.props.untouch('tee_set_id');
						this.props.untouch('front9');
						this.props.untouch('back9');
						this.props.untouch('total');

						this.props.externalDestroy('manualCourseForm');

						this.setState({
							isManual: false,
							errors: []
						});
						this.props.addAlert({type:'success', message:'Score has been posted successfully'});
					}
            this.setState({
              outdatedScoreModalOpen: !response.score.is_recent && response.score.status !== 'UnderReview',
            })
				})
				.catch(err => {
					console.error(err);

					let errors =  [];

					if (err.data.errors) {

						Object.keys(err.data.errors).forEach(error => {
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								errors.push(err.data.errors[error][0]) ;
							}
						});

						this.setState({errors});

					} else {
						this.props.addAlert({type:'error', message:'Score can\'t be posted at this moment. Please try again.'});
					}

				})
		}

	}

	confirmScore() {
		ScoreService.confirmScore(this.state.savedEntry.id, this.state.savedEntry.golfer_id)
			.then(response => {
				this.setState({confirmationModalOpen: false, savedEntry: null});
				this.props.untouch('front9');
				this.props.untouch('back9');
				this.props.untouch('total');
				this.props.change('front9', null);
				this.props.change('back9', null);
				this.props.change('total', null);
				this.setState({
					isManual: false
				});
				this.props.addAlert({type:'success', message:'Score has been posted successfully'});
        this.setState({
          outdatedScoreModalOpen: !response.score.is_recent,
        })
			})
			.catch(err => {
				this.setState({confirmationModalOpen: false, savedEntry: null});
			})
	}

	getHomeCourses() {
		this.setState({
			courses: []
		});
		ClubService.getClubHomeCourses(this.props.match.params.association_id, this.props.match.params.id)
			.then(async response => {
				if (response.facilities.length === 0) {
					/**
					 * Disable home courses if there is no home course defined
					 */
					this.setState({
						method: 'course'
					});
					this.props.change('method', 'course')
				} else {
					let courses = await Promise.all(response.facilities.map(async hc => {
						let courses = await Promise.all(hc.home_courses.map(home_course => {
							let courseName = hc.name === home_course.name ? home_course.name : hc.name + ' ' + home_course.name;
							return {
								req: CourseRatingSystemService.getCourseDetails(home_course.course_id),
								courseName: courseName,
								courseId: home_course.course_id
							};
						}).map(async r => {
							let response = await r.req;
							let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.state.golfer.gender).map(ts => {
								let totalRatings = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
								let frontRatings = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
								let backRatings = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
								return {
									label: ts.TeeSetRatingName,
									holes: ts.Holes,
									holesNumber: ts.HolesNumber,
									courseId: r.courseId,
									value: ts.TeeSetRatingId,
									ratingTotal: totalRatings && `${parseFloat(totalRatings.CourseRating).toFixed(1)} / ${totalRatings.SlopeRating}`,
									ratingFront: frontRatings && `${parseFloat(frontRatings.CourseRating).toFixed(1)} / ${frontRatings.SlopeRating}`,
									ratingBack: backRatings && `${parseFloat(backRatings.CourseRating).toFixed(1)} / ${backRatings.SlopeRating}`,
									teeId: ts.TeeSetRatingId,
									ratingTotalValue: totalRatings && parseFloat(totalRatings.CourseRating).toFixed(1),
								}
							});
							orderTees(filteredTS);
							if (this.state.nrHoles === '18') {
								filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
									let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
									return {
										label: `${r.courseName} / ${ts.label} (${ts.ratingTotal} / ${totalPar})`,
										value: ts.value,
										teeSetID: ts.value,
										courseId: ts.courseId
									}
								});
							} else {
								filteredTS = filteredTS.reduce((res, current) => {
									let frontPar = current.holes.reduce((acc, h, index) => {
										if (index < 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let backPar = current.holes.reduce((acc, h, index) => {
										if (index >= 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let front = current.ratingFront ? {
										label: `${r.courseName} / ` + current.label + ` F9 (${current.ratingFront} / ${frontPar})`,
										unparsedLabel: current.label + ' F9',
										value: current.value,
										courseId: current.courseId,
										tee_set_side: 'F9',
										teeSetID: current.teeId
									}: null;
									let back = current.ratingBack ? {
										label: `${r.courseName} / ` + current.label + ` B9 (${current.ratingBack} / ${backPar})`,
										unparsedLabel: current.label + ' B9',
										value: current.value,
										courseId: current.courseId,
										tee_set_side: 'B9',
										teeSetID: current.teeId
									} : null;
									if(front && back) {
										return res.concat([front, back])
									}
									if (front) {
										return res.concat([front]);
									}
									if (back) {
										return res.concat([back]);
									}
									return null;
								}, []);
							}

							return filteredTS;
						}));

						return flatten(courses);

					}));
					this.setState({
            home_courses: flatten(courses).map(el => el.courseId),
						courses: flatten(courses)
					});
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.setState({
			golfer: this.props.golfer
		}, () => {
			this.getHomeCourses();
		});
		this.props.initialize({date: new Date()});
	}


	setTees() {
		if (this.props.course_name && this.props.course_name.value) {
			CourseRatingSystemService.getCourseDetails(this.props.course_name.value)
				.then(response => {
					let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.state.golfer.gender).map(ts => {
						let totalRatings = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
						let frontRatings = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
						let backRatings = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
						return {
							label: ts.TeeSetRatingName,
							holes: ts.Holes,
							holesNumber: ts.HolesNumber,
							value: ts.TeeSetRatingId,
							ratingTotal: totalRatings && `${parseFloat(totalRatings.CourseRating).toFixed(1)} / ${totalRatings.SlopeRating}`,
							ratingFront: frontRatings && `${parseFloat(frontRatings.CourseRating).toFixed(1)} / ${frontRatings.SlopeRating}`,
							ratingBack: backRatings && `${parseFloat(backRatings.CourseRating).toFixed(1)} / ${backRatings.SlopeRating}`,
							ratingTotalValue: totalRatings && parseFloat(totalRatings.CourseRating).toFixed(1),
						}
					});
					orderTees(filteredTS);
					if (this.state.nrHoles !== "18") {
						filteredTS = filteredTS.reduce((res, current) => {
							let frontPar = current.holes.reduce((acc, h, index) => {
								if (index < 9) return acc + h.Par;
								return acc + 0;
							}, 0);
							let backPar = current.holes.reduce((acc, h, index) => {
								if (index >= 9) return acc + h.Par;
								return acc + 0;
							}, 0);
							let front = current.ratingFront ? {
								label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
								unparsedLabel: current.label + ' F9',
								value: current.value,
								tee_set_side: 'F9'
							} : null;
							let back = current.ratingBack ? {
								label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
								unparsedLabel: current.label + ' B9',
								value: current.value,
								tee_set_side: 'B9'
							} : null;
							if(front && back) {
								return res.concat([front, back])
							}
							if (front) {
								return res.concat([front]);
							}
							if (back) {
								return res.concat([back]);
							}
							return null;
						}, []);
					} else {
						filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
							let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
							let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
							return {label: label, value: ts.value}
						});
					}
					this.setState({
						teeSetsOptions: filteredTS
					}, () => {
						if (this.props.tee_set_id && this.props.tee_set_id.value) {
							let selectedTeeSet = this.state.teeSetsOptions.filter(ts => ts.value === this.props.tee_set_id.value);
							if (selectedTeeSet.length) {
								this.props.change('tee_set_id', selectedTeeSet[0]);
								this.props.untouch('tee_set_id')
							} else {
								this.props.change('tee_set_id', null);
								this.props.untouch('tee_set_id');
							}
						}
					})
				})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.scoreType !== prevState.scoreType) {
      if (!this.state.auto_score_type) {
        if (!(this.state.scoreType === 'away' && prevState.scoreType === 'tournament') &&
        !(this.state.scoreType === 'tournament' && prevState.scoreType === 'away') &&
			!(this.state.scoreType === 'home' && prevState.scoreType === 'tournament') &&
			!(this.state.scoreType === 'tournament' && prevState.scoreType === 'home')
		) {
          this.props.change('course_name', null);
          this.props.untouch('course_name');
          this.props.change('tee_set_id', null);
          this.props.untouch('tee_set_id');
        }
        this.props.change('front9', null);
        this.props.change('back9', null);
        this.props.change('total', null);
        this.setState({
          errors: []
        })
      }
      else {
        this.setState({
          auto_score_type: false
        });
      }
		}
		if (this.state.method !== prevState.method) {
			this.setState({
				courses: [],
				errors: []
			});
			if (this.state.method === 'home-course') {
				this.getHomeCourses();
			} else {
				this.props.untouch('tee_set_id');
			}
		}
		if (this.state.nrHoles !== prevState.nrHoles) {
			this.setState({
				errors: []
			});
			if (this.state.method === 'home-course') {
				this.getHomeCourses();
			} else {
				this.setTees();
			}
		}
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		} return new Promise(resolve => {
			resolve([])
		})

	}

	setManualValues(data) {
		this.props.change('course_name', {value: -1, label: data.course_name});
		this.props.change('tee_set_id', {value: -1,tee_set_side: this.state.nrHoles === "9" ? 'F9' : 'All18', label: `${data['total-cr']} / ${data['total-slope']}`});
		this.props.change('manual_values', data);
		this.setState({
			manualCourseModal:false,
			manualRatingModal: false,
			isManual: true,
		});

	}

	closeManualModal() {
		if(!this.state.isManual) {
			this.props.externalDestroy('manualCourseForm');
			this.props.changeWithForm('totalForm', 'front9', null);
			this.props.changeWithForm('totalForm', 'back9', null);
			this.props.changeWithForm('totalForm', 'total', null);
		}
		this.setState({manualCourseModal: false, manualRatingModal: false})
	}

  penaltyTypeSetState(data) {
    this.setState({penaltyType: data})
    this.props.change('penaltyType', data);
  }

  holesSetState(data) {
    this.setState({nrHoles: data})
    this.props.change('nrHoles', data);
    if (this.state.method ==='home-course') {
      this.props.change('course_name', null);
    }
  }

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		let self = this;
		let disabled_submit = false
		const auto_penalty_type = this.state.scoreType === 'penalty' && this.state.penaltyMethod === 'auto'

		if (this.props?.golfer?.use_scaling && !auto_penalty_type) {
			const hasTotal = Boolean(this.props.total)
			const hasBackAndFront = Boolean(this.props.back && this.props.front)
			disabled_submit = !hasTotal || (!hasBackAndFront && (this.props.back || this.props.front))
		}

		return (
			<Fragment>
      <ConfirmationModalTypeB
        wideClass={'narrow'}
        openModal={this.state.outdatedScoreModalOpen && !this.state.confirmationModalOpen && !this.props.golfer.use_scaling}
        onConfirmAction={()=>{
          this.setState({
            outdatedScoreModalOpen: false,
          })
        }}
        closeModal={()=>{
          this.setState({
            outdatedScoreModalOpen: false,
          })
        }}
        infoText={["This 9-hole score is older than the twentieth oldest 18-hole score in " +
                   "the golfer's scoring record and will not be combined. The score has been " +
                   "posted successfully, but will not display in the scoring record and will " +
                   "not be considered when calculating the golfer's Handicap Index.",
                   "For historical purposes, the score can be found in the Audit Logs."]}
        confirmLabel={"OK"}
        modalIcon={"check_circle"}
      />
			<form autoComplete="off"  onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form'}>
				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__body">
								<div className="row with-padding-vertical ">
									<div className="col  is-1-of-6">
										<label htmlFor="date">Date Played <span>*</span></label>
										<Field maxDate={new Date()}
											   minDate={new Date('1/1/1990')}
											   className={'date'}
											   showMonthDropdown
											   showYearDropdown
											   name={'date'}
											   id={'date'}
											   component={DatePickerInput}
											   placeholder="Select ..." />
									</div>
									<div className="col  is-3-of-6">
										<label htmlFor="date">Score Type</label>
										<div className="custom-radio__container">
											<Field
												onClick={
													() => {
														this.setState({
															scoreType: 'home',
															method: 'home-course'
														});
														this.props.change('method', 'home');
														this.props.change('scoreType', 'home');
													}
												}
												checked={this.state.scoreType === "home"}
												id="home"
												name={'home'}
												component={'input'}
												type="radio"
											/>
											<label htmlFor="home">Home</label>

											<Field
												onClick={
													() => {
														this.setState({
															scoreType: 'away',
															method: 'course'
														})
														this.props.change('scoreType', 'away');
														this.props.change('method', 'course');
													}
												}
												checked={this.state.scoreType === 'away'}
												id='away'
												name={'away'}
												component={'input'}
												type="radio"
											/>
											<label htmlFor="away">Away</label>

											<Field
												onClick={
													() => {
														this.setState({
															scoreType: "tournament",
														});
														this.props.change('scoreType', "tournament");
													}
												}
												checked={this.state.scoreType === "tournament"}
												id="tournament"
												name={"tournament"}
												component={'input'}
												type="radio"
											/>
											<label  htmlFor="tournament">Competition</label>

											<Field
												onClick={
													() => {
														this.setState({
															scoreType: "penalty",
															penaltyMethod: "auto"
														})
														this.props.change('scoreType', "penalty");
														this.props.change('penaltyMethod', 'auto')
													}
												}
												checked={this.state.scoreType === "penalty"}
												id="penalty"
												name={"penalty"}
												component={'input'}
												type="radio"
											/>
											<label htmlFor="penalty">Penalty</label>
										</div>
									</div>
									{this.state.scoreType === "penalty" ?
										<Fragment>
											<div className="col  is-1-of-6">
												<label htmlFor="date">Penalty Method</label>
												<div className="custom-radio__container">
													<Field
														onClick={
															() => {
																this.setState({
																	penaltyMethod: 'auto'
																});
																this.props.change('penaltyMethod', 'auto');
															}
														}
														checked={this.state.penaltyMethod === "auto"}
														id="auto"
														name={'auto'}
														component={'input'}
														type="radio"
													/>
													<label htmlFor="auto">Auto</label>

													<Field
														onClick={
															() => {
																this.setState({
																	penaltyMethod: 'manual'
																})
																this.props.change('penaltyMethod', 'manual');
															}
														}
														checked={this.state.penaltyMethod === "manual"}
														id="manual"
														name={'manual'}
														component={'input'}
														type="radio"
													/>
													<label htmlFor="manual">Manual</label>
												</div>
											</div>
											{this.props?.golfer?.use_scaling ? 
                        <PenaltyType
                          state = {this.state}
                          handlerFunction = {this.penaltyTypeSetState}
                        />
                        :
                        <Holes
                          state = {this.state}
                          handlerFunction = {this.holesSetState}
                        />
                      }
										</Fragment>
										:
										<Holes
                      state = {this.state}
                      handlerFunction = {this.holesSetState}
                    />
									}
								</div>

								<div className="row with-padding-vertical ">
									{(this.state.scoreType !== "penalty" || (this.state.scoreType === "penalty" && this.state.penaltyMethod === "manual")) ?
									<Fragment>
										<div className="col  is-1-of-2">
												<label htmlFor="date">Choose Course/Tee Lookup Method:</label>
												<div className="radio__container">
													<Field
														onClick={
															() => {
																this.setState({
																	method: 'home-course'
																});
																this.props.change('method', 'home-course');
															}
														}
														checked={this.state.method === 'home-course'}
														id='home-course'
														name={'home-course'}
														component={'input'}
														type="radio"
														className="simple__radio"
													/>
												<label htmlFor="home-course">Home Courses/Tees</label>

												<Field
													onClick={
													() => {
															this.setState({
																method: 'course'
															});
															this.props.change('method', 'course');
															this.props.change('course_name', null)
														}
													}
													checked={this.state.method === 'course'}
													id='course'
													name={'course'}
													component={'input'}
													type="radio"
													className="simple__radio"
													/>
												<label htmlFor="course">Course/Tee Search</label>
												</div>
											</div>


										{this.state.method === "home-course" ?
											<div className="col is-1-of-2">
												<Fragment>
													<label htmlFor={'course_name'}>Course/Tee Played</label>
													<Field name={'course_name'}
														   id={'course_name'}
														   component={SelectInput}
														   className="react-select-container"
														   classNamePrefix="react-select"
														   options={this.state.courses}/>
												</Fragment>
											</div>
										:
										<Fragment>
											<div className="col is-2-of-6">
												<label  htmlFor={'tee'}>Course Played <span>*</span></label>
												<Field name={'course_name'}
													   id={'course_name'}
													   defaultOptions={this.state.courses}
													   loadOptions={debounce(self.getCoursesOptions, 1000)}
													   component={AsyncSelectInput}
													   onChange={(event, newValue, prevValue) => {
														   if (newValue) {
                                 if (this.state.home_courses.includes(newValue.value)) {
                                   if (this.state.scoreType === 'away') {
                                    this.setState({
                                      scoreType: 'home',
                                      auto_score_type: true
                                    });
                                    this.props.change('scoreType', 'home');
                                  }
                                 }
                                 else {
                                   if (this.state.scoreType === 'home') {
                                    this.setState({
                                      scoreType: 'away',
                                      auto_score_type: true
                                    });
                                    this.props.change('scoreType', 'away');
                                  }
                                 }
														   		this.props.change('course_id', newValue);
															   CourseRatingSystemService.getCourseDetails(newValue.value)
																   .then(response => {
																	   let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.state.golfer.gender).map(ts => {
																		let totalRatings = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
																		let frontRatings = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
																		let backRatings = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
																			return {
																		   	   label: ts.TeeSetRatingName,
																			   holes: ts.Holes,
																			   holesNumber: ts.HolesNumber,
																			   value: ts.TeeSetRatingId,
																			   ratingTotal: totalRatings && `${parseFloat(totalRatings.CourseRating).toFixed(1)} / ${totalRatings.SlopeRating}`,
																			   ratingFront: frontRatings && `${parseFloat(frontRatings.CourseRating).toFixed(1)} / ${frontRatings.SlopeRating}`,
																			   ratingBack: backRatings && `${parseFloat(backRatings.CourseRating).toFixed(1)} / ${backRatings.SlopeRating}`,
																				ratingTotalValue: totalRatings && parseFloat(totalRatings.CourseRating).toFixed(1),
																		   }
																	   });
																	   orderTees(filteredTS);
																	   if (this.state.nrHoles !== "18") {
																		   filteredTS = filteredTS.reduce((res, current) => {
																			   let frontPar = current.holes.reduce((acc, h, index) => {
																				   if (index < 9) return acc + h.Par;
																				   return acc + 0;
																			   }, 0);
																			   let backPar = current.holes.reduce((acc, h, index) => {
																				   if (index >= 9) return acc + h.Par;
																				   return acc + 0;
																			   }, 0);
																			   let front = current.ratingFront ? {
																				   label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
																				   unparsedLabel: current.label + ' F9',
																				   value: current.value,
																				   tee_set_side: 'F9'
																			   } : null;
																			   let back = current.ratingBack ? {
																				   label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
																				   unparsedLabel: current.label + ' B9',
																				   value: current.value,
																				   tee_set_side: 'B9'
																			   } : null;
																			   if(front && back) {
																				   return res.concat([front, back])
																			   }
																			   if (front) {
																				   return res.concat([front]);
																			   }
																			   if (back) {
																				   return res.concat([back]);
																			   }
																				 return null;
																		   }, []);
																	   } else {
																		   filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
																			   let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
																			   let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
																			   return {label: label, value: ts.value}
																		   });
																	   }
																	   this.props.change('tee_set_id', null);
																	   this.setState({
																		   teeSetsOptions: filteredTS
																	   });
																	   this.props.untouch('tee_set_id');
																   })
														   } else {
															   this.props.change('tee_set_id', null);
															   this.setState({
																   teeSetsOptions: []
															   });
															   this.props.untouch('tee_set_id');
                                 if (this.state.scoreType === 'home') {
                                  this.setState({
                                    scoreType: 'away'
                                  });
                                  this.props.change('scoreType', 'away');
                                }
														   }
													   }}
													   className="react-select-container"
													   classNamePrefix="react-select"
														 placeholder="Enter Course Name"
														 grayPlaceholder={true}
												/>
											   	<button type="button" className={"btn label"}  onClick={(e)=>{e.preventDefault(); this.setState({manualCourseModal: true})}}><span>Manual Course Entry</span> (Not recommended)</button>
											</div>
											<div className="col is-1-of-6">
												<label  htmlFor={'tee'}>Tee (C.R. / Slope / Par) <span>*</span></label>
												<Field name={'tee_set_id'}
													   disabled={!this.props.course_name}
													   validate={[requiredSelect]}
													   id={'tee_set_id'}
													   options={this.state.teeSetsOptions}
													   component={SelectInput}
													   className="react-select-container"
													   classNamePrefix="react-select"
												/>
												<button type="button"  className={"btn label"} disabled={!this.props.course_name}  onClick={(e)=>{e.preventDefault(); this.setState({manualRatingModal: true})}}><span>Manual Rating Entry</span></button>
											</div>
										</Fragment>}
									</Fragment>
										:
										<Fragment>
                    {!this.props?.golfer?.use_scaling && <>
											<div className="col  is-5-of-6"></div>
											<PenaltyType
                          state = {this.state}
                          handlerFunction = {this.penaltyTypeSetState}
                        />
                      </>
                    }
										</Fragment>
                  }

								</div>
								{(this.state.scoreType !== "penalty" || (this.state.scoreType === "penalty" && this.state.penaltyMethod === "manual")) ? <div className="row with-padding-vertical align-right ">
									{this.state.nrHoles === "18" ?
										<Fragment>
											<div className="col is-1-of-6 ">
												<label  htmlFor={'front9'}>Front 9</label>
												<Field disabled={(this.props.total && this.props.total.length)} component={InputField} type="text" name={'front9'} id={'front9'} onChange={(event, newValue, prevValue) => {
													if (this.state.errors.length > 0) {this.setState({errors: []})}
													if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
														let backValue = parseInt(this.props.back) || 0;
														this.props.change('total', backValue);
													}
													if (newValue.length === 1) {
														if (parseInt(newValue) === 0) {
															event.preventDefault();
															return;
														}

														let backValue = parseInt(this.props.back) || 0;
														this.props.change('total', backValue + parseInt(newValue));
													}
													if (newValue.length > 2) {
														event.preventDefault();
														return;
													}
													if(newValue.length === 2) {
														let backValue = parseInt(this.props.back) || 0;
														this.props.change('total', backValue + parseInt(newValue));
														document.getElementsByClassName('back9')[0].focus();
													}
												}}/>
											</div>
											<div className="col is-1-of-6">
												<label htmlFor={'back9'}>Back 9</label>
												<Field disabled={(this.props.total && this.props.total.length)} className={'back9'} component={InputField} type="text" name={'back9'} id={'back9'} onChange={(event, newValue, prevValue) => {
													if (this.state.errors.length > 0) {this.setState({errors: []})}
													if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
														let frontValue = parseInt(this.props.front) || 0;
														this.props.change('total', frontValue);
													}
													if (newValue.length === 1) {
														if (parseInt(newValue) === 0) {
															event.preventDefault();
															return;
														}
														let frontValue = parseInt(this.props.front) || 0;
														this.props.change('total', frontValue + parseInt(newValue));
													}
													if (newValue.length > 2) {
														event.preventDefault();
													}
													if(newValue.length === 2) {
														let frontValue = parseInt(this.props.front) || 0;
														this.props.change('total', frontValue + parseInt(newValue));
													}
												}}/>
											</div>
											<div className="col is-1-of-6">
												<label htmlFor={'total'}>Total 18</label>
												<Field disabled={(this.props.front && this.props.front.length) || (this.props.back && this.props.back.length)} onChange={()=>{if (this.state.errors.length > 0) {this.setState({errors: []})}}} component={InputField} type="text" name={'total'} id={'total'}/>
											</div>
										</Fragment>
										:
										<div className="col is-1-of-6">
											<label htmlFor={'total'}>Total 9</label>
											<Field component={InputField} type="text" onChange={()=>{if (this.state.errors.length > 0) {this.setState({errors: []})}}} name={'total'} id={'total'}/>
										</div>
									}
								</div> :
									<div className="row with-padding-vertical">
										<div className="col"></div>
									</div>
								}

								{this.state.errors.length > 0 &&
								<p>
									{this.state.errors.map(error=> {
										return (<span className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
									})}
								</p>
								}
								<div className="row with-padding-vertical">
									<div className="col is-1-of-6 push-right">
										<button disabled={disabled_submit || pristine || submitting || ((this.state.scoreType === 'penalty' && this.state.penaltyMethod === "auto") ? false : !this.props.course_name)} type={'submit'} className="btn fill blue">Post Score</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</form>

			<Modal
				isOpen={this.state.manualCourseModal || this.state.manualRatingModal}
				onRequestClose={()=>{this.closeManualModal()}}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={false}
				shouldReturnFocusAfterClose={false}
					>
					<ManualCourseModal setManualValues={this.setManualValues} alreadySaved={this.state.isManual} nrHoles={this.state.nrHoles} course={this.state.manualRatingModal && this.props.course_name.label} closeModal={()=>{this.closeManualModal()}} />
			</Modal>

			<ConfirmationModalTypeB
				openModal={this.state.confirmationModalOpen}
				onConfirmAction={() => this.confirmScore()}
				modalIcon={'help_outline'}
				onCancelAction={() => {
					this.setState({
						confirmationModalOpen: false,
						savedEntry: null
					})
				}}
				closeModal={() => {
					this.setState({
						confirmationModalOpen: false,
						savedEntry: null
					})
				}}
				question={"Duplicate Score Found"}
				bodyQuestion={this.state.modalReviewBodyQuestion}
				infoText={['Press "Continue" to post the score anyway', 'Press "Cancel" to review the score prior to posting']}
				cancelLabel={"Cancel"}
				confirmLabel={"Continue"}
			/>

		</Fragment>

		);
	}
}

TotalForm = reduxForm({
	form: 'totalForm',
	destroyOnUnmount: true,
	touchOnChange: true
})(TotalForm);

function mapStateToProps(state) {
	const selector = formValueSelector('totalForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		total: selector(state, 'total'),
		front: selector(state, 'front9'),
		back: selector(state, 'back9'),
		course_name: selector(state, 'course_name'),
		tee_set_id: selector(state, 'tee_set_id')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert,changeWithForm, externalDestroy}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TotalForm));
