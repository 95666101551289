import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import JoinAndRenewServices from '../../services/api/joinAndRenew';
import { addAlert } from "../shared/actions";
import { useDispatch } from 'react-redux';
import Modal from "react-modal";
import RefundConfirmationModal from "./RefundConfirmationModal";

const transaction_types = { 'sale': 'Membership', 'refund': 'Refund' }

export default function RefundModal(props) {
  const [fullRefundSelected, setFullRefundSelected] = useState(true);
  const [includeFee, setIncludeFee] = useState(false);
  const [inactivateGolfer, setInactivateGolfer] = useState(false);
  const [includeAssociationFee, setIncludeAssociationFee] = useState(false);
  const [includeClubFee, setIncludeClubFee] = useState(false);
  const [includedAddons, setIncludedAddons] = useState({});
  const [enteredAssociationFee, setEnteredAssociationFee] = useState('0.00');
  const [enteredClubFee, setEnteredClubFee] = useState('0.00');
  const [exceedsAssociationFeeLimit, setExceedsAssociationFeeLimit] = useState(false);
  const [exceedsClubFeeLimit, setExceedsClubFeeLimit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const transaction = props.transaction;
  if (transaction === null)
    return null;

  const join_type = transaction.payment_details.join_type;

  const golfer_name = `${transaction.full_name}`;
  const transaction_date = moment(new Date(transaction.created_at), 'YYYY/MM/DD HH:mm:ss');
  const consumed_date = transaction.consumed_date && moment(transaction.consumed_date).format('MM/DD/YYYY');

  const paymentDetails = transaction.payment_details;

  const getClubAmount = () => {
    const prorating_data = paymentDetails.prorating_data;
    let amount;

    if (paymentDetails.membership_data?.discounted_amount !== undefined)
      amount = paymentDetails.membership_data.discounted_amount;
    else if (prorating_data && prorating_data.hasOwnProperty('club_fee'))
      amount = prorating_data.club_fee.prorated_amount;
    else
      amount = paymentDetails.membership_data.amount;

    return parseFloat(amount);
  };

  const getAssociationAmount = () => {
    const prorating_data = paymentDetails.prorating_data;
    let amount;

    if (paymentDetails.golf_association_rate?.discounted_amount !== undefined)
      amount = paymentDetails.golf_association_rate.discounted_amount;
    else if (prorating_data && prorating_data.hasOwnProperty('association_fee'))
      amount = prorating_data.association_fee.prorated_amount;
    else
      amount = paymentDetails.golf_association_rate.amount;

    return parseFloat(amount);
  };

  const club_amount = getClubAmount();
  const assoc_amount = getAssociationAmount();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let initialAddons = {};
    paymentDetails['addon_charges'].forEach(addon => {
      initialAddons[addon['id']] = false;
    });
    setIncludedAddons(initialAddons);
  }, [paymentDetails]);

  const getFeeValue = (paymentDetails) => {
    let value = 0;

    const transaction_fee = paymentDetails.transaction_fee;

    if (transaction_fee)
      value = Number(transaction_fee.amount) || 0

    return value;
  };
  
  const rows = [
    {
      label: 'Name:',
      value: golfer_name
    },
    {
      label: 'GHIN #:',
      value: transaction.golfer_id
    },
    {
      label: 'Transaction Date:',
      value: transaction_date.format('MM/DD/YYYY HH:mm:ss')
    },
    {
      label: 'Transaction Type:',
      value: `${transaction.transaction_type} (${transaction_types[transaction.transaction_type]})`
    },
    {
      label: 'Transaction #:',
      value: transaction.transaction_id
    },
    {
      label: 'Transaction Total:',
      value: `$${transaction.amount}`
    }
  ];

  const getAssociationFee = () => {
    if (fullRefundSelected)
      return assoc_amount.toFixed(2)
    else
      return includeAssociationFee && enteredAssociationFee ? parseFloat(enteredAssociationFee).toFixed(2) : parseFloat(0).toFixed(2)
  };

  const getClubFee = () => {
    if (fullRefundSelected)
      return club_amount.toFixed(2)
    else
      return includeClubFee && enteredClubFee ? parseFloat(enteredClubFee).toFixed(2) : parseFloat(0).toFixed(2)
  };

  const getTotalRefund = () => {
    if (fullRefundSelected)
      return includeFee ? transaction.amount : (transaction.amount - getFeeValue(paymentDetails)).toFixed(2)
    else {
      let total = 0;
      if (includeFee)
        total = total + parseFloat(getFeeValue(paymentDetails).toFixed(2));
      if (includeAssociationFee && enteredAssociationFee)
        total = total + parseFloat(enteredAssociationFee);
      if (includeClubFee && enteredClubFee)
        total = total + parseFloat(enteredClubFee);
      paymentDetails['addon_charges'].forEach(addon => {
        if (includedAddons[addon['id']])
          total += parseFloat(addon.amount)
      });
      return total.toFixed(2);
    }
  };

  const refund_rows = [
    {
      label: 'Association Fee:',
      value: `$${getAssociationFee()}`
    },
    {
      label: 'Club fee:',
      value: `$${getClubFee()}`
    },
    {
      label: 'Transaction Fee:',
      value: includeFee ? `$${getFeeValue(paymentDetails).toFixed(2)}` : `$0.00`
    },
    ...(fullRefundSelected ? paymentDetails['addon_charges'] : paymentDetails['addon_charges'].filter(addon => includedAddons[addon['id']])).map(addon => ({
      label: `Refund ${addon.name}:`,
      value: `$${parseFloat(addon.amount).toFixed(2)}`
    })),
    {
      label: '',
      value: ''
    },
    {
      label: 'Total refund:',
      value: `$${getTotalRefund()}`
    }
  ];

  const refundMessage = (join_type, name, date) => {
    let message = ''
    
    if (!fullRefundSelected){
      if (!inactivateGolfer){
        if (join_type === 'new_affiliation')
          message =
            <span>Once submitted, a partial refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file. <strong>{name}</strong> will remain active in the club until the current membership end date of <strong>{date}</strong>.</span>;
        if (join_type === 'renewing_inactive_affiliations')
          message =
            <span>Once submitted, a partial refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file. <strong>{name}</strong> will remain active in the club until the current membership end date of <strong>{date}</strong>.</span>;
        if (join_type === 'renewing_active_affiliations' || join_type === 'auto_renew')
          message =
            <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file. <strong>{name}</strong> will remain active in the club until the current membership end date of <strong>{date}</strong>.</span>;
      } else {
        if (join_type === 'new_affiliation')
          message =
            <span>Once submitted, a partial refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file, and <strong>{name}</strong> will be removed from the club immediately.</span>;
        if (join_type === 'renewing_inactive_affiliations')
          message =
            <span>Once submitted, a partial refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file, and <strong>{name}</strong> will be inactivated in the club immediately.</span>;
        if (join_type === 'renewing_active_affiliations' || join_type === 'auto_renew')
          message =
            <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file. <strong>{name}</strong> will be inactivated in the club immediately.</span>;
      }  
    }
    
    if (fullRefundSelected) {
      if (join_type === 'new_affiliation')
          message =
            <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file, and <strong>{name}</strong> will be removed from the club immediately.</span>;
      if (join_type === 'renewing_inactive_affiliations')
        message =
          <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file, and <strong>{name}</strong> will be inactivated in the club immediately.</span>;
      if (join_type === 'renewing_active_affiliations' || join_type === 'auto_renew')
        if (transaction.consumed) {
          message =
            <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file, and <strong>{name}</strong> will be inactivated in the club immediately.</span>;
        }
        else {
          message =
            <span>Once submitted, a refund of <strong>${getTotalRefund()} </strong> will be issued to the credit card on file. <strong>{name}</strong> will remain active in the club until the current membership end date of {consumed_date}.</span>;
        }
    }
    if (!includeFee)
      message =
      <>
        {message}
        <br></br>
        <br></br>
        <span> This refund does not include transaction fees.</span>
      </>
    return <div>{message}</div>
  }

  const submitRefund = () => {
    setDisabled(true);
    const params = {
      full_refund: fullRefundSelected,
      include_fee: includeFee,
      association_fee: getAssociationFee(),
      club_fee: getClubFee(),
      addon_charge_ids: fullRefundSelected ? Object.keys(includedAddons).map(Number) : Object.keys(includedAddons).filter(key => includedAddons[key] === true).map(Number)
    }
    if (!fullRefundSelected) {
      params.inactivate_golfer = inactivateGolfer;
    }
    JoinAndRenewServices.refundTransaction(transaction.id, params)
      .then((res) => {
        setDisabled(false);
        props.updateTableOnRefund();      
        dispatch(addAlert({
          type:'success',
          message:'The refund has been successfully made'
        }));
        props.closeModal();
      })
      .catch(() => {
        setDisabled(false);
        dispatch(addAlert({
          type: 'error',
          message: 'Refund failed'
        }));
        props.closeModal();
      });
  }

  const handleAssociationFeeChange = (e) => {
    const re = /^[0-9]*\.?[0-9]{0,2}$/;
    if (re.test(e.target.value)) {
      setEnteredAssociationFee(e.target.value);
      setExceedsAssociationFeeLimit(parseFloat(e.target.value) > assoc_amount);
    }
  };

  const handleClubFeeChange = (e) => {
    const re = /^[0-9]*\.?[0-9]{0,2}$/;
    if (re.test(e.target.value)) {
      setEnteredClubFee(e.target.value);
      setExceedsClubFeeLimit(parseFloat(e.target.value) > club_amount);
    }
  };

  const checkDisabled = () => {
    if (fullRefundSelected === false && getTotalRefund() === parseFloat(0).toFixed(2))
      return true
    if (disabled)
      return true
    if (fullRefundSelected === false && includeAssociationFee && exceedsAssociationFeeLimit)
      return true
    if (fullRefundSelected === false && includeClubFee && exceedsClubFeeLimit)
      return true
    return false
  };

  return (
    <Fragment>

      <div className="modal__container semi-narrow">
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">attach_money</i></div>
          <h2 className="modal__title">Refund</h2>
          <button className="modal__close" onClick={() => {
            props.closeModal()
          }}><i className="material-icons-outlined">clear</i></button>
        </div>

        <div className="modal__body">
          <div className='row'>
            <div className='col'>
              <span className='label'>Transaction Details</span>
            </div>
          </div>
          <div className='row'>
            <div className='col is-full'>
              <table className='data__table'>
                <tbody>
                  {rows.map((row, key) => {
                    return (
                      <tr key={key}>
                        <td className='table-left-cell'>{row.label}</td>
                        <td className='table-right-cell'>{row.value}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <hr className="refund-hr"></hr>
          <div className='row' style={{marginBottom: "5px"}}>
            <div className='col'>
              <span className='label'>Refund Details</span>
            </div>
          </div>
          <div className='row' style={{justifyContent: "center", display: "flex"}}>
            <div className='col is-1-of-4 no-padding-right'>
              <button
                className={`refund-btn full-refund ${fullRefundSelected ? 'selected' : ''}`}
                onClick={() => setFullRefundSelected(true)}>Full Refund
              </button>
            </div>
            <div className='col is-1-of-4 no-padding-left'>
              <button
                className={`refund-btn partial-refund ${!fullRefundSelected ? 'selected' : ''}`}
                onClick={() => setFullRefundSelected(false)}>Partial Refund
              </button>
            </div>
          </div>
          <br></br>
          {paymentDetails.transaction_fee &&
            <div className='row' style={{marginBottom: 0}}>
              <div className='col'>
                <label className='refund-fee'>
                  <input
                    type="checkbox" defaultChecked={includeFee} value={includeFee} name="include_fee" id="include_fee"
                    onClick={(e) => {
                      setIncludeFee(e.target.checked);
                    }}
                  />
                  <span>Include Transaction Fee of ${getFeeValue(paymentDetails).toFixed(2)}?</span>
                </label>
              </div>
            </div>
          }
          {!fullRefundSelected && <>
            <div className='row' style={{marginBottom: "10px"}}>
              <div className='col'>
                <label className='refund-fee'>
                  <input
                    type="checkbox" defaultChecked={includeAssociationFee} value={includeAssociationFee}
                    onClick={(e) => {
                      setIncludeAssociationFee(e.target.checked);
                    }}
                  />
                  <span>Refund Association Fee</span>
                </label>
              </div>
              <div className='col'>
                <input
                  type="text"
                  className="refund-input"
                  value={enteredAssociationFee}
                  onChange={handleAssociationFeeChange}
                  onBlur={(e) => {
                    if (e.target.value === "") setEnteredAssociationFee("0.00");
                  }}
                  disabled={!includeAssociationFee}
                />
                <div className={`refund-limit-note ${exceedsAssociationFeeLimit ? 'red-note' : ''}`}>Cannot
                  Exceed {`$${assoc_amount.toFixed(2)}`}</div>
              </div>
            </div>
            <div className='row' style={{marginBottom: "0"}}>
              <div className='col'>
                <label className='refund-fee'>
                  <input type="checkbox" defaultChecked={includeClubFee} value={includeClubFee} onClick={(e) => {
                    setIncludeClubFee(e.target.checked);
                  }}
                  />
                  <span>Refund Club Fee</span>
                </label>
              </div>
              <div className='col'>
                <input
                  type="text"
                  className="refund-input"
                  value={enteredClubFee}
                  onChange={handleClubFeeChange}
                  onBlur={(e) => {
                    if (e.target.value === "") setEnteredClubFee("0.00");
                  }}
                  disabled={!includeClubFee}
                />
                <div className={`refund-limit-note ${exceedsClubFeeLimit ? 'red-note' : ''}`}>Cannot
                  Exceed {`$${club_amount.toFixed(2)}`}</div>
              </div>
            </div>
            {paymentDetails['addon_charges'].map((addon, key) => {
              return (
                <div className='row' key={addon.id} style={{marginBottom: 0}}>
                  <div className='col'>
                    <label className='refund-fee'>
                      <input
                        type="checkbox"
                        checked={includedAddons[addon['id']]}
                        onChange={() => {
                          setIncludedAddons({
                            ...includedAddons,
                            [addon['id']]: !includedAddons[addon['id']]
                          });
                        }}
                      />
                    <span>Refund {addon.name} (${parseFloat(addon.amount).toFixed(2)})</span>
                    </label>
                  </div>
                </div>
              )
            })}
            <div className="row" style={{marginBottom: "7px"}}></div>
          </>}
          <div className='row' style={{
            marginBottom: "27px",
            marginTop: paymentDetails['addon_charges'].length === 0 && !fullRefundSelected ? "25px" : "15px"
          }}>
            <div className='col is-full'>
              <table className='data__table'>
                <tbody className='no-background'>
                <tr>
                  <td className='table-center-cell'><strong>Refund Name</strong></td>
                  <td className='table-center-cell'><strong>Refund Amount</strong></td>
                </tr>
                {refund_rows.map((row, key) => {
                  return (
                    <tr key={key}>
                      <td
                        className={`table-left-cell ${row.label === 'Total refund:' ? 'strong' : ''}`}>{row.label ? row.label : <>&nbsp;</>}</td>
                      <td
                        className={`table-right-cell ${row.label === 'Total refund:' ? 'strong' : ''}`}>{row.value}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='note'>
                {refundMessage(join_type, golfer_name, transaction.membership_end_date)}
              </div>
            </div>
          </div>
          {!fullRefundSelected &&
            <div className='row' style={{marginBottom: 0}}>
              <div className='col'>
                <label className='refund-fee inactivate-golfer'>
                  <input
                    type="checkbox" defaultChecked={inactivateGolfer} value={inactivateGolfer}
                    onClick={(e) => {
                      setInactivateGolfer(e.target.checked);
                    }}
                  />
                  <span>Inactivate Golfer on Submission?</span>
                </label>
              </div>
            </div>
          }
          <div className={fullRefundSelected ? "row" : "row margin-top-34"}>
            <div className="col is-1-of-2">
              <button type='button' onClick={() => props.closeModal()} className="btn fill gray">Cancel</button>
            </div>
            <div className="col is-1-of-2">
              <button type='submit' className="btn fill blue" onClick={() => setDeleteModalIsOpen(true)}
                      disabled={checkDisabled()}>Issue Refund
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <RefundConfirmationModal
          closeModal={() => {setDeleteModalIsOpen(false)}}
          onConfirm={() => submitRefund()}
          golferName={golfer_name}
          refund_rows={refund_rows}
          loading={disabled}
        />
      </Modal>

    </Fragment>
  );
}
