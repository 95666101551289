import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import AddMembershipTypeModal from '../../../Modals/AddMembershipTypeModal';
import EditMembershipTypeModal from '../../../Modals/EditMembershipTypeModal';
import EditEmailCampaignModal from '../../../Modals/EditEmailCampaignModal';
import AssociationService from "../../../../services/api/association";
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {addAlert} from "../../../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { CalendarService } from '../../../../services/calendarService';
import EditJoinAndRenewEnabledModal from '../../../Modals/EditJoinAndRenewEnabledModal';
import EditClubChallengesEnabledModal from '../../../Modals/EditClubChallengesEnabledModal';
import JoinAndRenewServices from '../../../../services/api/joinAndRenew';
import MembershipTypeEditLogModal from "../../../Modals/MembershipTypeEditLogModal";
import ReactTooltip from "react-tooltip";
import {getProratedAmount} from "../../../shared/proratings";

class AccountSetupAssoc extends Component {

    constructor(props) {

        super(props);

        this.state = {
            AddMembershipTypeModalIsOpen: false,
            EditMembershipTypeModalIsOpen: false,
			EditEmailCampaignModalIsOpen: false,
			MembershipTypeEditLogModalIsOpen: false,
			seasons: [],
			membershipTypes: [],
			loading: true,
			membershipLoading: true,
			campaigns: [],
          campaignsLoading: true,
          joinRenewEnabled: props.join_and_renew_enabled,
					clubChallengesEnabled: props.club_challenges_enabled
        };

        this.openAddMembershipTypeModal = this.openAddMembershipTypeModal.bind(this);
        this.openEditRevisionScheduleModalModal = this.openEditRevisionScheduleModalModal.bind(this);
		this.deleteMembership = this.deleteMembership.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateClubChallenges = this.updateClubChallenges.bind(this);

	}

    openAddMembershipTypeModal() {
		this.setState({AddMembershipTypeModalIsOpen: true});
    }

    openEditRevisionScheduleModalModal() {
		this.setState({EditMembershipTypeModalIsOpen: true});
	}

    closeModal(data) {
		if(data) this.getMembershipTypes();
		this.setState({
            AddMembershipTypeModalIsOpen: false,
            EditMembershipTypeModalIsOpen: false,
			EditEmailCampaignModalIsOpen: false
        });
	}

	componentDidMount() {
    	this.getSeasonDates();
		this.getMembershipTypes();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.association !== this.props.association) {
			this.getSeasonDates();
		}
	}

	getSeasonDates(){
		AssociationService.getPrimary(this.props.associationId)
			.then(response => {
				if (response && response.association) {
					AssociationService.getSeasons(response.association.course_rating_id)
						.then(res => {
								this.setState({
									loading: false,
									campaigns: [{...response.email_campaign_management,
										...{
										email_campaign: 'Newsletter',
										description: 'On the scheduled date, all active golfers within the association with an email address will receive an email once the handicap index calculations have updated. This occurs shortly after 12:00 midnight local time. Please ensure your content is ready by 11:45 PM local time.',
										subscribed: this.props.association.newsletter_enabled ? 'Yes': 'No',
                    date_range: response.email_campaign_management.newsletter_all_year ? 'All Year' : `${response.email_campaign_management.newsletter_start_date.replace('-', '/')} - ${response.email_campaign_management.newsletter_end_date.replace('-', '/')}`
									}}],
									campaignsLoading: false,
									seasons: res.Season ? res.Season.map((i, key) => {
										return {
											Id: key,
											SeasonName: i.SeasonName,
											SeasonStartDate: i.IsAllYear ? '01/01' : i.SeasonStartDate,
											SeasonEndDate:  i.IsAllYear ? '12/31' :i.SeasonEndDate
										}
									}) : []
								});
						})
						.catch(err => {
							console.error(err)
							this.setState({
								loading: false
							});
						})
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	getMembershipTypes(){
		this.setState({membershipLoading: true});
    	AssociationService.getMembershipTypes(this.props.associationId)
			.then(res => {
				this.setState({membershipLoading: false, membershipTypes: res.membership_types});
			})
			.catch(err => {
				this.setState({membershipLoading: false, membershipTypes: []});
			})
	}

	deleteMembership() {
		AssociationService.deleteMembershipType(this.props.associationId, this.state.deleteMembership && this.state.deleteMembership.id)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message:'Membership type successfully deleted'
				});
				this.setState({confirmationModalIsOpen: false, deleteMembership: null}, () => this.getMembershipTypes())
			})
			.catch(err => {console.error(err)});
	}


	changeSubscription(data){
    	AssociationService.editCampaignSettings(this.props.associationId, data).then(res =>{this.props.updateAssociation();})
		// return AssociationService.editPrimary(this.props.associationId, {newsletter_enabled: data})
		// 	.then(res =>{this.props.updateAssociation();})

	}

  // Receives an integer representing the number of months
  // Returs 'year', 'x years', 'month', 'x months'
  numberOfMonthsToString(numberMonths) {
    if (numberMonths === 12)
      return 'year';
    if (numberMonths === 1)
      return 'month';

    let isYear = (numberMonths % 12) === 0;
    let yearNumber = numberMonths / 12;

    if (isYear)
      return yearNumber + ' years';

    return numberMonths + ' months';
  }

  // data: hashmap
  // month_day: integer
  // x_months: integer
  monthlyDayNumber(data) {
    let day = CalendarService.ordinal_suffix_of(data['month_day']);
    let months = this.numberOfMonthsToString(data['x_months']);

    return day + ' day of every ' + months;
  }

  // data: hashmap
  // week_day: integer
  // week_day_order: integer
  // x_months: integer
  monthlyDayOrder(data) {
    let order = CalendarService.ordinal_suffix_of(data['week_day_order']);
    let day_number = data['week_day'];
    let day_name = CalendarService.dayIndexToDayName(day_number);
    let months = this.numberOfMonthsToString(data['x_months']);

    return order + ' ' + day_name + ' of every ' + months;
  }

  // data: hashmap
  // x_weeks: integer
  // week_day: integer from 1 to 7 inclusive
  weeklyDay(data) {
    let weeks_number = data['x_weeks'];
    let day_names = data['week_day'].map (index => CalendarService.dayIndexToDayName(index));
    let days = day_names.join(', ');

    return 'Recurs every ' + weeks_number + ' week(s) on: ' + days;
  }

  // data: hashmap
  // keys: month_day, week_day, week_day_order, x_months, x_weeks
  computeScheduleMessage(data) {
    let month_day = data['month_day'];
    let week_day = data['week_day'];
    let week_day_order = data['week_day_order'];
    let x_months = data['x_months'];
    let x_weeks = data['x_weeks'];

    if(week_day == null &&
      week_day_order == null &&
      x_months == null &&
      x_weeks == null) {

      return '1st & 15th of each month';
    }

    if (month_day && x_months) {
      return this.monthlyDayNumber(data);
    }

    // week_day could be zero here
    if (week_day != null && week_day_order && x_months) {
      return this.monthlyDayOrder(data);
    }

    return this.weeklyDay(data);
  }

  getPaymentGatewaySettings() {
    const update_join_renew = this.props.update_join_renew
    JoinAndRenewServices.getSettings(this.props.associationId).then(res => {
      this.setState({
        joinRenewEnabled: res.settings.join_and_renew_enabled
      })

      update_join_renew(res)
    })
  }

	updateClubChallenges(status) {
		this.setState({ clubChallengesEnabled: status })
		this.props.update_club_challenges(status);
	}

	render() {
		let columns = [
			{
				Header: 'Season Name',
				accessor: 'SeasonName',
				colClassName: 'col_user-name'
			},
			{
				Header: 'Begin Date',
				accessor: 'SeasonStartDate',
			},
			{
				Header: 'End Date',
				accessor: 'SeasonEndDate',
			},
		];

		let membershipColumns = [
			{
				Header: 'Membership Code',
				accessor: 'code',
				colClassName: 'col_membership-code',
				Cell: (data) => {
					return (
						<div className="blue_ribbon ribbon_hover">
							{data.original.code}
							{data.original.edited &&
								<i className="material-icons-outlined blue"
									 onClick={() => {
										 this.setState({MembershipTypeEditLogModalIsOpen: true, editMembership: data.original})
									 }}>
									bookmark
								</i>
							}
						</div>
					)
				}
			},
			{
				Header: <Fragment>
					Is Locked?
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='offer_type'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
						<div style={{padding: '10px 0px'}}>
							<p><strong>Locked Membership Code:</strong> When a membership code is locked it means that any
								golfers assigned to it cannot be reassigned to another Membership Type. A locked code is
								denoted with the light blue lock icon. If you have questions, please contact your association.</p>
						</div>
					</ReactTooltip>
				</Fragment>,
				accessor: 'locked',
				colClassName: 'col_is_locked',
				Cell: (data) => {
					return (
						<div style={{display: "flex", justifyContent: "center"}}>
							{data.original.locked &&
								<i className="material-icons-outlined lock-icon-container"></i>
							}
						</div>
					)
				}
			},
			{
				Header: 'Member Description',
				accessor: 'description',
			},
			{
				Header: 'USGA Membership Type',
				accessor: 'type'
			},
			{
				Header: 'Bill Rate',
				accessor: 'bill_rate',
				colClassName: 'col_bill_rate',
				Cell: props => {
					const {amount, isProrated} = getProratedAmount(props.original.bill_rate, props.original.proratings);
					return (
						<div className="blue_ribbon ribbon_hover" style={{color: isProrated ? "#0089ce" : ""}}>
							{amount}
							{isProrated &&
								<i className="material-icons-outlined scissors-icon-container prorated-amount"></i>
							}
						</div>
					)
				}
			},
			{
				Header: 'Status',
				accessor: 'active',
				colClassName: 'col_bill_rate',
				Cell: props => props.original.active === "true" ? 'Active' : 'Inactive'
			},
			{
				Header: '',
				sortable: false,
				colClassName: 'col_buttons',
				show: this.props.canEditAssociation,
				Cell: props => ( <span className='col_edit-delete'>
						<button className={'btn lnk'} onClick={() => {this.setState({EditMembershipTypeModalIsOpen: true, editMembership: props.original})}}>Edit</button>
						{!props.original.is_used ? <button className={'btn lnk'} onClick={() => {this.setState({confirmationModalIsOpen: true, deleteMembership: props.original})}}>Delete</button> : null}
					</span>)
			}
		];

		const emailCampaignColumns = [
			{
				Header: 'Email Campaign',
				accessor: 'email_campaign',
				colClassName: 'col_golfer-name'
			},
			{
				Header: 'Description',
				accessor: 'description',
				className: 'col_wrap',
				colClassName: 'col_description'
			},
			{
				Header: 'Subscribed?',
				accessor: 'subscribed',
				className: 'table-align-center',
				thClassName: 'table-align-center is-small',
				colClassName: 'col_method-small'
			},
      {
        Header: 'Date Range',
        accessor: 'date_range',
        className: 'table-align-center',
        thClassName: 'table-align-center is-small'
      },
			{
        id: 'schedule_time',
				Header: 'Schedule',
				accessor: d => this.computeScheduleMessage(d),
				className: 'table-align-center',
				thClassName: 'table-align-center is-small',
				colClassName: 'col_email-address'
			},
			{
				Header: '',
				accessor: 'Edit',
				colClassName: 'col_edit',
				show: this.props.canEditAssociation,
				Cell: props => <button className="btn lnk" onClick={() => this.setState({EditEmailCampaignModalIsOpen: true, selectedCampaign: props.original})}>Edit</button>,
			},
		];

		return (

            <Fragment>

                <div className="columns">
                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Season Dates</div>
                                <div className="panel__body">
                                    <div className="row">
                                        <div className="col is-1-of-2 centered">
                                            <p className="align-center">Note: Season dates are managed within the Course Rating Application.</p>
                                        </div>
                                    </div>

									<div className="row">
										<div className="col is-1-of-2 centered">
											<GhinTable
												idKey={'id'}
												hidePagination={true}
												columns={columns}
												loading={this.state.loading}
												hideSelect={true}
												data={this.state.seasons}
												ref={(r) => this.table = r}
												defaultSorted={[{
													id: 'SeasonName',
													desc: false
												}]}
												sortable={true}
											/>
										</div>
									</div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="panel">
                          <div className="panel__head">Association Membership Types</div>
                          <div className="panel__body">
                            {/* #559 for now only super admin*/}
                            {this.props.canEditAssociation && <div className="row" style={{marginBottom: "0"}}>
                              <div className="col is-5-of-6" style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
                                <p><strong>Note:</strong> Membership Types can only be deleted from an association if there are no golfers (regardless of status) currently assigned to it.</p>
                              </div>
                              <div className="col is-1-of-6 push-right">
                                <button onClick={() => this.openAddMembershipTypeModal()}
                                  className="btn fill green">Add Membership Type
                                </button>
                              </div>
                            </div>}
														<div className="row">
															<div className="col auto push-right">
																<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text">
																	<i className="material-icons-outlined blue">bookmark</i>
																	<p>Modified Membership Type</p>
																</div>
															</div>
															<div className="col auto" style={{paddingLeft: 0}}>
																<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text">
																	<i className="material-icons-outlined lock-icon-container"></i>
																	<p>Membership Code is Locked</p>
																</div>
															</div>
															<div className="col auto" style={{paddingLeft: 0}}>
																<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text">
																	<i className="material-icons-outlined scissors-icon-container"></i>
																	<p>Prorated Rate</p>
																</div>
															</div>
														</div>
                                    <div className="row">
                                        <div className="col is-full">
											<GhinTable
												idKey={'id'}
												hidePagination={true}
												columns={membershipColumns}
												defaultPageSize={50}
												loading={this.state.membershipLoading}
												hideSelect={true}
												data={this.state.membershipTypes}
												ref={(r) => this.table = r}
												defaultSorted={[{
													id: 'code',
													desc: false
												}]}
												sortable={true}
											/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Email Campaign Management</div>
								<div className="panel__body">
									<div className={"row"}>
										<div className={"col"}>
											<p>Please contact GHIN Support to opt in or out of the following email services.</p>
										</div>
									</div>
									<div className="row">
										<div className="col is-full">
											<GhinTable
												idKey={'id'}
												hidePagination={true}
												columns={emailCampaignColumns}
												loading={this.state.campaignLoading}
												hideSelect={true}
												data={this.state.campaigns}
												ref={(r) => this.table = r}
												defaultSorted={[{
													id: 'code',
													desc: false
												}]}
												sortable={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
          </div>
          <div className="row">
            <div className="col">
              <div className="panel">
                <div className="panel__head">Join & Renew Setup</div>
                <div className="panel__body">
                  <div className="row">
                    <div className="col is-4-of-10">
                      <div>
                        <strong>Join & Renew Enabled?:</strong> <div style={{ display: "inline", margin: "5px" }}>{` ${this.state.joinRenewEnabled ? "Yes" : "No"} `}</div>
                        {
                          this.props.super_user && this.props.association.status === 'Active' &&
                          <button onClick={() => this.setState({ editJoinRenewModalIsOpen: true })} className="btn lnk">Edit</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="panel">
                <div className="panel__head">Challenges Setup</div>
                <div className="panel__body">
                  <div className="row">
                    <div className="col is-4-of-10">
                      <div>
                        <strong>Challenges Enabled?:</strong> <div style={{ display: "inline", margin: "5px" }}>{` ${this.state.clubChallengesEnabled ? "Yes" : "No"} `}</div>
                        {
                          this.props.super_user && this.props.association.status === 'Active' &&
                          <button onClick={() => this.setState({ editClubChallengesModalIsOpen: true })} className="btn lnk">Edit</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>

                <Modal
						isOpen={this.state.AddMembershipTypeModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <AddMembershipTypeModal associationId={this.props.associationId} joinRenewEnabled={this.state.joinRenewEnabled} closeModal={(data)=>{this.closeModal(data)}} />
                </Modal>

                <Modal
						isOpen={this.state.EditMembershipTypeModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <EditMembershipTypeModal associationId={this.props.associationId} membership={this.state.editMembership} joinRenewEnabled={this.state.joinRenewEnabled} closeModal={(data)=>{this.closeModal(data)}} />
                </Modal>

				<Modal
					isOpen={this.state.EditEmailCampaignModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditEmailCampaignModal updateAssociation = {() => this.props.updateAssociation()} associationId={this.props.associationId} subscription={this.state.campaigns[0]} closeModal={(data)=>{this.closeModal(data)}} />
        </Modal>

        <Modal
          isOpen={this.state.editJoinRenewModalIsOpen}
          onRequestClose={() => { this.setState({ editJoinRenewModalIsOpen: false }) }}
          contentLabel="Modal"
          portalClassName="modal"
          overlayClassName="modal__overlay"
          className="modal__content"
          bodyOpenClassName="modal--is-open"
          htmlOpenClassName="prevent-scroll"
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={false}
        >
          <EditJoinAndRenewEnabledModal
            closeModal={() => { this.setState({ editJoinRenewModalIsOpen: false }) }}
            reload_payment_settings={() => this.getPaymentGatewaySettings()}
            enable_join_and_renew={this.state.joinRenewEnabled}
            association_id={this.props.associationId}
          />
        </Modal>

				<Modal
					isOpen={this.state.editClubChallengesModalIsOpen}
					onRequestClose={() => { this.setState({ editClubChallengesModalIsOpen: false }) }}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditClubChallengesEnabledModal
						closeModal={() => { this.setState({ editClubChallengesModalIsOpen: false }) }}
						updateClubChallenges={this.updateClubChallenges}
						enable_club_challenges={this.state.clubChallengesEnabled}
						association_id={this.props.associationId}
					/>
				</Modal>

				<Modal
					isOpen={this.state.MembershipTypeEditLogModalIsOpen}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
				>
					<MembershipTypeEditLogModal
						closeModal={() => {
							this.setState({MembershipTypeEditLogModalIsOpen: false})
						}}
						selectedMembership={this.state.editMembership}
						association_id={this.props.associationId}
					/>
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to delete Membership Type:${this.state.deleteMembership && this.state.deleteMembership.code}?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmationModalIsOpen: false})}
					onConfirmAction={()=>this.deleteMembership()}
					openModal={this.state.confirmationModalIsOpen}
					closeModal={() => this.setState({confirmationModalIsOpen: false})}
				/>

            </Fragment>
		);
	}
}
function mamStateToProps(state){
	return {
		super_user: state.user.access.super_user
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}

export default connect(mamStateToProps, mapDispatchToProps)(AccountSetupAssoc);
