import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { addAlert } from '../shared/actions';
import { Field, reduxForm } from "redux-form";
import ChallengesService from "../../services/api/challenges";

function EditClubChallengesEnabledFormFunction(props) {
  const [enable_club_challenges, set_enable_club_challenges] = useState(props.enable_club_challenges)

  const { handleSubmit, pristine, submitting } = props;

  const onSubmit = (values, dispatch, props) => {
    const data = {
      club_challenges_enabled: enable_club_challenges,
      association_id: props.association_id,
    }
    return ChallengesService.enableChallenges(data).then(() => {
      props.updateClubChallenges(data.club_challenges_enabled);
      props.addAlert({ type: 'success', message: `Challenges successfully ${data.club_challenges_enabled ? 'enabled' : 'disabled'}` })
      props.closeModal();
    })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col is-1-of-2">
          <span className="label">Enable Challenges?</span>
          <div className="radio__container">

            <Field
              onClick={
                (data) => {
                  set_enable_club_challenges(data.target.value === "true")
                }
              }
              checked={enable_club_challenges}
              id="enable-join-yes"
              value={true}
              name='enable_club_challenges'
              component='input'
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="enable-join-yes">Yes</label>

            <Field
              onClick={
                (data) => {
                  set_enable_club_challenges(data.target.value === "true");
                }
              }
              checked={!enable_club_challenges}
              id="enable-join-no"
              value={false}
              name='enable_club_challenges'
              component='input'
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="enable-join-no">No</label>

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <button type='button' className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type='submit' className="btn fill blue" disabled={pristine || submitting}>Save</button>
        </div>
      </div>
    </form>
  )
}

const EditClubChallengesEnabledForm = reduxForm({
  form: 'editClubChallengesEnabledForm',
  destroyOnUnmount: true
})(EditClubChallengesEnabledFormFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditClubChallengesEnabledForm));
