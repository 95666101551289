import request from "../shared/request"

export default class JoinAndRenewServices {
  static getSettings(id) {
    return request({
      url: `/associations/${id}/join_and_renew_settings.json`,
      method: "GET"
    })
  }

  static updateSettings(id, params) {
    return request({
      url: `/associations/${id}/join_and_renew_settings.json`,
      method: "PATCH",
      data: params
    })
  }

  static getJoinPages(id, params) {
    return request({
      url: `/associations/${id}/join_pages.json`,
      method: "GET",
      params
    })
  }

  static getClubJoinPages(id) {
    return request({
      url: `/clubs/${id}/join_pages.json`,
      method: "GET"
    })
  }

  static getClubJoinPageCustomization(club_id, id) {
    return request({
      url: `/clubs/${club_id}/join_pages/${id}/customization.json`,
      method: "GET"
    })
  }

  static updateClubJoinPageCustomization(club_id, id, data) {
    return request({
      url: `/clubs/${club_id}/join_pages/${id}/customization.json`,
      method: "PATCH",
      data
    })
  }

  static getJoinPage(golf_association_id, id) {
    return request({
      url: `/associations/${golf_association_id}/join_pages/${id}.json`,
      method: "GET"
    })
  }

  static createJoinPage(id, data) {
    return request({
      url: `/associations/${id}/join_pages.json`,
      method: "POST",
      data
    })
  }

  static updateJoinPage(association_id, id, data) {
    return request({
      url: `/associations/${association_id}/join_pages/${id}.json`,
      method: "PATCH",
      data
    })
  }

  static deleteJoinPage(association_id, id) {
    return request({
      url: `/associations/${association_id}/join_pages/${id}.json`,
      method: "DELETE",
    })
  }

  static JoinPageAssignAddons(association_id, id, addon_ids) {
    return request({
      url: `/associations/${association_id}/join_pages/${id}/add_on_charges.json`,
      method: "POST",
      data: {
        addon_charge_ids: addon_ids
      }
    })
  }

  static exportPage(association_id, id) {
    return request({
      url: `/associations/${association_id}/join_pages/${id}/export_to_excel.json`,
      method: "GET",
    })
  }

  static getAddonCharges(association_id, params = {}) {
    return request({
      url: `/associations/${association_id}/addon_charges.json`,
      method: "GET",
      params
    })
  }

  static updateAddonCharge(association_id, addon_id, params) {
    return request({
      url: `/associations/${association_id}/addon_charges/${addon_id}.json`,
      method: "PATCH",
      data: params
    })
  }

  static createAddonCharge(association_id, params) {
    return request({
      url: `/associations/${association_id}/addon_charges.json`,
      method: "POST",
      data: params
    })
  }

  static deleteAddonCharge(association_id, addon_id) {
    return request({
      url: `/associations/${association_id}/addon_charges/${addon_id}.json`,
      method: "DELETE"
    })
  }

  static getCustomizedEmails(association_id) {
    return request({
      url: `/associations/${association_id}/join_renew_customized_emails.json`,
      method: 'GET'
    });
  }

  static updateCustomizedEmail(association_id, customized_email_id, data) {
    return request({
      url: `/associations/${association_id}/join_renew_customized_emails/${customized_email_id}.json`,
      method: 'PATCH',
      data
    })
  }

  static getSentEmails(association_id, params) {
    return request({
      url: `/associations/${association_id}/join_renew_sent_emails.json`,
      method: 'GET',
      params
    });
  }

  static resendEmail(association_id, id) {
    return request({
      url: `/associations/${association_id}/join_renew_sent_emails/${id}/resend_email.json`,
      method: 'POST'
    })
  }

  static getTransactions(association_id, params) {
    return request({
      url: `/transactions.json`,
      method: 'GET',
      params: { ...params, association_id: association_id}
    });
  }

  static exportTransactions(association_id, params) {
    return request({
      url: `/transactions/export_to_excel.json?`,
      method: 'GET',
      params: { ...params, association_id: association_id}
    });
  }

  static refundTransaction(transaction_id, params) {
    return request({
      url: `transactions/${transaction_id}/refund.json`,
      method: 'POST',
      data: params
    });
  }

  static getAssociationTerms(id) {
	return request({
	  url: `/associations/${id}/golf_association_terms.json`,
	  method: 'GET'
	})
  }

  static updateAssociationTerms(association_id, id, params) {
  	return request({
	  url: `/associations/${association_id}/golf_association_terms/${id}.json`,
	  method: 'PATCH',
	  data: params
	})
  }

  static createAssociationTerms(association_id, params) {
  	return request({
	  url: `/associations/${association_id}/golf_association_terms.json`,
	  method: 'POST',
	  data: params
	})
  }

  static removeAssociationTerms(association_id, id) {
    return request({
	  url: `/associations/${association_id}/golf_association_terms/${id}.json`,
	  method: 'DELETE',
	})
  }

  static getAssociationClubMembershipFees(association_id, params) {
    return request({
	  url: `/associations/${association_id}/club_memberships_fees.json`,
	    method: 'GET',
	    params
    })
  }

  static assignClubMembershipsToJoinPage(association_id, id, membership_ids) {
	  return request({
	  url: `/associations/${association_id}/join_pages/${id}/clubs_memberships.json`,
	  method: 'POST',
	  data: {
		  club_membership_ids: membership_ids
	  }
	})
  }

  static getJoinClubSettings(club_id) {
    return request({
      url: `/clubs/${club_id}/join_club_settings.json`,
      method: 'GET',
    })
  }

  static updateJoinClubSettings(club_id, data) {
    return request({
      url: `/clubs/${club_id}/join_club_settings.json`,
      method: 'PATCH',
      data
    })
  }

  static getPendingMembers(club_id, params) {
    return request({
      url: `/clubs/${club_id}/pending_golfers.json`,
      method: 'GET',
      params
    })
  }

  static approveMember(club_id, id) {
    return request({
      url: `/clubs/${club_id}/pending_golfers/${id}/approve.json`,
      method: 'POST'
    })
  }

  static rejectMember(club_id, id, data) {
    return request({
      url: `/clubs/${club_id}/pending_golfers/${id}/reject.json`,
      method: 'POST',
      data
    })
  }

  static exportPendingMembersToExcel(club_id, params) {
    return request({
      url: `/clubs/${club_id}/pending_golfers/export_to_excel.json`,
      params
    })
  }

  static getClubZipCode(club_id, params) {
    return request({
      url: `/clubs/${club_id}/zip_codes.json`,
      method: 'GET',
      params
    })
  }

  static addClubZipCode(club_id, data) {
    return request({
      url: `/clubs/${club_id}/zip_codes.json`,
      method: 'POST',
      data
    })
  }

 //Bulk Upload
  static uploadFile(club_id, data) {
    return request({
      url: `/clubs/${club_id}/zip_codes/bulk_upload.json`,
      method: 'POST',
      data
    })
  }

  static removeClubZipCodes(club_id, params) {
    return request({
      url: `/clubs/${club_id}/zip_codes.json`,
      method: 'DELETE',
      params
    })
  }
  
  static createPromotionalCode(association_id, data) {
    return request({
      url: `/associations/${association_id}/promotional_codes.json`,
      method: "POST",
      data
    })
  }

  static updatePromotionalCode(association_id, id, data) {
    return request({
      url: `/associations/${association_id}/promotional_codes/${id}.json`,
      method: "PATCH",
      data
    })
  }

  static getPromotionalCodes(association_id, params) {
    return request({
      url: `/associations/${association_id}/promotional_codes.json`,
      method: 'GET',
      params
    });
  }

  static deletePromotionalCode(association_id, id) {
    return request({
      url: `/associations/${association_id}/promotional_codes/${id}.json`,
      method: 'DELETE'
    });
  }

  static getPromotionalCodeAppliedClubs(association_id, id, params) {
    return request({
      url: `/associations/${association_id}/promotional_codes/${id}/applied_clubs.json`,
      method: 'GET',
      params
    });
  }

  static getArchivedPromotionalCodes(association_id, id) {
    return request({
      url: `/associations/${association_id}/promotional_codes/${id}/archived.json`,
      method: 'GET',
    });
  }

  static exportPromotionalCode(association_id, id) {
    return request({
      url: `/associations/${association_id}/promotional_codes/${id}/export_to_csv.json`,
      method: 'GET',
    });
  }

  static getPromotionalCodesUsedByGolfer(club_id, golfer_id, params) {
    return request({
      url: `/clubs/${club_id}/golfers/${golfer_id}/promotional_codes.json`,
      method: 'GET',
      params
    });
  }

  static exportAddOns(association_id, id, download = false) {
    return request({
      url: `/associations/${association_id}/addon_charges/${id}/export_to_csv.json`,
      method: 'GET',
      params: {download: download}
    });
  }
}
