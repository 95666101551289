import React, {Fragment, useEffect, useState} from "react";
import GhinTable from "../../../shared/GhinTable";
import ClubService from "../../../../services/api/club";

export default function ClubMembershipFees(props) {
  const [clubMembershipFees, setClubMembershipFees] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      ClubService.getMembershipFees(props.club.id).then((res) => {
        if (cancel) return;
        setClubMembershipFees(res.club_membership_fees);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    fetchData();

    return () => {
      cancel = true;
    }
  }, [props.club.id])

  const formatGender = (gender) => {
    if (gender === "male") return "Male";
    else if (gender === "female") return "Female";
    else return "Male & Female";
  };

  const formatAge = (minimumAge, maximumAge) => {
    if (minimumAge && maximumAge) return `${minimumAge} to ${maximumAge}`;
    else if (!maximumAge && minimumAge)
      return `Age ${minimumAge} and older`;
    else if (!minimumAge && maximumAge)
      return `No older than ${maximumAge}`;
    else return "No age restrictions";
  };

  const calculateTotalAmount = (fee) => {
    const isCmfProrated = !!fee.prorating;
    let cmfAmount = fee.amount;
    if (isCmfProrated)
      cmfAmount = fee.prorating.amount;

    const isProratedType = !!fee.golf_association_membership_prorating;
    let typeAmount = fee.golf_association_membership_amount;
    if (isProratedType)
      typeAmount = fee.golf_association_membership_prorating.amount;

    return parseFloat(typeAmount || 0.0) +
      parseFloat(fee.golf_association_fee_amount || 0.0) +
      parseFloat(cmfAmount || 0.0)
  };

  const columns = [
    {
      Header: "Membership Fee Name",
      colClassName: "col_fee_name",
      accessor: "name",
    },
    {
      Header: "Description",
      colClassName: "col_fee_description",
      accessor: "description",
    },
    {
      Header: "Club Fee",
      colClassName: "col_fee_amount",
      Cell: (data) => {
        const isProrated = !!data.original.prorating;
        let fee_amount = data.original.amount;
        if (isProrated)
          fee_amount = data.original.prorating.amount;
        return <>
          <span>
            <div style={{color: isProrated ? "#0089ce" : ""}}>${parseFloat(fee_amount).toFixed(2)}</div>
            <div className="blue_ribbon ribbon_hover">
              {isProrated &&
                <i className="material-icons-outlined scissors-icon-container prorated-amount"></i>
              }
            </div>
          </span>
          </>;
      }
    },
    {
      Header: "Eligibility Requirements",
      colClassName: "col_eligibility",
      Cell: (data) => {
        return (
          <ul>
            <li>Gender:{" "}{formatGender(data.original.gender)}</li>
            <li>Age:{" "}{formatAge(data.original.minimum_age, data.original.maximum_age)}</li>
          </ul>
        );
      }
    },
    {
      Header: "Association Membership Code",
      Cell: (data) => {
        const isProrated = !!data.original.golf_association_membership_prorating;
        let membership_type_amount = data.original.golf_association_membership_amount;
        if (isProrated)
          membership_type_amount = data.original.golf_association_membership_prorating.amount;
        return (
          <ul>
            <li>{data.original.golf_association_membership_name} -
              &nbsp;
              <span style={{color: isProrated ? "#0089ce" : ""}}>
                ${parseFloat(membership_type_amount).toFixed(2)}
                <span>
                  <div className="blue_ribbon ribbon_hover">
                    {isProrated &&
                      <i className="material-icons-outlined scissors-icon-container prorated-amount"></i>
                    }
                  </div>
                </span>
              </span>
            </li>
            <li>{data.original.golf_association_fee_name ? `${data.original.golf_association_fee_name} - $${parseFloat(data.original.golf_association_fee_amount).toFixed(2)}` : null}</li>
          </ul>
        );
      }
    },
    {
      Header: "Total Fee",
      colClassName: "col_fee_amount",
      Cell: (data) => {
        return <center> ${calculateTotalAmount(data.original).toFixed(2)} </center>;
      }
    }
  ];

  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">
          Club Membership Fees
          { !props.canEditAssociation && <span className="red-note">Please contact your association to change these settings.</span> }
        </div>
        <div className='panel__body'>
          <div className="row" style={{marginBottom: 0}}>
            <div className="col">
              <p>
                The table below shows available Membership Fees for GHIN Join & Renew. The Club Fee indicates the amount
                paid to the Club, the Association Membership Code and Online Transaction Fee shows the amount paid to
                the Association, and the Total Fee is the amount a golfer will pay to join online.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col auto push-right">
              <div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text" style={{marginTop: 0}}>
                <i className="material-icons-outlined scissors-icon-container"></i>
                <p>Prorated Fee</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col regular_font">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={clubMembershipFees}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}