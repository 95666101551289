import React, { Component, Fragment } from "react";
import GolferTableFilter from "./GolferTableFilter";
import GhinTable from "../../../shared/GhinTable";
import { connect } from "react-redux";
import {NavLink} from 'react-router-dom';
import moment from "moment";
import { useEffect } from 'react';
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import ReactTooltip from "react-tooltip";
import ChallengesService from "../../../../services/api/challenges";

import Modal from "react-modal";

class GolferTable extends Component {

constructor() {
  super();
  
		this.state = {
			golfers: [],
			tableFilterIsHidden: false,
			loading: false,
			total: 0,
			per_page: 20,
			page: 0,
			filtered: [],
			sorted: [{ id: "created_at", desc: true }],
		};
	}; 

	toggleTableFilter() {
		this.setState({
			tableFilterIsHidden: !this.state.tableFilterIsHidden,
		});
	}

	updateFilter(filtered) {
		this.setState(
			{
				filtered,
				page: 0,
			},
			() => {
				this.loadGolfers();
			}
		);
	}

	localDateTime(datetime) {
		datetime = new Date(datetime);
		let hours = datetime.getHours();
		datetime.setUTCHours(hours);
		return datetime;
	}

	loadGolfers() {
		const params = this.state.filtered;
		params['sorting_criteria'] = this.state.sorted[0].id;
		params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		params['per_page'] = this.state.per_page;
		params['page'] = this.state.page + 1;
		this.setState({ loading: true });
    if (this.props.challengeId)
      ChallengesService.getGolfers(
        this.props.challengeId,
				params
      )
        .then((res) => {
          this.setState({
            golfers: res.golfers,
            total: res.total,
            pages: res.pages,
            loading: false,
          });
        })
        .catch((err) => {
          console.error(err);
					this.setState({ loading: false });
        });
	}
	componentDidMount() {
		this.refreshTable();
	}

	componentDidUpdate() {
		this.refreshTable();
	}

	refreshTable() {
		if (this.props.tableNeedsRefresh === true) {
			this.loadGolfers();
			this.props.setTableRefresh(false);
		}
	}

	render() {
		const canShow = this.props.canEditAssociation;
		let su = this.props.user_type === "super-admin"
		const columns = [
			{
				Header: '#',
				accessor: 'id',
				colClassName: 'col_id',
				show: false
			},
			{
				Header: "Golfer Name",
				accessor: "golfer_name",
				thClassName: "is-3-of-9",
        Cell: props =>
        <Fragment>
          <NavLink to={`../../../../../${this.props.associationId || 2}/club/${props.original.primary_club}/golfer/${props.row.golfer_id}`}>{props.row.golfer_name}</NavLink>
        </Fragment>,
				sortable: true,
			},
			{
				Header: "H.I.",
				accessor: "hi_display",
				thClassName: "is-2-of-9",
				sortable: true,
			},
			{
				Header: "GHIN #",
				accessor: "golfer_id",
				thClassName: "is-2-of-10",
				sortable: true,
			},
			{
				Header: "Rounds Posted to Challenge",
				accessor: "rounds_posted",
				thClassName: "is-4-of-10",
				sortable: false,
			},
			{
				Header: "Status",
				accessor: "status",
				thClassName: "is-3-of-9",
				sortable: false,
			},
			{
				Header: "",
				accessor: "buttons",
				thClassName: "is-3-of-9",
        Cell: (props) => ( 
					this.props.challengeStatus === 'deleted' ||
						<span className="col_edit-delete-challenges">
							<button
								className={"btn lnk"}
								onClick={() => {
									this.props.openRemoveGolferModal({golfer_id: props.original.golfer_id, golfer_name: props.original.golfer_name});
								}}
							>
								Remove
							</button>
							<button
								className={"challenge-scores-button btn lnk "}
								onClick={() => {
									this.props.openViewScoreModal({
										golfer_id: props.original.golfer_id,
										golfer_name: props.original.golfer_name,
										primary_club: props.original.primary_club,
										association_id: this.props.associationId,
									});
								}}
							>
								View Scores
							</button>
						</span>
					),
				sortable: false,
			},
		];

		return (
			<Fragment>
				<GolferTableFilter
					updateFiltered={this.updateFilter.bind(this)}
					hideFilters={this.state.tableFilterIsHidden}
				/>
				<div className="pre__table">
					<div className="row">

						<div className="col auto push-right">
							<div className="row">
							</div>
						</div>
					</div>
				</div>
				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					pageSize={this.state.per_page}
					onPageChange={(pageIndex) =>
						this.setState({ page: pageIndex }, () =>
							this.loadGolfers()
						)
					}
					onPageSizeChange={(pageSize, pageIndex) =>
						this.setState(
							{
								per_page: pageSize,
								page: pageIndex,
							},
							() => this.loadGolfers()
						)
					}
					data={this.state.golfers}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState(
							{
								sorted: newSorted,
								page: 0,
							},
							() => {
								this.loadGolfers();
							}
						);
					}}
					manual
					hideSelect
				/>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		access: state.user.access,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GolferTable);
