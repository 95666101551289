import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import {isBeforeDate, isCustomNumeric, isNumeric, lessThan, maxLength, required} from "../../inputs/validations";
import AssociationService from "../../services/api/association";
import {addAlert} from '../shared/actions';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import ReactTooltip from "react-tooltip";
import {DatePickerInput} from "../../inputs/DatePicker";
import {processProratedFee} from "../shared/proratings";

const usgaMembershipType = [
    { value: 'Junior', label: 'Junior' },
    { value: 'Standard', label: 'Standard' },
]

class EditMembershipTypeForm extends Component {

	constructor(props) {
		super(props);
		this.state = {proratedFees: [], initialProratedFees: [], modifiedFields: {}, active: false, errors: [], proratingErrors: []};

		this.addProratedFee = this.addProratedFee.bind(this);
		this.updateProratedFee = this.updateProratedFee.bind(this);
		this.setDate = this.setDate.bind(this);
		this.deleteProratedFee = this.deleteProratedFee.bind(this);
		this.constructParams = this.constructParams.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		const { initializeData, proratedFees } = this.props.membership.proratings.reduce((acc, proratedFee) => {
			const { initializeFields, proratedFeeItem } = processProratedFee(proratedFee);
			acc.initializeData = { ...acc.initializeData, ...initializeFields};
			acc.proratedFees.push(proratedFeeItem);
			return acc;
		}, { initializeData: {}, proratedFees: []})

    	this.props.initialize(
    		{...this.props.membership,
				...{type: {
						label: this.props.membership.type,
						value: this.props.membership.type
					},
					bill_rate: this.props.membership.bill_rate,
					active: this.props.membership.active === "true" ? true : false,
					...initializeData,
    			}
    		});
		this.setState({
			active: this.props.membership.active === "true" ? true : false,
			proratedFees: proratedFees,
			initialProratedFees: JSON.parse(JSON.stringify(proratedFees)),
		});
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	addProratedFee() {
		this.setState((prevState) => ({
			proratedFees: [...prevState.proratedFees, {
				name: "", amount: null, start_date: "", end_date: "", recurring: false, key: Date.now()
			}],
		}));
	}

	updateProratedFee(index, fieldName, value) {
		const proratedFees = [...this.state.proratedFees];
		proratedFees[index][fieldName] = value;
		const key = proratedFees[index].key;
		const initialValue = this.state.initialProratedFees[index]?.[fieldName];

		if (initialValue === undefined)
			this.setState({proratedFees: proratedFees});
		else
			this.setState((prevState) => ({
				proratedFees,
				modifiedFields: {
					...prevState.modifiedFields,
					[key]: {
						...prevState.modifiedFields[key],
						[fieldName]: String(value) !== String(initialValue),
					},
				},
			}));
	}

	setDate(index, fieldName, value) {
		if (value) {
			const date = new Date(value)
			const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
			this.updateProratedFee(index, fieldName, dateString)
		} else
			this.updateProratedFee(index, fieldName, '')
	}

	deleteProratedFee(index) {
		const updatedProratedFees = this.state.proratedFees.filter((_, i) => i !== index);
		this.setState({proratedFees: updatedProratedFees}, () => {
			if (this.state.initialProratedFees[index]) {
				const dummyField = `prorated_fee_name_${this.state.initialProratedFees[index].key}`;
				const dummyValue = 'deletedProratedFee';
				// Force pristine to be false when deleting a pre-existent prorating
				this.props.dispatch(change('editMembershipTypeForm', dummyField, dummyValue));
			}
		});
	}

	isFieldModifiedOrNew(proratedFee, fieldName) {
		const isNew = !proratedFee.id;
		const isModified = this.state.modifiedFields[proratedFee.key]?.[fieldName] === true;
		return isNew || isModified;
	}

	constructParams(values) {
		const {code, description, bill_rate, type, locked, active} = values;

		let data = {code, description, bill_rate, type, locked, active};
		data.proratings = this.state.proratedFees.map(({id, name, amount, start_date, end_date, recurring}) => {
			const formattedStartDate = recurring ? start_date.split('-').slice(1).join('-') : start_date;
			const formattedEndDate = recurring ? end_date.split('-').slice(1).join('-') : end_date;
			return ({id, name, amount, start_date: formattedStartDate, end_date: formattedEndDate, recurring});
		});
		data = JSON.parse(JSON.stringify(data));
		data.type = data.type.value;
		data.active = data.active ? 'true' : 'false';

		return data;
	}

	onSubmit(values) {
		const data = this.constructParams(values);
		return AssociationService.editMembershipType(this.props.associationId, this.props.membership.id, data)
			.then(res => {
				this.props.addAlert({
					type:'success',
					errors: [],
					proratingErrors: [],
					message:'Membership type successfully edited'
				});
				this.props.closeModal('refresh')
			})
			.catch(err => {
				if (err.data && err.data.error) {
					this.setState({errors: [err.data.error]});
				} else if (err.data && err.data.errors) {
					let errors = [];
					let proratingErrors = [];

					Object.keys(err.data.errors).forEach(key => {
						if (key === "proratings") {
							proratingErrors.push(...err.data.errors[key]);
						} else {
							errors.push(...err.data.errors[key]);
						}
					})

					this.setState({errors: errors, proratingErrors: proratingErrors});
				}
				console.error(err)
			});
	}

	render() {
		const { handleSubmit, submitting, pristine, valid} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row">
						<div className="col is-1-of-4">
							<label htmlFor="code">Membership Code <span>*</span></label>
							<Field component={InputField} validate={[required, maxLength([2,'Code must contain max 2 characters'])]} type="text" name="code" id="code" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="description">Membership Description <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="description" id="description" />
						</div>
					</div>

					<div className="row">
						<div className="col is-5-of-7">
							<label htmlFor="type">USGA Membership Type <span>*</span></label>
							<Field validate={[required]}
								   component={SelectInput}
								   disabled={this.props.membership.is_used}
								   id='type'
								   name='type'
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={usgaMembershipType}
								   />
						</div>
						<div className="col is-2-of-7">
							<label htmlFor="bill_rate">Bill Rate <span>*</span></label>
							<Field component={InputField}
								   validate={[required, isCustomNumeric(8)]}
								   type="text"
								   name="bill_rate"
								   id="bill_rate" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-4 jc-fe">
							<Field component={'input'}
								   type="checkbox"
								   disabled={this.props.membership.is_used}
								   name="active"
								   id="active"
								   checked={this.state.active}
								   onClick={()=>{this.setState({active: !this.state.active})}} />
							<label htmlFor="active">Active</label>
						</div>
					</div>

					<div className="row" style={{marginTop: "-14px"}}>
						<div className="col" style={{flexDirection: "row"}}>
							<Field
								component={'input'}
								type="checkbox"
								name="locked"
								id="locked"
							/>
							<label style={{color: 'black'}} htmlFor="locked">Lock Membership Code</label>
							<a style={{alignSelf: "center", marginLeft: "3px"}} href="#donothing" onClick={(e) => {
								e.preventDefault()
							}}
								 data-tip data-for={`offer_type`}>
								<i className="material-icons v-mid" style={{color: "#0089CE", fontSize: "18px"}}>info</i>
							</a>
							<ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
								<div style={{padding: '10px 0px'}}>
									<p><strong>Locked Membership Code:</strong> When a membership code is locked it means that any
										golfers assigned to it cannot be reassigned to another Membership Type. A locked code is
										denoted with the light blue lock icon. If you have questions, please contact your association.</p>
								</div>
							</ReactTooltip>
						</div>
					</div>

					<div className="row" style={{marginBottom: 0, marginTop: "-6px"}}>
						<div className="col">
							<span className={"red"} style={{fontStyle: "italic"}}>NOTE: This fee is effective immediately in the association's local timezone.</span>
						</div>
					</div>

					<hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

					<div className="row">
						<div className="col">
							<p className="prorated_fees_title"><strong>Association Membership Type Proration</strong></p>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<p>If a member joins a club in the middle of the year, the association membership type rate can
								be prorated so that the member pays only for a portion of the year. Multiple proration periods can be
								established. Press "<strong>Add Association Membership Type Proration</strong>" to add a prorated rate.
								Check the "Recurring" checkbox to have the fees automatically applied during the specified timeframe
								from year-to-year.</p>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<p><strong>Important Notes:</strong></p>
							<p>
								<div style={{display: "flex", marginLeft: "9.5px"}}>
									<div><strong>•</strong>&ensp;</div>
									<div>Association Membership Type Proration will ONLY be applied to Join & Renew clubs using the Calendar Year membership cycle and clubs not using Join & Renew.</div>
								</div>
							</p>
							<p>
								<div style={{display: "flex", marginLeft: "9.5px"}}>
									<div><strong>•</strong>&ensp;</div>
									<div>Proration WILL NOT be applied to Join & Renew clubs using the Anniversary (365) membership cycle.</div>
								</div>
							</p>
							<p>
								<div style={{display: "flex", marginLeft: "9.5px"}}>
									<div><strong>•</strong>&ensp;</div>
									<div>When a prorated membership type exists, membership discounts created in GHIN Join & Renew WILL NOT be combined during online registration.</div>
								</div>
							</p>
							<p>
								<div style={{display: "flex", marginLeft: "9.5px"}}>
									<div><strong>•</strong>&ensp;</div>
									<div>If the dates for a prorated rate overlap the "Include Remainder Date" at a club, the prorated rates WILL NOT be applied.</div>
								</div>
							</p>
						</div>
					</div>

					<div className="prorated_fees">
						{
							this.state.proratedFees.map((fee, index) => (<div key={`wrapper-${index}`}>
									<div className="row prorated_fee" key={`proration-${index}`}>
										<div className="col">
											<div className="row">
												<div className="col is-4-of-7">
													<label htmlFor={`prorated_fee_name_${fee.key}`}>Proration Name <span>*</span></label>
													<Field component={InputField}
																 validate={[required, maxLength([50, 'Proration Name must contain max 50 characters'])]}
																 type="text" name={`prorated_fee_name_${fee.key}`} id={`prorated_fee_name_${fee.key}`}
																 onChange={(e) => this.updateProratedFee(index, 'name', e.target.value)}/>
												</div>
												<div className="col is-1-of-3">
													<label htmlFor={`prorated_fee_amount_${fee.key}`}>Prorated Fee <span>*</span></label>
													<Field component={InputField} type="text"
																 validate={this.props.bill_rate ? [required, isCustomNumeric(8), lessThan([this.props.bill_rate, 'Must be less than Bill Rate'])] : [required, isCustomNumeric(8)]}
																 name={`prorated_fee_amount_${fee.key}`} id={`prorated_fee_amount_${fee.key}`}
																 onChange={(e) => this.updateProratedFee(index, 'amount', e.target.value)}/>
												</div>
											</div>
											<div className="row" style={{marginTop: "0"}}>
												<div className="col is-2-of-7">
													<label htmlFor={`prorated_fee_start_date_${fee.key}`}>Effective Date <span>*</span></label>
													<Field className={'date'} showYearDropdown={!fee.recurring} minDate={!fee.recurring ? new Date() : null}
																 component={DatePickerInput} maxDate={!fee.recurring && fee.end_date ? new Date(fee.end_date) : null}
																 name={`prorated_fee_start_date_${fee.key}`} id={`prorated_fee_start_date_${fee.key}`}
																 onChange={(v) => this.setDate(index, "start_date", v)}
																 validate={this.isFieldModifiedOrNew(fee, "start_date") ?
																	 [required, isBeforeDate({recurring: fee.recurring,
																	 end_date: fee.end_date
																 })] : []}
																 showMonthDropdown dateFormat={fee.recurring ? 'MM/dd': 'MM/dd/yyyy'}/>
												</div>
												<div className="col is-2-of-7">
													<label htmlFor={`prorated_fee_end_date_${fee.key}`}>Expiration Date <span>*</span></label>
													<Field className={'date'} showYearDropdown={!fee.recurring}
																 component={DatePickerInput}
																 validate={this.isFieldModifiedOrNew(fee, "end_date") ? [required, isBeforeDate({
																	 recurring: fee.recurring,
																	 start_date: fee.start_date
																 })] : []}
																 minDate={!fee.recurring ? fee.start_date ? new Date(fee.start_date) : new Date() : null}
																 name={`prorated_fee_end_date_${fee.key}`} id={`prorated_fee_end_date_${fee.key}`}
																 onChange={(v) => this.setDate(index, "end_date", v)}
																 showMonthDropdown dateFormat={fee.recurring ? 'MM/dd': 'MM/dd/yyyy'}/>
												</div>
												<div className="col" style={{marginTop: "16px", display: "block"}}>
													<Field
														component={'input'} type="checkbox" name={`prorated_fee_recurring_${fee.key}`}
														id={`prorated_fee_recurring_${fee.key}`}
														onChange={() => this.updateProratedFee(index, 'recurring', !fee.recurring)}/>
													<label style={{color: 'black', lineHeight: "56px"}}
																 htmlFor={`prorated_fee_recurring_${fee.key}`}>Recurring</label>
												</div>
											</div>
										</div>
										<div className="col is-1-of-8" style={{display: "flex", justifyContent: "center"}}>
											<button type={'button'} onClick={() => this.deleteProratedFee(index)}
															className="btn fill gray">Delete
											</button>
										</div>
									</div>
									{index < this.state.proratedFees.length - 1 && <hr className="horizontal_rule"></hr>}
								</div>
							))
						}
					</div>

					{this.state.proratingErrors.length > 0 && this.state.proratingErrors.map(error => {
						return (<span className={'validation__message is-error'}>{error}</span>)
					})}

					<div className="row">
						<div className="col">
							<div className="col push-left add_prorated_fee_btn">
								<button
									type={'button'}
									onClick={() => this.addProratedFee()}
									className="btn fill another-blue">Add Association Membership Type Proration
								</button>
							</div>
						</div>
					</div>

					{this.state.errors.length > 0 && <div className="row">
						<div className="col">
							{this.state.errors.map(error => {return (<span className={'validation__message is-error'}>{error}</span>)})}
						</div>
					</div>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={this.props.closeModal}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={submitting || pristine || !valid} className="btn fill blue" >Save</button>
						</div>
					</div>
				</form>
            </Fragment>
		);
	}
}

EditMembershipTypeForm = reduxForm({
	form: 'editMembershipTypeForm',
	destroyOnUnmount: true
})(EditMembershipTypeForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editMembershipTypeForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		bill_rate: selector(state, 'bill_rate'),
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditMembershipTypeForm));
