import React, { Component, Fragment } from "react";
import GolferTable from "./GolferTable";
import Modal from "react-modal";
import "react-picky/dist/picky.css";
import { withRouter } from 'react-router-dom';
import ChallengesService from "../../../../services/api/challenges";
import ViewScoreModal from "../../../Modals/ViewScoreModal";
import EditChallengeModal from "../../../Modals/EditChallengeModal";
import DeleteChallengeModal from "../../../Modals/DeleteChallengeModal";
import { addAlert } from "../../../shared/actions";
import ShareChallengeModal from "../../../Modals/ShareChallengeModal";

class ClubChallenge extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addNewChallengeModalIsOpen: false,
			tableNeedsRefresh: false,
			removeGolferModal: false,
			viewScoreModal: false,
			editModal: false,
			deleteModal: false,
			challenge: null,
			view_scores_challenge: null,
			challenge_id: null,
			removed_golfer: null,
			viewed_golfer: null,
			viewed_golfer_id: null,
			viewed_golfer_primary_club: null,
			viewed_golfer_association_id: null,
      viewShareChallengeModal: false,
		};

		this.setTableRefresh = this.setTableRefresh.bind(this);
		this.openRemoveGolferModal = this.openRemoveGolferModal.bind(this);
		this.openViewScoreModal = this.openViewScoreModal.bind(this);
		this.openEditModal = this.openEditModal.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
		this.copyInviteLink = this.copyInviteLink.bind(this);
	}

	closeModal() {
		this.setState({
			editModal: false,
			removeGolferModal: false,
			viewScoreModal: false,
      viewShareChallengeModal: false,
		});
	}

	setTableRefresh(value) {
		this.setState({ tableNeedsRefresh: value });
		console.log("refreshing");
		this.loadChallenge();
	}

	copyInviteLink() {
    this.setState({ viewShareChallengeModal: true })
		const inviteLink = this.state.challenge.link;
		navigator.clipboard.writeText(inviteLink)
			.then(() => {
				addAlert({ type: 'success', message: 'Invite link copied to clipboard!' });
			})
			.catch(err => {
				console.error('Failed to copy invite link: ', err);
				addAlert({ type: 'error', message: 'Failed to copy invite link' });
			});
	}

	viewLeaderboard() {
    window.open(this.state.challenge.link.replace(/\bchallenges\b/g, 'leaderboard'), "_blank");
	}


	loadDetails(data) {
		this.setState({
			viewed_golfer: data.golfer_name,
			viewed_golfer_id: data.golfer_id,
			viewed_golfer_primary_club: data.primary_club,
			viewed_golfer_association_id: data.association_id,
		});
		const { pathname } = this.props.location;
		const pathSegments = pathname.split('/');
		const challengeId = pathSegments[pathSegments.length - 1];
		ChallengesService.getGolferChallenges(
			data.golfer_id
		)
			.then((res) => {
				this.setState({
					view_scores_challenge: res
				});
			})
			.catch((err) => {
        console.log(err);
				// const basePath = pathSegments.slice(0, -1).join('/');
				// this.props.history.push(basePath);
			});
	}


	deleteChallenge() {
		ChallengesService.deleteChallenge(this.state.challenge_id).then(() => {
			if (this.props.setRefreshList) this.props.setRefreshList(true);
			addAlert({ type: 'success', message: 'Challenge successfully deleted' });
			const { pathname } = this.props.location;
			const pathSegments = pathname.split('/');
			this.setState({ deleteModal: false });
			const basePath = pathSegments.slice(0, -1).join('/');
			this.props.history.push(basePath);
		}).catch(err => {
			console.log(err);
		});
	}

	loadChallenge() {
		const { pathname } = this.props.location;
		const pathSegments = pathname.split('/');
		const challengeId = pathSegments[pathSegments.length - 1];
		ChallengesService.getChallenge(
			challengeId,
		)
			.then((res) => {
				this.setState({
					challenge: res
				});
				this.props.updateChallenge(res.name);
			})
			.catch((err) => {
        console.log(err);
				// const basePath = pathSegments.slice(0, -1).join('/');
				// this.props.history.push(basePath);
			});
	}

	componentDidMount() {
		const { pathname } = this.props.location;
		const pathSegments = pathname.split('/');
		const challengeId = pathSegments[pathSegments.length - 1];
		this.setState({ challenge_id: challengeId })
		this.loadChallenge();
	}

	openRemoveGolferModal(data) {
		this.setState({
			removeGolferModal: true,
			removed_golfer: data,
		});
	}

	openViewScoreModal(data) {
		this.setState({
			viewScoreModal: true,
			viewed_golfer: data
		});
		this.loadDetails(data);
	}

	openEditModal() {
		this.setState({ editModal: true });
	}
	openDeleteModal() {
		this.setState({ deleteModal: true });
	}

	render() {
		let panelTitle = "Challenges";

		return (
			<Fragment>
				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head" style={{}}>{panelTitle}</div>
							<div className="panel__body">
								<div className="row">
									<div className="col is-6-of-6 challenge-settings">
										<h3>{this.state.challenge?.name}</h3>
									</div>
									{this.state.challenge && this.state.challenge.status !== 'deleted' &&
										<>
											{(this.props.canEditAssociation || this.props.pushNotificationUser) && (
												<div className="col is-1-of-5">
													<button
														className="btn fill green"
														onClick={this.copyInviteLink} // call the copyInviteLink function
													>
														Copy Invite Link
													</button>
												</div>
											)}
											{(this.props.canEditAssociation || this.props.pushNotificationUser) && (
												<div className="col is-1-of-5">
													<button
														className="btn fill green"
														onClick={() =>
															this.viewLeaderboard()
														}
													>
														View Leaderboard
													</button>
												</div>
											)}
											{(this.props.canEditAssociation || this.props.pushNotificationUser) && (
												<div className="col is-1-of-8 challenge-button" onClick={this.openDeleteModal}>
													Delete Challenge
												</div>
											)}
											{(this.props.canEditAssociation || this.props.pushNotificationUser) && (
												<div className="col is-1-of-8 challenge-button" onClick={this.openEditModal}>
													Edit Challenge
												</div>
											)}
										</>
									}
								</div>
								<div className="row">
									<div className="col">
										<GolferTable
											canEditAssociation={this.props.canEditAssociation}
											challengeId={this.state.challenge_id}
											challengeStatus={this.state.challenge?.status}
											associationId={this.props.associationId}
											openRemoveGolferModal={this.openRemoveGolferModal}
											openViewScoreModal={this.openViewScoreModal}
											closeModal={this.closeModal}
											user={this.props.user}
											openAddNewChallengeModal={this.openAddNewChallengeModal}
											setTableRefresh={(value) => this.setTableRefresh(value)}
											tableNeedsRefresh={this.state.tableNeedsRefresh}
											userType={this.props.userType}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        <ShareChallengeModal
          isOpen={this.state.viewShareChallengeModal}
          onConfirm={() => this.closeModal()}
					closeModal={() => this.closeModal()}
					share_link={this.state.challenge?.link}
					challenge_name={this.state.challenge?.name}
        />
				<ViewScoreModal
					wideClass={"narrow"}
					isOpen={this.state.viewScoreModal}
					openModal={this.state.viewScoreModal}
					challenge={this.state.view_scores_challenge}
          fromClub={true}
					viewedGolfer={this.state.viewed_golfer}
					viewedGolferId={this.state.viewed_golfer_id}
					viewedGolferPrimaryClub={this.state.viewed_golfer_primary_club}
					viewedGolferAssociationId={this.state.viewed_golfer_association_id}
					onConfirmAction={() => {this.closeModal()}}
					onCancelAction={() => {
						this.setState({ viewScoreModal: false });
					}}
					closeModal={() => {
						this.setState({ viewScoreModal: false });
					}}
					question={this.state.challenge?.name}
					confirmLabel={"View Full Score History"}
					modalIcon={"help_outline"}
				/>
				<Modal
					isOpen={this.state.editModal}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditChallengeModal
						challengeId={this.state.challenge_id}
						challenge={this.state.challenge}
						user={this.props.user}
						closeModal={() => this.closeModal()}
						setTableRefresh={(value) => this.setTableRefresh(value)}
					/>
				</Modal>
				<DeleteChallengeModal
					wideClass={"narrow"}
					isOpen={this.state.deleteModal}
					openModal={this.state.deleteModal}
					onConfirmAction={() => this.deleteChallenge()}
					onCancelAction={() => {
						this.setState({ deleteModal: false });
					}}
					closeModal={() => {
						this.setState({ deleteModal: false });
					}}
					question={
						"Delete Challenge"
					}
					bodyQuestion={
						"Are you sure you want to delete the " + this.state.challenge?.name + "?"
					}
					note={
						"Golfers will lose the ability to view the leaderboard and post scores to the challenge."
					}
					cancelLabel={"Cancel"}
					confirmLabel={"Delete Challenge"}
					modalIcon={"help_outline"}
				/>
			</Fragment>
		);
	}
}

export default withRouter(ClubChallenge);
