import React, { Component, Fragment } from "react";
import Modal from "react-modal";

import AddClubMembershipTypeModal from "../../../Modals/AddClubMembershipTypeModal";
import ClubService from "../../../../services/api/club";
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import {bindActionCreators} from "redux";
import {addAlert} from "../../../shared/actions";
import {connect} from "react-redux";
import InfoBox from '../../../shared/InfoBox';
import ReactTooltip from "react-tooltip";
import {getProratedAmount} from "../../../shared/proratings";
import {numberFormat} from "../../../shared/numberFormat";

class MembershipTypesClub extends Component {
	constructor(props) {
		super(props);

		this.state = {
			AddMembershipTypeModalIsOpen: false,
			membershipTypes: [],
			membershipLoading: false,
			confirmationMdoalIsOpen: false,
			deleteMembership: null,
			confirmationDefaultModal: false,
      selected_membership: null,
		};

		this.openAddMembershipTypeModal =
			this.openAddMembershipTypeModal.bind(this);
		this.deleteMembership = this.deleteMembership.bind(this);
		this.changeDefaultMembership = this.changeDefaultMembership.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openAddMembershipTypeModal() {
		this.setState({ AddMembershipTypeModalIsOpen: true });
	}

	componentDidMount() {
		this.getMembershipTypes();
	}

	getMembershipTypes() {
		this.setState({
			membershipLoading: true,
		});
		ClubService.getMembershipTypes(this.props.club.id)
			.then((res) => {
				let usedMemberships = res.membership_types.filter(
					(membershipType) => membershipType.is_used
        );
        this.setState({
					membershipLoading: false,
					membershipTypes: res.membership_types.map(
						(membershipType) => {
							return {
								...membershipType,
								...{
									bill_rate: parseFloat(
										membershipType.bill_rate
									),
								},
							};
						}
          ),
					hideRemoveAction:
						usedMemberships.length === res.membership_types.length,
        });
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					membershipLoading: false,
					membershipTypes: [],
				});
			});
	}
	changeDefaultMembership() {
		ClubService.markAsDefault(
			this.props.club.id,
			this.state.selected_membership.club_rate_id
		)
			.then((res) => {
				this.setState({
					confirmationDefaultModal: false,
				});
				this.getMembershipTypes();
			})
			.catch((err) => {
				console.error(err);
			});
	}

	closeModal(data) {
		if (data) this.getMembershipTypes();
		this.setState({
			AddMembershipTypeModalIsOpen: false,
		});
	}

	deleteMembership() {
		ClubService.removeMembershipType({
			club_ids: [this.props.club.id],
			membership_ids: [this.state.deleteMembership.id],
		})
			.then((res) => {
				this.props.addAlert({
					type: "success",
					message: "Membership type successfully removed",
				});
				this.setState(
					{ confirmationMdoalIsOpen: false, deleteMembership: null },
					() => this.getMembershipTypes()
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		let membershipColumns = [
			{
				Header: "Membership Code",
				accessor: "code",
				colClassName: "col_user-name",
			},
			{
				Header: <Fragment>
					Is Locked?
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='offer_type'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
						<div style={{padding: '10px 0px'}}>
							<p><strong>Locked Membership Code:</strong> When a membership code is locked it means that any
								golfers assigned to it cannot be reassigned to another Membership Type. A locked code is
								denoted with the light blue lock icon. If you have questions, please contact your association.</p>
						</div>
					</ReactTooltip>
				</Fragment>,
				accessor: 'locked',
				colClassName: 'col_is_locked',
				Cell: (data) => {
					return (
						<div style={{display: "flex", justifyContent: "center"}}>
							{data.original.locked &&
								<i className="material-icons-outlined lock-icon-container"></i>
							}
						</div>
					)
				}
			},
			{
				Header: 'Membership Description',
				accessor: 'description',
			},
			{
				Header: "Default Membership",
				accessor: "is_default",
				colClassName: "col_requirements",
				Cell: (props) => (
					<Fragment>
						<input
							type="checkbox"
							disabled={props.original.type === "Junior" || props.original.is_default}
							checked={
								props.original.is_default &&
								props.original.type != "Junior"
							}
							onChange={() => {
								this.setState({
									confirmationDefaultModal: props.original
										.is_default
										? false
										: true,
									selected_membership: props.original,
								});
							}}
							name={props.original.id}
							id={props.original.id}
						/>
						<label
							style={{ margin: "auto" }}
							htmlFor={props.original.id}
						></label>
					</Fragment>
				),
			},
			{
				Header: 'Bill Rate',
				accessor: 'bill_rate',
				colClassName: 'col_bill_rate',
				Cell: props => {
					const isProrated = !!props.original.prorating;
					let amount = props.original.bill_rate;
					if (isProrated)
						amount = props.original.prorating.amount;
					return (
						<div className="blue_ribbon ribbon_hover" style={{color: isProrated ? "#0089ce" : ""}}>
							{numberFormat(amount)}
							{isProrated &&
								<i className="material-icons-outlined scissors-icon-container prorated-amount"></i>
							}
						</div>
					)
				}
			},
			{
				Header: '',
				show: this.props.canEditAssociation && !this.state.hideRemoveAction,
				colClassName: 'col_buttons',
				Cell: props => !props.original.is_used && (<span className='col_edit-delete'>
					<button className={'btn lnk'} onClick={() => {this.setState({confirmationMdoalIsOpen: true, deleteMembership: props.original})}}>Remove</button>
				</span>)
			}
		];
		return (
            <Fragment>

                <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Club Membership Types</div>
                                <div className="panel__body">
                                {!this.props.canEditAssociation && <div className="row">
                                  <InfoBox className="info-box-yellow" 
                      							title = "PLEASE READ: "
                      							classTitle="color-red font-weight-600"
                      message="Membership codes are created by your association for billing and tracking purposes. Please use caution when updating. If you have any questions, please contact your association."
                      						/>
                                </div>}
                                  {this.props.canEditAssociation && <div className="row" style={{marginBottom: "0"}}>
                                    <div className="col is-4-of-5" style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
                                      <p><strong>Note:</strong> Membership Types can only be removed from a club if there are no golfers (regardless of status) currently assigned to it.</p>
                                    </div>
                                    <div className="col is-1-of-5 push-right">
                                      <button onClick={() => this.openAddMembershipTypeModal()} className="btn fill green">Add Membership Type</button>
                                    </div>
                                  </div>}
																	<div className="row">
																		<div className="col auto push-right">
																			<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text"
																					 style={{marginTop: !this.props.canEditAssociation ? '0' : undefined}}>
																				<i className="material-icons-outlined lock-icon-container"></i>
																				<p>Membership Code is Locked</p>
																			</div>
																		</div>
																		<div className="col auto" style={{paddingLeft: 0}}>
																			<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text"
																					 style={{marginTop: !this.props.canEditAssociation ? '0' : undefined}}>
																				<i className="material-icons-outlined scissors-icon-container"></i>
																				<p>Prorated Rate</p>
																			</div>
																		</div>
																	</div>
									<div className="row">
										<div className="col is-full">
											<GhinTable
												idKey={"id"}
												hidePagination={true}
												columns={membershipColumns}
												loading={
													this.state.membershipLoading
												}
												hideSelect={true}
												data={
													this.state.membershipTypes
												}
												ref={(r) => (this.table = r)}
												defaultSorted={[
													{
														id: "code",
														desc: false,
													},
												]}
												sortable={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={this.state.AddMembershipTypeModalIsOpen}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddClubMembershipTypeModal
						association={this.props.association}
						club={this.props.club}
						membershipTypes={this.state.membershipTypes}
						clubId={this.props.club.id}
						closeModal={(data) => {
							this.closeModal(data);
						}}
					/>
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to remove Membership Type:${
						this.state.deleteMembership &&
						this.state.deleteMembership.code
					}?`}
					confirmLabel={"Continue"}
					cancelLabel={"Cancel"}
					onCancelAction={() =>
						this.setState({ confirmationMdoalIsOpen: false })
					}
					onConfirmAction={() => this.deleteMembership()}
					openModal={this.state.confirmationMdoalIsOpen}
					closeModal={() =>
						this.setState({ confirmationMdoalIsOpen: false })
					}
				/>

				<ConfirmationModalTypeB
					question={`Change Default Membership Type?`}
					modalIcon={"help_outline"}
					confirmLabel={"Continue"}
					cancelLabel={"Cancel"}
					bodyQuestion={`Are you sure you want to change the default membership type?`}
					onCancelAction={() =>
						this.setState({ confirmationDefaultModal: false })
					}
					onConfirmAction={this.changeDefaultMembership}
					openModal={this.state.confirmationDefaultModal}
					closeModal={() =>
						this.setState({ confirmationDefaultModal: false })
					}
				/>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addAlert }, dispatch);
}

export default connect(null, mapDispatchToProps)(MembershipTypesClub);
