import {numberFormat} from "./numberFormat";

export function getProratedAmount(initialAmount, proratedFees) {
  let amount = initialAmount;
  let isProrated = false;
  const activeProratedFee = proratedFees ? proratedFees.find((proratedFee) => proratedFee.active) : null;

  if (activeProratedFee) {
    amount = activeProratedFee.amount;
    isProrated = true;
  }

  return {amount: numberFormat(amount), isProrated: isProrated};
}

export function processProratedFee(proratedFee) {
  let startDate = proratedFee.start_date;
  let endDate = proratedFee.end_date;
  if (proratedFee.recurring) {
    const currentYear = new Date().getFullYear();
    startDate = `${currentYear}-${startDate}`;
    endDate = `${currentYear}-${endDate}`;
  }

  return {
    initializeFields: {
      [`prorated_fee_name_${proratedFee.id}`]: proratedFee.name,
      [`prorated_fee_amount_${proratedFee.id}`]: String(proratedFee.amount),
      [`prorated_fee_start_date_${proratedFee.id}`]: new Date(new Date(startDate).setHours(0, 0, 0, 0)),
      [`prorated_fee_end_date_${proratedFee.id}`]: new Date(new Date(endDate).setHours(0, 0, 0, 0)),
      [`prorated_fee_recurring_${proratedFee.id}`]: proratedFee.recurring,
    },
    proratedFeeItem: {
      ...proratedFee,
      key: proratedFee.id,
      start_date: startDate,
      end_date: endDate
    },
  };
}

