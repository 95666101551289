import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import AddHomeCourseModal from '../../../Modals/AddHomeCourseModal';
import EditHomeCourseModal from '../../../Modals/EditHomeCourseModal';
import ClubService from "../../../../services/api/club";
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {bindActionCreators} from "redux";
import {addAlert} from "../../../shared/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';


class HomeCoursesClub extends Component {

    constructor(props) {

        super(props);

        this.state = {
            AddHomeCourseModalIsOpen: false,
			EditHomeCourseModalIsOpen: false,
			editingCourse: null,
			openDeleteConfirmation: false,
			homeCourses: [],
			facilities: [],
			editingFacility: null,
			toDelete: null,
			loading: true
        };

        this.openAddHomeCourseModal = this.openAddHomeCourseModal.bind(this);
		this.openEditHomeCourseModal = this.openEditHomeCourseModal.bind(this);


	}

    openAddHomeCourseModal() {
        this.setState({AddHomeCourseModalIsOpen: true});
    }

	openEditHomeCourseModal(id) {
    	this.setState({
			editingFacility: this.state.facilities.filter(facility => facility.id === id)[0],
			EditHomeCourseModalIsOpen: true
		})
	}

    closeModal(res) {
		this.setState({
            AddHomeCourseModalIsOpen: false,
			EditHomeCourseModalIsOpen: false,
			loading: res ? true : false,
			homeCourses: res ? [] : this.state.homeCourses
        });
		if(res) this.getHomeCourses()
    }

    componentDidMount() {
		this.getHomeCourses()
	}

	getHomeCourses(){
		ClubService.getClubHomeCourses(this.props.match.params.association_id, this.props.match.params.id)
			.then(res => {
				let homeCourses = [];
				if(res.facilities.length > 0) res.facilities.map(facility => {
					facility.home_courses.map(home_course => {
						homeCourses.push({
							...home_course,
							...{
								id: home_course.id,
								isPrimary: facility.is_primary,
								facilityId: facility.facility_id,
								facilityNumber: facility.id,
								facilityName: facility.name,
								courseName: home_course.name,
								city: home_course.city,
								state: home_course.state,
								defaultTeeMale: home_course.default_tee_male ? home_course.default_tee_male.name : '',
								defaultTeeFemale: home_course.default_tee_female? home_course.default_tee_female.name : ''
							}
						});
						return null;
					});
					return null;
				});
        homeCourses.sort((a, b) => {
          const facilityA = a.facilityName.toUpperCase();
          const facilityB = b.facilityName.toUpperCase();
          if (facilityA < facilityB) {
            return -1;
          }
          if (facilityA > facilityB) {
            return 1;
          }
          const courseA = a.courseName.toUpperCase();
          const courseB = b.courseName.toUpperCase();
          if (courseA < courseB) {
            return -1;
          }
          if (courseA > courseB) {
            return 1;
          }
          return 0;
        })
				this.setState({
					loading: false,
					homeCourses,
					facilities: res.facilities
				});
			})
			.catch(err => {
				console.error(err);
			})
	}

	removeHomeCourse(){
    	ClubService.removeClubHomeCourses( this.state.toDelete.facilityId,  this.state.toDelete.facilityNumber, this.props.club.id,  this.state.toDelete.courseId)
			.then(res=>{
				this.setState({
					openDeleteConfirmation: false,
					toDelete: null,
					loading: true,
					homeCourses: []
				});
				this.props.addAlert({
					type:'success',
					message: 'Course successfully removed from your Home Course List!'
				});
				this.getHomeCourses();
			})
			.catch(err=>{console.error(err)});
	}

	render() {
		let columns = [
			{
				Header: 'Facility Name',
				accessor: 'facilityName',
				colClassName: 'col_email-address',
				Cell: props => {return (
					<Fragment>{props.value.length > 25 ? props.value.slice(0, 25) + '...' : props.value} {props.original.isPrimary === true ? <span className="fa fa-flag blue small margin-left absolute line-height-18"></span> : ''}</Fragment>
				)}
			},
			{
				Header: 'Course Name',
				accessor: 'courseName',
			},
			{
				Header: 'City',
				accessor: 'city',
			},
			{
				Header: 'State/Prov.',
				accessor: 'state',
			},
			{
				Header: 'Default Male Tee',
				accessor: 'defaultTeeMale',
			},
			{
				Header: 'Default Female Tee',
				accessor: 'defaultTeeFemale',
			},
			{
				Header: '',
				accessor: 'edit',
				colClassName: 'col_edit',
				show: this.props.canEditClub,
				Cell: props => {return (
					<button className="btn lnk" onClick={()=>{ this.openEditHomeCourseModal(props.original.facilityNumber)}}>Edit</button>
				)}
			},
			{
				Header: '',
				accessor: 'remove',
				colClassName: 'col_remove',
				show: this.props.canEditClub && !(this.props.club.club_type === "Type 1" && this.state.homeCourses.length === 1),
				Cell: props => {return (
					<button className="btn lnk" onClick={()=>{ this.setState({
						toDelete: {
							facilityId:props.original.facilityId,
							facilityNumber:props.original.facilityNumber,
							courseId: props.original.id,
							courseName: props.original.courseName,
							facilityName: props.original.facilityName
						},
						openDeleteConfirmation: true
					})}}>Remove</button>
				)}
			}
		];
		return (

            <Fragment>
                <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Home Courses and Default Tee</div>
                                <div className="panel__body">
                                    <div className="row">
                                        <div className="col auto push-right">
                                          <span style={{ display: 'inline-flex'}}>
                                            <i className="fa fa-flag blue big margin-right"></i> 
                                              <span className="fa-flag-image-text">Primary Facility</span>
                                          </span>
                                        </div>
										{this.props.canEditClub &&  <div className="col auto">
                                            <button onClick={() => this.openAddHomeCourseModal()} className="btn fill green">Add Home Course</button>
                                        </div>}
                                    </div>
                                    <div className="row">
                                        <div className="col">
											<GhinTable
												idKey={'id'}
												defaultPageSize={10}
												columns={columns}
												loading={this.state.loading}
												hideSelect={true}
												data={this.state.homeCourses}
												ref={(r) => this.table = r}
												sortable={false}
											/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.AddHomeCourseModalIsOpen}
                    onRequestClose={()=>{this.closeModal()}}
                    contentLabel="Modal"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
					>
                    <AddHomeCourseModal assocId={this.props.associationId} clubId={this.props.match.params.id} closeModal={(data)=>{this.closeModal(data)}} />
                </Modal>

				<Modal
					isOpen={this.state.EditHomeCourseModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditHomeCourseModal assocId={this.props.associationId}
										 facility={this.state.editingFacility}
										 clubId={this.props.club}
										 cantRemove={(this.props.club.club_type === "Type 1" && this.state.homeCourses.length === 1)}
										 closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<ConfirmationModal
					question={'Confirm'}
					bodyQuestion={`Are you sure you want to remove  ${this.state.toDelete ? this.state.toDelete.facilityName : ''} > ${this.state.toDelete ? this.state.toDelete.courseName : ''} from your Home Course List?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({openDeleteConfirmation: false})}
					onConfirmAction={()=>{this.removeHomeCourse()}}
					openModal={this.state.openDeleteConfirmation}
					closeModal={() => this.setState({openDeleteConfirmation: false})}
				/>

            </Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		club: state.club
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect( mapStateToProps, mapDispatchToProps)(withRouter(HomeCoursesClub));
