import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import PhoneChallenge from "../../img/phone_challenge.png"
import CheckCircle from "../../img/check-circle.png"

export default function ShareChallengeModal(props) {
	return (
		<Modal
			isOpen={props.isOpen}
			contentLabel="Share Challenge Modal"
			portalClassName="modal"
			overlayClassName="modal__overlay"
			className="modal__content"
			bodyOpenClassName="modal--is-open"
			htmlOpenClassName="prevent-scroll"
			shouldCloseOnOverlayClick
			shouldFocusAfterRender={false}
		>
			<div className="modal__container narrow">
				<div className="modal__head">
          <h2 className={`modal__title`}>
					  {props.challenge_name}
          </h2>
					<button className="modal__close" onClick={() => {props.onConfirm()}}>
						<i className="material-icons-outlined">clear</i>
					</button>
				</div>
				
				<div className="share-challenge-modal__body">
					<div className="share-challenge-modal__content-left">
						<img src={CheckCircle} alt="Check Circle" />
						<h3>A shareable link to this Challenge has been copied to your clipboard.</h3>
						<p>This link allows golfers to view and join the Challenge from Golfer Products.</p>
						<a href={props.share_link} className="share-challenge-modal__link">
              {props.share_link}
						</a>
						<button className="share-challenge-modal__continue-btn btn fill blue " onClick={props.onConfirm}>
							Continue
						</button>
					</div>
					<div className="share-challenge-modal__content-right">
						<img src={PhoneChallenge} alt="Phone Challenge Preview" />
					</div>
				</div>
			</div>
		</Modal>
	);
}

ShareChallengeModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
};
