import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { addAlert } from '../shared/actions';
import { Field, reduxForm } from "redux-form";
import JoinAndRenewServices from "../../services/api/joinAndRenew";

function EditJoinAndRenewEnabledFormFunction(props) {
  const [enable_join_and_renew, set_enable_join_and_renew] = useState(props.enable_join_and_renew)

  const { handleSubmit, pristine, submitting } = props;

  const onSubmit = (values, dispatch, props) => {
    const data = {
      join_renew_enabled: enable_join_and_renew,
    }
    return JoinAndRenewServices.updateSettings(props.association_id, data).then(() => {
      props.addAlert({ type: 'success', message: `Join and Renew successfully ${values.enable_join_and_renew === "true" ? 'enabled' : 'disabled'}` })
      props.closeModal();
      props.reload_payment_settings();
    })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col is-1-of-2">
          <span className="label">Enable Join and Renew?</span>
          <div className="radio__container">

            <Field
              onClick={
                (data) => {
                  set_enable_join_and_renew(data.target.value === "true")
                }
              }
              checked={enable_join_and_renew}
              id="enable-join-yes"
              value={true}
              name='enable_join_and_renew'
              component='input'
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="enable-join-yes">Yes</label>

            <Field
              onClick={
                (data) => {
                  set_enable_join_and_renew(data.target.value === "true");
                }
              }
              checked={!enable_join_and_renew}
              id="enable-join-no"
              value={false}
              name='enable_join_and_renew'
              component='input'
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="enable-join-no">No</label>

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <button type='button' className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type='submit' className="btn fill blue" disabled={pristine || submitting}>Save</button>
        </div>
      </div>
    </form>
  )
}

const EditJoinAndRenewEnabledForm = reduxForm({
  form: 'editJoinAndRenewEnabledForm',
  destroyOnUnmount: true
})(EditJoinAndRenewEnabledFormFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditJoinAndRenewEnabledForm));
