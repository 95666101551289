import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import {connect, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import {change, Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {
	isCustomNumeric,
	required,
	customMsgRequired,
	requiredSelect,
	isNumber,
	greaterThanCustomMessage,
	lowerThanCustomMessage,
	maxLength,
	alphaNumericOrPoint,
	isBeforeDate,
  lessThan
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { SelectInput } from "../../inputs/Select";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import ClubService from "../../services/api/club";
import {DatePickerInput} from "../../inputs/DatePicker";
import {processProratedFee} from "../shared/proratings";

function MembershipFee(props) {
	const [error, setError] = useState("");
	const [proratingError, setProratingError] = useState("")
	const [membershipTypes, setMembershipTypes] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [proratedFees, setProratedFees] = useState([]);
	const [initialProratedFees, setInitialProratedFees] = useState([]);
	const [modifiedFields, setModifiedFields] = useState({});
	const dispatch = useDispatch();

	const genderOptions = [
		{
			value: "male and female",
			label: "Male & Female",
		},
		{
			value: "male",
			label: "Male",
		},
		{
			value: "female",
			label: "Female",
		},
	];

	useEffect(() => {
		const getMembershipCodes = () => {
			ClubService.getMembershipTypes(props.clubId, {include_used_membership: 'false'})
				.then((res) => {
					if (res.membership_types) {
						setMembershipTypes(
							res.membership_types.map((mt) => {
								const isProrated = props.membership_cycle ? !!mt.prorating : false;
								let amount = parseFloat(mt.bill_rate).toFixed(2);
								if (isProrated)
									amount = parseFloat(mt.prorating.amount).toFixed(2);
								return {
									amount: mt.bill_rate,
									value: mt.club_rate_id,
									label:
										<Fragment>
											<div style={{display: "inline-flex", alignItems: "end"}}>
											<span style={{display: "flex", alignItems: "center"}}>
												<span className="prorated-codes-dropdown">{`${mt.code} - ${mt.type} Membership -`}</span>
												&nbsp;
												<span className="prorated-codes-dropdown" style={{color: isProrated ? "#0089ce" : ""}}>{`$${amount}`}</span>
												{isProrated && <i className="material-icons-outlined scissors-icon-container prorated-amount" style={{transform: "translateY(1px)"}}/>}
											</span>
											</div>
										</Fragment>,
									proratedAmount: isProrated ? amount : null,
								};
							})
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};

		getMembershipCodes();
	}, [props.clubId]);

	useEffect(() => {
		if (props.selectedMembershipFee) {
			const initialize = props.initialize;
			const fee = props.selectedMembershipFee;
			const genderEligibility = genderOptions.filter((g) => {
				return g.value === fee.gender;
			});
			const transactionFee = props.transactionFees.filter((tf) => {
				return tf.value === fee.golf_association_fee_id;
			});
			const membershipType = membershipTypes.filter((mt) => {
				return mt.value === fee.club_rate_id;
			});
			const proratings = fee.proratings ? fee.proratings : []
			const { initializeData, prFees } = proratings.reduce((acc, proratedFee) => {
				const { initializeFields, proratedFeeItem } = processProratedFee(proratedFee);
				acc.initializeData = { ...acc.initializeData, ...initializeFields};
				acc.prFees.push(proratedFeeItem);
				return acc;
			}, { initializeData: {}, prFees: []})

			initialize({
				...fee,
				gender_eligibility: genderEligibility[0],
				transaction_fee: transactionFee[0],
				club_rate: membershipType[0],
				membershipType: membershipType[0],
				...initializeData,
			});
			setProratedFees(prFees);
			setInitialProratedFees(JSON.parse(JSON.stringify(prFees)));
		} else {
			const transactionFee = props.transactionFees.filter((tf) => {
				return tf.is_default === true;
			});
			props.initialize({
				transaction_fee: transactionFee[0],
				gender_eligibility: {
					value: "male and female",
					label: "Male & Female",
				},
			});
		}
	}, [membershipTypes]);

  useEffect(() => {
    const membership_type = parseFloat(props.membershipType?.proratedAmount || props.membershipType?.amount || 0.0)
    const transaction_fee = parseFloat(props.transactionFee?.amount || 0.0)
    const membership_amount = isNaN(parseFloat(props.membership_amount || 0.0)) ? 0.0 : parseFloat(props.membership_amount || 0.0)
    setTotalAmount(membership_type + transaction_fee + membership_amount);
	}, [props.membershipType, props.transactionFee, props.membership_amount]);

	const addProratedFee = () => {
		setProratedFees(prevState => [...prevState, {
			name: "", amount: null, start_date: "", end_date: "", recurring: false, key: Date.now()
		}
		]);
	}

	const updateProratedFee = (index, fieldName, value) => {
		const updatedProratedFees = [...proratedFees];
		updatedProratedFees[index][fieldName] = value;
		const key = updatedProratedFees[index].key;
		const initialValue = initialProratedFees[index]?.[fieldName];

		if (initialValue === undefined)
			setProratedFees(updatedProratedFees);
		else {
			setProratedFees(updatedProratedFees);
			setModifiedFields((prevModifiedFields) => ({
				...prevModifiedFields,
				[key]: {
					...prevModifiedFields[key],
					[fieldName]: String(value) !== String(initialValue),
				},
			}));
		}
	}

	const setDate = (index, fieldName, value) => {
		if (value) {
			const date = new Date(value)
			const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
			updateProratedFee(index, fieldName, dateString)
		} else
			updateProratedFee(index, fieldName, '')
	}

	const deleteProratedFee = (index) => {
		const updatedProratedFees = proratedFees.filter((_, i) => i !== index);
		setProratedFees(updatedProratedFees);
		if (initialProratedFees[index]) {
			const dummyField = `prorated_fee_name_${initialProratedFees[index].key}`;
			const dummyValue = 'deletedProratedFee';
			// Force pristine to be false when deleting a pre-existent prorating
			dispatch(change('membershipFeeForm', dummyField, dummyValue));
		}
	}

	const isFieldModifiedOrNew = (proratedFee, fieldName) => {
		const isNew = !proratedFee.id;
		const isModified = modifiedFields[proratedFee.key]?.[fieldName] === true;
		return isNew || isModified;
	}

	const constructParams = (values) => {
		const {name, amount, description, expense_code, club_rate, gender_eligibility, transaction_fee, minimum_age, maximum_age} = values;

		let data = {name, amount, description, expense_code, club_rate, gender_eligibility, transaction_fee, minimum_age, maximum_age};
		data = JSON.parse(JSON.stringify(data));
		if (data.transaction_fee) {
			if (data.transaction_fee.value !== '' && data.transaction_fee.value !== null)
				data.golf_association_fee_id = data.transaction_fee.value;
			else
				data.no_transaction_fee = 'true';
		}
		data.club_rate_id = data.club_rate.value;
		data.gender_eligibility = values.gender_eligibility.value;
		if (props.membership_cycle)
			data.proratings = proratedFees.map(({id, name, amount, start_date, end_date, recurring}) => {
				const formattedStartDate = recurring ? start_date.split('-').slice(1).join('-') : start_date;
				const formattedEndDate = recurring ? end_date.split('-').slice(1).join('-') : end_date;
				return ({id, name, amount, start_date: formattedStartDate, end_date: formattedEndDate, recurring});
			});
		delete data.transaction_fee;
		delete data.club_rate;

		return data;
	}

	const onSubmit = (values, dispatch, props) => {
		const data = constructParams(values);

		if (!props.selectedMembershipFee) {
			ClubService.addMembershipFee(props.clubId, data)
				.then(() => {
					props.addAlert({
						type: "success",
						message:
							"Membership Fee has been successfully created.",
					});
					props.closeModal();
					props.reload_clubs();
				})
				.catch((e) => {
					var error = ""
					var proratingError = ""

					try {
						Object.keys(e.data.errors).forEach((key) => {
							if (key === "proratings" && proratingError === "") {
								proratingError = e.data.errors[key][0]
							} else if (key !== "proratings" && error === "") {
								error = e.data.errors[key][0]
							}
						});
					} catch {
						error = "An error has occurred"
					}

					setError(error);
					setProratingError(proratingError);
				});
		} else {
			ClubService.editMembershipFee(
				props.clubId,
				props.selectedMembershipFee.id,
				data
			)
				.then(() => {
					props.addAlert({
						type: "success",
						message: "Membership Fee has been successfully edited.",
					});
					props.closeModal();
					// reload
					props.reload_clubs();
				})
				.catch((e) => {
					var error = ""
					var proratingError = ""

					try {
						Object.keys(e.data.errors).forEach((key) => {
							if (key === "proratings" && proratingError === "") {
								proratingError = e.data.errors[key][0]
							} else if (key !== "proratings" && error === "") {
								error = e.data.errors[key][0]
							}
						});
					} catch {
						error = "An error has occurred"
					}

					setError(error);
					setProratingError(proratingError);
				});
		}
	};

	const [maximumAge, setMaximumAge] = useState(100);
	const [minimumAge, setMinimumAge] = useState(0);

	const { handleSubmit, pristine, valid, submitting } = props;

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col">
						<label htmlFor="name">
							Membership Fee Name <span>*</span>
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[customMsgRequired('Please provide a Membership Fee Name'), maxLength([50, "Value too long (max 50 characters)"])]}
							name="name"
							id="name"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col">
						<label htmlFor="description">
							Membership Fee Description <span>*</span>
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[customMsgRequired('Please provide a Membership Fee Description'), maxLength([200, "Value too long (max 200 characters)"])]}
							name="description"
							id="description"
						/>
					</div>
				</div>

        <div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="expense_code">
							Expense Code
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[alphaNumericOrPoint, maxLength([15, "Value too long (max 15 characters)"])]}
							name="expense_code"
							id="expense_code"
						/>
					</div>
          <div className="col is-1-of-2">
            <span style={{ marginTop: "27px" }}>Enter an Expense Code to track business activities in your association. (Not Required)</span>
          </div>
				</div>

				<div className="row" style={{marginBottom: 0}}>
					<div className="col">
						<span className={"red"} style={{fontStyle: "italic"}}>NOTE: This fee is effective immediately in the association's local timezone.</span>
					</div>
				</div>

				<hr
					style={{
						border: "0.5px solid #cccccc",
						marginTop: "25px",
						marginBottom: "25px",
					}}
				></hr>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="amount">
							Club Membership Fee <span>*</span>
						</label>
						<Field
							component={InputField}
              placeholder={'0.00'}
							type="text"
							validate={[customMsgRequired('Please provide a numerical Club Membership Fee amount'), isCustomNumeric(8)]}
							name="amount"
							id="amount"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="club_rate">
							Association Membership Code <span>*</span>
						</label>
						<Field
							component={SelectInput}
							validate={[requiredSelect]}
							name="club_rate"
							id="club_rate"
							className="react-select-container"
							classNamePrefix="react-select"
							options={membershipTypes}
						/>
					</div>
				</div>

				{props.membershipType?.proratedAmount && <div className="row">
					<div className="col is-3-of-5 centered">
						<p><strong>Note: </strong>If the dates for this membership code proration overlap the “Include Remainder
							Date” for the club, then the prorated rate will not be applied.</p>
					</div>
				</div>}

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="transaction_fee">
							Association Transaction Fee
						</label>
						<Field
							component={SelectInput}
							name="transaction_fee"
							id="transaction_fee"
							className="react-select-container"
							classNamePrefix="react-select"
							options={props.transactionFees}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="total_fee">Total Fee:</label>
						<div
							style={{
								textAlign: "center",
								borderRadius: "6px",
								backgroundColor: "#cccccc",
								padding: "10px",
							}}
						>
							${totalAmount.toFixed(2)}
						</div>
					</div>
				</div>

				{ props.membership_cycle &&
					<>
						<hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

						<div className="row">
							<div className="col">
								<p className="prorated_fees_title"><strong>Club Fee Proration (Calendar Year Only)</strong></p>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<p>If a member joins the club in the middle of the year, the club membership dues can be prorated so that
									the member pays only for a portion of the year. Multiple proration periods can be established. Press
									"<strong>Add Club Fee Proration</strong>" to add a prorated rate. Check the "Recurring" checkbox to have
									the fees automatically applied during the specified timeframe from year-to-year.</p>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<p><strong>Notes:</strong></p>
								<p>
									<div style={{display: "flex", marginLeft: "9.5px"}}>
										<div><strong>•</strong>&ensp;</div>
										<div> When a prorated membership fee exists, membership discounts created in GHIN Join & Renew will not be combined during online registration.</div>
									</div>
								</p>
								<p>
									<div style={{display: "flex", marginLeft: "9.5px"}}>
										<div><strong>•</strong>&ensp;</div>
										<div> In addition, if the dates for a prorated rate overlap the "Include Remainder Date" at the club, the prorated rates will not be applied. The "Include Remainder" settings will always trump proration.</div>
									</div>
								</p>
							</div>
						</div>

						<div className="prorated_fees">
							{
								proratedFees.map((fee, index) => (<div key={`wrapper-${index}`}>
										<div className="row prorated_fee" key={`proration-${index}`}>
											<div className="col">
												<div className="row">
													<div className="col is-4-of-7">
														<label htmlFor={`prorated_fee_name_${fee.key}`}>Proration Name <span>*</span></label>
														<Field component={InputField}
																	 validate={[required, maxLength([50, 'Proration Name must contain max 50 characters'])]}
																	 type="text" name={`prorated_fee_name_${fee.key}`} id={`prorated_fee_name_${fee.key}`}
																	 onChange={(e) => updateProratedFee(index, 'name', e.target.value)}/>
													</div>
													<div className="col is-1-of-3">
														<label htmlFor={`prorated_fee_amount_${fee.key}`}>Prorated Fee <span>*</span></label>
														<Field component={InputField} type="text"
																	 validate={props.membership_amount ? [required, isCustomNumeric(8), lessThan([props.membership_amount, 'Must be less than Club Membership Fee'])] : [required, isCustomNumeric(8)]}
																	 name={`prorated_fee_amount_${fee.key}`} id={`prorated_fee_amount_${fee.key}`}
																	 onChange={(e) => updateProratedFee(index, 'amount', e.target.value)}/>
													</div>
												</div>
												<div className="row" style={{marginTop: "0"}}>
													<div className="col is-2-of-7">
														<label htmlFor={`prorated_fee_start_date_${fee.key}`}>Effective Date <span>*</span></label>
														<Field className={'date'} showYearDropdown={!fee.recurring}
																	 minDate={!fee.recurring ? new Date() : null} component={DatePickerInput}
																	 maxDate={!fee.recurring && fee.end_date ? new Date(fee.end_date) : null}
																	 name={`prorated_fee_start_date_${fee.key}`} id={`prorated_fee_start_date_${fee.key}`}
																	 onChange={(v) => setDate(index, "start_date", v)}
																	 validate={isFieldModifiedOrNew(fee, "start_date") ? [required, isBeforeDate({
																		 recurring: fee.recurring, end_date: fee.end_date
																	 })] : []}
																	 showMonthDropdown dateFormat={fee.recurring ? 'MM/dd' : 'MM/dd/yyyy'}/>
													</div>
													<div className="col is-2-of-7">
														<label htmlFor={`prorated_fee_end_date_${fee.key}`}>Expiration Date <span>*</span></label>
														<Field className={'date'} showYearDropdown={!fee.recurring} component={DatePickerInput}
																	 validate={isFieldModifiedOrNew(fee, "end_date") ? [required, isBeforeDate({
																		 recurring: fee.recurring, start_date: fee.start_date
																	 })] : []}
																	 minDate={!fee.recurring ? fee.start_date ? new Date(fee.start_date) : new Date() : null}
																	 name={`prorated_fee_end_date_${fee.key}`} id={`prorated_fee_end_date_${fee.key}`}
																	 onChange={(v) => setDate(index, "end_date", v)}
																	 showMonthDropdown dateFormat={fee.recurring ? 'MM/dd' : 'MM/dd/yyyy'}/>
													</div>
													<div className="col" style={{marginTop: "16px", display: "block"}}>
														<Field
															component={'input'} type="checkbox" name={`prorated_fee_recurring_${fee.key}`}
															id={`prorated_fee_recurring_${fee.key}`}
															onChange={() => updateProratedFee(index, 'recurring', !fee.recurring)}/>
														<label style={{color: 'black', lineHeight: "56px"}}
																	 htmlFor={`prorated_fee_recurring_${fee.key}`}>Recurring</label>
													</div>
												</div>
											</div>
											<div className="col is-1-of-8" style={{display: "flex", justifyContent: "center"}}>
												<button type={'button'} onClick={() => deleteProratedFee(index)}
																className="btn fill gray">Delete
												</button>
											</div>
										</div>
										{index < proratedFees.length - 1 && <hr className="horizontal_rule"></hr>}
									</div>
								))
							}
						</div>

						{proratingError && (
							<span className={"validation__message is-error"}>
								{proratingError}
							</span>
						)}

						<div className="row">
							<div className="col">
								<div className="col push-left add_prorated_fee_btn">
									<button
										type={'button'}
										onClick={() => addProratedFee()}
										className="btn fill another-blue">Add Club Fee Proration
									</button>
								</div>
							</div>
						</div>
					</>
				}

				<hr
					style={{
						border: "0.5px solid #cccccc",
						marginTop: "25px",
						marginBottom: "25px",
					}}
				></hr>

				<div className="row">
					<div className="col">
						<label htmlFor="age_restrictions">
							Eligibility Requirements
						</label>
						<p>
							If there are any gender or age requirements for this
							club membership, please indicate below.
						</p>
					</div>
				</div>

				<div className="row">
					<div
						className="col is-1-of-5"
						style={{ textAlign: "right", marginTop: "11px" }}
					>
						<label htmlFor="gender_eligibility">Gender</label>
					</div>
					<div className="col is-4-of-5">
						<div className="col is-1-of-2">
							<Field
								component={SelectInput}
								id="gender_eligibility"
								name="gender_eligibility"
								className="react-select-container"
								classNamePrefix="react-select"
								options={genderOptions}
							/>
						</div>
					</div>
				</div>

				<div className="row">
					<div
						className="col is-1-of-5"
						style={{ textAlign: "right", marginTop: "35px" }}
					>
						<label htmlFor="age">Age</label>
					</div>
					<div className="col is-4-of-5">
						<div style={{ flexDirection: "row", display: "flex" }}>
							<div className="col is-1-of-3">
								<label htmlFor="minimum_age">Minimum Age</label>
								<Field
									component={InputField}
									validate={[
										isNumber,
										lowerThanCustomMessage([
											maximumAge || "",
											"Minimum Age cannot be higher than Maximum Age",
										]),
										maxLength([3, "Value too long (max 3 digits)"])
									]}
									onChange={(e) => {
										setMinimumAge(e.target.value);
									}}
									type="text"
									name="minimum_age"
									id="minimum_age"
								/>
							</div>
							<div className="col is-1-of-3">
								<label htmlFor="maximum_age">Maximum Age</label>
								<Field
									component={InputField}
									validate={[
										isNumber,
										greaterThanCustomMessage([
											minimumAge || "",
											"Maximum Age cannot be lower than Minimum Age",
										]),
										maxLength([3, "Value too long (max 3 digits)"])
									]}
									onChange={(e) => {
										setMaximumAge(e.target.value);
									}}
									type="text"
									name="maximum_age"
									id="maximum_age"
								/>
							</div>
						</div>
					</div>
				</div>

				{error && (
					<span className={"validation__message is-error"}>
						{error}
					</span>
				)}
				<div className="row">
					<div className="col is-1-of-2">
						<button
							type={"button"}
							className="btn fill gray"
							onClick={() => props.closeModal()}
						>
							Cancel
						</button>
					</div>
					<div className="col is-1-of-2">
						<button
							type={"submit"}
							disabled={pristine || submitting || !valid}
							className="btn fill blue"
						>
							Save
						</button>
					</div>
				</div>
			</form>
		</Fragment>
	);
}

const MembershipFeeForm = reduxForm({
	form: "membershipFeeForm",
	destroyOnUnmount: true,
})(MembershipFee);

function mapStateToProps(state) {
	const selector = formValueSelector("membershipFeeForm");
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		membership_amount: selector(state, "amount"),
		transactionFee: selector(state, "transaction_fee"),
		membershipType: selector(state, "club_rate"),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addAlert }, dispatch);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(MembershipFeeForm));
