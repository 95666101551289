import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import AddKioskModal from '../../../Modals/AddKioskModal';
import ClubService from "../../../../services/api/club";
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import EditKioskModal from "../../../Modals/EditKioskModal";
import {bindActionCreators} from "redux";
import {addAlert} from "../../../shared/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';


class KioskSetupClub extends Component {

    constructor(props) {

        super(props);

        this.state = {
            AddKioskModalIsOpen: false,
			EditKioskModalIsOpen: false,
			selectedKioskSetup: null,
			confirmKioskSetupDelete: false,
			confirmKioskName: null,
			confirmKioskId: null,
			kiosk_setups: [],
			editingCourse: null,
			openDeleteConfirmation: false,
			kisok_setups: [],
			facilities: [],
			editingFacility: null,
			toDelete: null,
			loading: true
        };

        this.openAddMKioskModal = this.openAddMKioskModal.bind(this);


	}

    openAddMKioskModal() {
		this.setState({AddKioskModalIsOpen: true});
    }


    closeModal(res) {
		this.setState({
            AddKioskModalIsOpen: false,
			EditKioskModalIsOpen: false,
			loading: res ? true : false,
        });
    }

    componentDidMount() {
		this.getKiosks()
	}

	getKiosks() {
    	ClubService.getKiosks(this.props.associationId, this.props.clubId)
			.then(response => {
				let kiosk_setups = response.kiosk_setups;
				this.setState({
					kiosk_setups
				})
				this.setState({
					loading: false,
				});
			})
			.catch(err => {

			})
	}

	deleteKioskSetup() {
    	ClubService.deleteKiosk(this.props.associationId, this.props.clubId, this.state.confirmKioskId)
			.then(response => {
				this.setState({
					confirmKioskSetupDelete: false,
					confirmKioskName: null,
					confirmKioskId: null
				}, () => {
					this.getKiosks();
				});
			})
	}

	render() {

		return (

            <Fragment>
                <div className="columns">
                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Kiosk Setup</div>
                                <div className="panel__body">
									{this.props.canEditClub && <div className="row">
                                        <div className="col is-1-of-5 push-right">
                                            <button onClick={() => this.openAddMKioskModal()} className="btn fill green">Add Kiosk</button>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col is-full">
                                            <table className="data__table">
                                                <colgroup>
                                                    <col className="col_nickname" />
                                                    <col className="" />
                                                    <col className="" />
                                                    <col className="" />
                                                    <col className="col_url" />
													{this.props.canEditClub && <Fragment>
                                                    	<col className="col_edit" />
                                                    	<col className="col_delete" />
													</Fragment>}
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>Nickname</th>
                                                        <th>Club(s)</th>
                                                        <th>Kiosk Display Name</th>
                                                        <th>Default Course</th>
                                                        <th>Url</th>
														{this.props.canEditClub && <Fragment>
															<th></th>
															<th></th>
														</Fragment>}
                                                    </tr>
                                                </thead>
                                                <tbody>
												{this.state.loading ?
													<tr>
														<td colSpan={this.props.canEditClub ? 9 : 7}>
															<span className="data-table__loader"></span>
														</td>
													</tr>
													:
													<Fragment>{this.state.kiosk_setups.length === 0 && <tr><td colSpan="9"><h1>No data available.</h1></td></tr>}
														{this.state.kiosk_setups.length > 0 && this.state.kiosk_setups.map(kiosk => {
															return (
																<tr key={kiosk.id}>
																	<td>{kiosk.nickname}</td>
																	<td>{kiosk.clubs.map(club => {return <Fragment>{club.club_name} <br /></Fragment>})}</td>
																	<td>{kiosk.club_display_name}</td>
																	<td>{kiosk.default_course_name}</td>
																	<td>https://kiosk.usga.org/{kiosk.url}</td>
																	{this.props.canEditClub && <Fragment>
																		<td><button className="btn lnk" onClick={(e) => {e.preventDefault(); this.setState({selectedKioskSetup: kiosk, EditKioskModalIsOpen: true})}}>Edit</button></td>
																		<td><button className="btn lnk" onClick={(e) => {e.preventDefault(); this.setState({confirmKioskSetupDelete: true, confirmKioskName: kiosk.nickname, confirmKioskId: kiosk.id})}}>Delete</button></td>
																	</Fragment>}
																</tr>
															)
														})}</Fragment>
												}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal
						isOpen={this.state.AddKioskModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <AddKioskModal clubName={this.props.clubName} facility={this.state.editingFacility} clubId={this.props.clubId} associationId={this.props.associationId} closeModal={()=>{this.closeModal(); this.getKiosks()}} />
                </Modal>

				<Modal
					isOpen={this.state.EditKioskModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditKioskModal facility={this.state.editingFacility} data={this.state.selectedKioskSetup} clubId={this.props.clubId} associationId={this.props.associationId} closeModal={()=>{this.closeModal(); this.getKiosks()}} />
				</Modal>

				<ConfirmationModal

					question={`Are you sure you want to delete ${this.state.confirmKioskName} Kiosk Setup?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmKioskSetupDelete: false})}
					onConfirmAction={() => this.deleteKioskSetup()}
					openModal={this.state.confirmKioskSetupDelete}
					closeModal={() => this.setState({confirmKioskSetupDelete: false})}
				/>

            </Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		club: state.club
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect( mapStateToProps, mapDispatchToProps)(withRouter(KioskSetupClub));
